<template>
  <div class="ProductCard" >
      <div class="Product">
         <Card v-if="length==3" :title="index==0?'Baseline':index==1?'Best Fit':index==2?'Best Performance':''" class="title"/>
         <div class="img" @click="toDetails">
             <img v-if="item.imgUrl" :src="item.imgUrl" alt="">
             <img v-else src="../../../../assets/default.png" alt="" >
         </div>
         <div class="ProductMesg">
             <p>{{item.name}}</p>
             <div class="btnList">
                 <el-button type="danger" @click="addTOCart"><i><img src="@/assets/iconCart.png"  alt=""></i>{{$t('productSelection.AddtoCart')}}</el-button>
                 <el-button size="medium" type="primary" @click="SaveSelectionResults"><i><img src="@/assets/img/save.png" alt=""></i>{{$t('productSelection.SaveSelectionResults')}}</el-button>
             </div>
         </div>
      </div>
  </div>
</template>

<script>
import Card from './card.vue'
export default {
    name:'ProductCard',
    props: {
        item:{
            type:Object,
            default:function(){
                return {}
            }
        },
        index:{
            type:Number,
            default:0,
            require:true
        },
        length:{
            type:Number,
            default:0,
            require:true
        },
        indexForm:{
            type:Object,
            default:{},
            require:true
        }
    },
    components: {
        Card
    },
    data () {
        return {
            entryId:null
        }
    },
    created () {
    },
    methods: {
        toDetails(){
            this.$emit('showDetails',this.item)
        },
        //加入购物车
        addTOCart(){
            let params = {
                'entryId': localStorage.getItem('entryId')?localStorage.getItem('entryId'):null,
                'entryName':this.indexForm.entryName,
                'productId':this.item.productId,
                'recommendType':this.indexForm.recommendType,
                'sceneList':this.item.sceneList
            }
            this.$emit('addShoppingCart',params)
        },
        //保存选型结果
        SaveSelectionResults(){
            let params = {
                'entryName':this.indexForm.entryName,
                'operationType': 1,
                'productId':this.item.productId,
                'sceneList':this.item.sceneList,
                'recommendType':this.indexForm.recommendType,
            }
            this.$emit('SaveSelectionResults',params)
        }
    }
}
</script>

<style lang="less" scoped>
    .ProductCard{
        width: 100%;
        height: 339px;        
        background: rgba(0, 0, 0, 0);
        border: 1px solid #CED4DA;
        opacity: 1;
        border-radius: 8px;       
        .Product{
            position: relative;
            width: 17.75rem;
            .title{
                position: absolute;
                top: 13px;
            }
            .img{
                height: 232px;
                // width: 268px;
                width: 100%;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                    border: none;
                    border-radius: 8px;
                }
            }
            .ProductMesg{
                padding: 11px;
                p{
                text-align: center;
                word-break: break-word;
                height: 35px;
                display: inline-block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                // white-space: nowrap;
                }
                .btnList{
                    display: flex;
                   /deep/ .el-button{
                        flex: 1;
                        font-size: 12px;
                        padding: 5px 8px 5px 8px;
                        margin-top: 11px;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        span{  
                            display: flex;
                            align-items: center;
                            img{
                                height: 14px;
                                width: 14px;
                                margin-right: 7px;
                            }
                        }
                    }
                }
            }
            
        }
    }
</style>