<template>
  <div class="content">
    <div class="close-content">
      <h2>Add Asset</h2>
      <img class="close"
           src="@/assets/img/task_close.png"
           @click="hidenView" />
    </div>
    <div class="head-content">
      <el-row style="background: #F1F6FB; padding:15px 0;">
        <el-col :span="24">
          <el-steps :active="activeStep"
                    align-center
                    finish-status="success">
            <el-step title="Choose Add Device"></el-step>
            <el-step title="Submit Device SN/ID"></el-step>
            <el-step title="Check Device Information"></el-step>
          </el-steps>
        </el-col>
      </el-row>

      <!-- 第一步 -->
      <el-row class="sp_deviceStep1"
              v-if="activeStep==1"
              type="flex"
              justify="center"
              style="flex-wrap: wrap;">
        <el-col :span="7">
          <div class="sp_deviceRadio"
               v-for="val in radioList"
               :key="val.id"
               :class="{is_checked:checkRadio == val.id}"
               @click="handleCheck(val.id)">
            <span>{{val.label}}</span>
            <i class="el-icon-check"
               v-if="checkRadio == val.id"></i>
          </div>
        </el-col>
        <el-col :span="24"
                style="text-align:center; margin-top:70px;">
          <el-button size="medium"
                     type="primary"
                     @click="handleNext(1)">Next</el-button>
        </el-col>

        <el-col :span="24"
                style="text-align:left; margin-top:70px;">
          <div class="support">
            <div class="note">Note:</div>
            <div>Support physical NGAF with version 8.0.26 and above, and order should be placed after 13 Oct 2021.</div>
            <div>Support physical IAG with version 12.0.42 and above, and order should be placed after 13 June 2022.</div>
          </div>
        </el-col>
      </el-row>

      <!-- 第二步 -->
      <el-form :model="filter"
               ref="addDeviceFrom"
               :rules="addDeviceRules"
               class="addDeviceFrom"
               v-if="activeStep==2">
        <el-row type="flex"
                justify="center"
                style="margin-bottom:10px;">
          <el-col :span="12">
            <p style="text-align:center">Please make sure if the selected devices belong to you, do not add devices purchased by customers.</p>
          </el-col>
        </el-row>
        <el-row v-if="checkRadio == 1"
                style="flex-wrap: wrap;"
                type="flex"
                justify="center">
          <el-col :span="7">
            <el-form-item label="Device ID/SN："
                          prop="deviceIdOrSn">
              <el-input type="textarea"
                        v-model="filter.deviceIdOrSn"
                        :autosize="{minRows: 4, maxRows: 6}"
                        placeholder="please input Device ID/SN."></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24"
                v-if="checkRadio == 2"
                style="flex-wrap: wrap;"
                type="flex"
                justify="center">
          <el-col :span="6">
            <el-form-item label="Order No："
                          prop="orderId">
              <el-input size="medium"
                        type="text"
                        v-model="filter.orderId"
                        placeholder="please input Order No."></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Device ID/SN："
                          prop="deviceIdOrSn">
              <el-input size="medium"
                        type="text"
                        v-model="filter.deviceIdOrSn"
                        placeholder="please input Device ID/SN."></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top:50px;">
          <el-col>
            <el-form-item style="text-align:center;">
              <el-button size="medium"
                         plain
                         @click="handleBack(2)">Back</el-button>
              <el-button size="medium"
                         type="primary"
                         @click="handleNext(2,'addDeviceFrom')">Next</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- 第三步 -->
      <el-row v-if="activeStep==3"
              class="sp_deviceStep3">
        <el-col :span="24">
          <common-table :tableHeadList="tableHeadList"
                        ref="multipleTable"
                        :tableData="tableData"
                        :isSelected="true"
                        @getSelectDate="getSelectDate">
            <template v-slot:status="slot">
              <span :style="statusStyle[slot.scope.row.licenseStatus]">{{statusOptions[slot.scope.row.licenseStatus].name}}</span>
            </template>
            <template v-slot:tooltip>
              <span style="margin-right:5px">Auto Activation</span>
              <el-tooltip content="Automatic Activation Enable. When Automatic 
                Activation is enabled, the device will be activated automatically 
                after it connects to the Internet. Automatic activation is not 
                available for software products."
                          placement="top-end"
                          effect="light">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </template>
            <template v-slot:activation="slot">
              <el-checkbox-group v-model="checkList">
                <el-checkbox :label="slot.scope.row.id">Enable</el-checkbox>
              </el-checkbox-group>
            </template>
          </common-table>
        </el-col>
        <el-col :span="24"
                style="margin-top:10px; text-align:right;">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :page-sizes="pageSizes"
                         :page-size="filter.pageSize"
                         :current-page="filter.pageNumber"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-col>
        <el-col :span="24"
                style="text-align:center; margin-top:50px;">
          <el-button size="medium"
                     plain
                     @click="handleBack(3)">Back</el-button>
          <el-button size="medium"
                     type="primary"
                     @click="handleNext(3)"
                     :loading="saveLoding">Save</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 弹出框 -->
    <el-dialog title="Hint"
               :visible.sync="dialogVisible"
               width="30%"
               :show-close="false"
               :modal="false"
               :close-on-click-modal="false">
      <el-row class="checkJunpRadio">
        <el-col :class="{activeJunpRadio:checkJunpRadio==val.id}"
                v-for="val in JunpRadio"
                :key="val.id">
          <el-radio v-model="checkJunpRadio"
                    :label="val.id">{{val.name}}</el-radio>
        </el-col>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addAsset, addConfirm } from '@/api/assets'
import commonTable from './commonTable.vue'
export default {
  name: 'addDevice',
  components: {
    commonTable
  },
  data () {
    return {
      tableHeadList: [
        {
          data: {
            label: 'Serial Number',
            prop: 'sn',
            name: '',
          }
        },
        // {
        //   data: {
        //     label: 'Order No.',
        //     prop: 'orderId',
        //     name: '',
        //   }
        // },
        {
          data: {
            label: 'Gateway ID',
            prop: 'deviceId',
            name: '',
          }
        },
        {
          data: {
            label: 'Product',
            prop: 'productType',
            name: '',
          }
        },
        {
          data:
          {
            label: 'Model',
            prop: 'model',
            name: '',
          }
        },
        // {
        //   data: {
        //     label: 'Bind',
        //     prop: 'bind',
        //   }
        // },
        {
          slotheader: true,
          slot: 'activation',
          data: {
            label: 'Auto Activation',
          }
        },
        {
          slot: 'status',
          data: {
            label: 'Status',
            prop: 'licenseStatus',
          }
        },
      ],
      activeStep: 1,
      radioList: [
        { id: 1, label: 'Add a device' },
        { id: 2, label: 'Add multiple devices' },
      ],
      statusOptions: [
        { id: 0, name: "Inactivated" },
        { id: 1, name: "Activated" },
        { id: 2, name: "To be Updated" },
        { id: 4, name: "Expired" },
      ],
      statusStyle: {
        0: {
          background: '#F4A12D',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: "inline-block",
        },
        1: {
          background: '#32C093',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: "inline-block",
        },
        2: {
          background: '#007AFF',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: "inline-block",
        },
        4: {
          background: '#79828C',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: "inline-block",
        },
      },
      checkRadio: null,
      filter: {
        orderId: "",
        deviceIdOrSn: '',
        pageSize: 10,
        pageNumber: 1,
      },
      addDeviceRules: {
        orderId: [
          { required: true, message: "please input Order No.", trigger: "blur", }
        ],
        deviceIdOrSn: [
          { required: true, message: "please input Device ID/SN.", trigger: "blur", }
        ],
      },
      tableData: [],
      saveLoding: false,
      pageSizes: [10, 15, 30, 60, 100],
      total: 0,
      selectionData: [],
      dialogVisible: false,
      checkJunpRadio: '',
      JunpRadio: [
        { id: 1, name: 'Continue to add device' },
        { id: 2, name: 'View in my devices List' },
      ],
      saveLoding: false,
      checkList: []
    }
  },
  props: {

  },
  watch: {

  },
  computed: {

  },
  created () {
    this.defaultFilter = JSON.parse(JSON.stringify(this.filter))
  },
  mounted () {

  },
  methods: {

    //获取所选设备
    getSelectDate (data) {
      this.selectionData = data.row
    },

    // 关闭
    hidenView () {
      this.$emit('hide-view')
    },

    //选择添加多个或者单个设备
    handleCheck (id) {
      this.checkRadio = id
    },

    //点击下一步或提交
    handleNext (data, addDeviceFrom) {
      if (data == 1) {
        if (this.checkRadio !== null) {
          this.activeStep = 2
          this.filter = JSON.parse(JSON.stringify(this.defaultFilter))
        } else {
          return
        }
      } else if (data == 2) {
        this.$refs[addDeviceFrom].validate(valid => {
          if (valid) {
            this.handleSearch()
            this.activeStep = 3
          }
        })
      } else if (data == 3) {
        if (this.selectionData.length > 0) {
          let params = [];
          //这里判断一波勾选的是否有已经绑定的
          for (var i = 0; i < this.selectionData.length; i++) {
            if (this.selectionData[i].bindingFlag) {
              this.$message({
                type: 'warning',
                message: 'The devices have been added,please do not add repeatedly.'
              })
              params = [];
              break
            } else {
              params.push(this.selectionData[i].id)
            }
          }
          if (params.length > 0) {
            this.saveLoding = true;
            const addParams = this.selectionData.map(item => {
              return this.checkList.includes(item.id) ? { id: item.id, autoActivate: 1 } : { id: item.id, autoActivate: 0 }
            })
            addConfirm(addParams).then(res => {
              this.$message({
                type: "success",
                message: "Added Auccessfully"
              })
              this.dialogVisible = true;
              this.saveLoding = false;
            }).catch(() => {
              this.saveLoding = false;
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: 'Please make a selection or click back to previous page.'
          })
        }
      }
    },

    //添加完成跳转页面
    handleConfirm () {
      if (this.checkJunpRadio == 1) {
        this.activeStep = 1;
        this.dialogVisible = false;
      } else {
        this.dialogVisible = false;
        this.$emit('addDeviceChildMsg', { close: false, isSave: true })
      }
    },
    //设备查询
    handleSearch () {
      let params = JSON.parse(JSON.stringify(this.filter))
      params.deviceIdOrSn = params.deviceIdOrSn.replace(/\n|\r\n/g, ",").replace(/\s*/g, "")
      params.type = this.checkRadio
      if (this.checkRadio == 1) {
        delete params.orderId
      }
      addAsset(params).then(res => {
        this.tableData = res.rows.rows;
        this.checkList = res.rows.rows.map(item => {
          return item.id
        })
        this.total = res.rows.total;
        this.tableData.forEach(val => {
          if (val.bindingFlag == false) {
            this.$nextTick(() => {
              this.$refs['multipleTable'].$children[0].toggleRowSelection(val);
            })
          }
        })
      }).catch(() => {
        this.tableData = [];
        this.total = 0;
      })
    },
    //返回上一步
    handleBack (data) {
      if (data == 2) {
        this.activeStep = 1
      } else {
        this.activeStep = 2
      }
    },

    handleCurrentChange (data) {
      this.filter.pageNumber = data;
      this.handleSearch(this.filter)
    },
    handleSizeChange (data) {
      this.filter.pageSize = data;
      this.handleSearch(this.filter)
    },
  }
}
</script>
<style lang="less">
.el-tooltip__popper {
  max-width: 300px;
}
</style>
<style lang="less" scoped>
.content {
  // border: 1px solid #e6e6e6;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  padding: 0 20px;
  height: 100vh;
  .close-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      display: block;
      width: 40px;
      height: 40px;
      margin-right: -10px;
      padding: 10px;
      cursor: pointer;
    }
  }
  .head-content {
    .sp_deviceStep1 {
      margin-top: 56px;
      .sp_deviceRadio {
        width: 100%;
        padding: 20px;
        border-radius: 4px;
        border: 1px solid #ddd;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 18px;
          font-weight: 600;
          color: #2b2b2b;
        }
        i {
          font-size: 18px;
          color: #007aff;
        }
      }
      .support {
        .note {
          color: rgba(0, 0, 0, 0.45);
          font-size: 16px;
          line-height: 32px;
        }
        margin-left: 200px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 22px;
      }
      .is_checked {
        // background-color: #409eff;
        border-color: #007aff;
        span {
          color: #007aff;
        }
      }
    }
    .addDeviceFrom {
      margin-top: 60px;
    }
    .sp_deviceStep3 {
      margin-top: 32px;
    }
    /deep/.el-step__title {
      color: black !important;
      font-weight: 600 !important;
    }
    /deep/.el-step__head.is-success {
      color: #007aff;
      border-color: #007aff;
      .el-step__line {
        background: #007aff;
      }
    }
    /deep/.el-step__head.is-process {
      color: #fff;
      border-color: #007aff;
      .el-step__icon.is-text {
        background: #007aff;
      }
    }
  }
}
</style>