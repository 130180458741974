<template>
  <div class="wrapper">
    <ul class="list">
      <li v-for="(item, index) in showObj.form" :key="index" class="list-item">
        <span v-if="item.formTypeWeb == 'date'">{{
          item.name + "&nbsp;“" + item.value[0] + "-" + item.value[1] + "”"
        }}</span>
        <span v-else-if="item.formTypeWeb === 'dateTime'">{{
          item.name + "&nbsp;“" + item.value[0] + "-" + item.value[1] + "”"
        }}</span>
        <span v-else-if="item.formTypeWeb === 'users'">{{
          item.name +
          "&nbsp;" +
          optionsNames[item.condition] +
          "“" +
          getUserName(item) +
          "”"
        }}</span>
        <span v-else-if="item.formTypeWeb === 'usernames'">{{
          item.name +
          "&nbsp;" +
          optionsNames[item.condition] +
          "“" +
          getUserName(item) +
          "”"
        }}</span>
        <span v-else-if="item.formTypeWeb === 'distributionchannel'">{{
          item.name +
          "&nbsp;" +
          optionsNames[item.condition] +
          "“" +
          getdistributionchannelName(item) +
          "”"
        }}</span>
        <span
          v-else-if="
            (item.formTypeWeb === 'category' && item.value.length > 0) ||
            item.formTypeWeb === 'IndustryCategory' ||
            item.formTypeWeb === 'interestProduct' ||
            (item.formTypeWeb === 'requirement' &&
              item.value.length > 0 &&
              item.value.length > 0)
          "
          >{{
            item.name +
            "&nbsp;" +
            optionsNames[item.condition] +
            "&nbsp;“" +
            item.valueContent +
            "”"
          }}</span
        >
        <span v-else-if="item.formTypeWeb === 'select'">{{
          item.name +
          "&nbsp;" +
          optionsNames[item.condition] +
          "“" +
          getSelectName(item) +
          "”"
        }}</span>

        <!-- isSelect:true,
            optionName:o.setting.find(e=>e.value==o.value).name, -->
        <span v-else>{{
          item.name +
          "&nbsp;" +
          optionsNames[item.condition] +
          "“" +
          item.value +
          "”"
        }}</span>
        <i class="el-icon-close icon" @click="handleDelete(item, index)" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FilterContent",
  props: {
    obj: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 获取条件名称
      optionsNames: {
        is: this.$t("sceneCreate.14"),
        in: this.$t("sceneCreate.14"),
        isNot: this.$t("sceneCreate.15"),
        contains: this.$t("sceneCreate.16"),
        notContains: this.$t("sceneCreate.17"),
        eq: this.$t("sceneCreate.14"),
        neq: this.$t("sceneCreate.15"),
      },
      // 展示信息
      showObj: {},
    };
  },
  watch: {
    obj: function (val) {
      this.showObj = val;
    },
  },
  mounted() {
    this.showObj = this.obj;
  },
  methods: {
    /**
     * 删除高级筛选条件
     * @param index
     */
    handleDelete(item, index) {
      this.$delete(this.showObj.obj, item.fieldName);
      this.showObj.form.splice(index, 1);
      this.$emit("delete", { item: item, index: index, obj: this.showObj });
    },
    getSelectName(item) {
      return item.setting
        .filter((ele) => item.value.includes(ele.value))
        .map((item) => item.name);
    },
    getUserName(item) {
      return item.setting.map((ele) => ele.name);
    },
    getdistributionchannelName(item) {
      return item.setting.map((ele) => ele.channelName);
    },
  },
};
</script>

<style lang="less" scoped>
.left() {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 100%;
  min-height: 50px;
  background: white;
  border-top: 1px solid #e1e1e1;
  font-size: 13px;
  overflow-x: auto;
  color: #aaa;
  .left();
  .list {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    flex-shrink: 0;
    .left();
    .list-item {
      height: 30px;
      padding: 0 10px;
      margin: 10px 15px 0 0;
      border: 1px solid #e1e1e1;
      border-radius: 3px;
      flex-shrink: 0;
      .center();
      .icon {
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>