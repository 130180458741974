<template>
  <div style="z-index: 1000">
    <el-popover
      v-model="visible"
      @show="getLabelList"
      placement="left"
      trigger="click"
    >
      <div style="font-size: 12px; padding-bottom: 5px; color: #aaa">
        {{ $t("sceneSet.1") }}
      </div>
      <div
        style="
          max-height: 350px;
          width: 100%;
          min-width: 250px;
          overflow-y: scroll;
        "
      >
        <draggable
          v-model="filterData"
          @end="moveEnd"
          style="cursor: pointer"
          class="board-column-content"
        >
          <el-row
            v-for="(item, index) in filterData"
            style="margin: 5px 0px"
            :key="index"
          >
            <el-col :span="6">
              <el-switch v-model="item.show"></el-switch>
            </el-col>
            <el-col :span="18">{{ item.name }}</el-col>
          </el-row>
        </draggable>
      </div>
      <div style="text-align: right; margin: 5px 0 0">
        <el-button
          type="primary"
          size="mini"
          :loading="confirmLoading"
          @click="confirm"
          >{{ $t("common.btn.sure") }}</el-button
        >
        <el-button size="mini" type="danger" @click="reset">{{
          $t("common.btn.reset")
        }}</el-button>
      </div>
      <img
        slot="reference"
        style="cursor: pointer; margin-top: 8px"
        src="@/assets/img/t_set.png"
        class="table-set"
      />
    </el-popover>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { queryFieldConfig, saveFieldConfig } from "@/api/common";
export default {
  name: "FieldsSet", // 场景 设置
  components: {
    draggable,
  },
  props: {
    /** 没有值就是全部类型 有值就是当个类型 */
    crmType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      resetList: [],
      filterData: [],
      confirmLoading: false,
    };
  },
  mounted() {
    // 为了防止火狐浏览器拖拽的时候以新标签打开，此代码真实有效
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
  },
  methods: {
    getLabelList() {
      queryFieldConfig({ objectCode: this.crmType }).then((res) => {
        if (res.code != 500) {
          res.rows.showFields.map((e) => (e.show = true));
          res.rows.hiddenFields.map((e) => (e.show = false));
          this.filterData = [...res.rows.showFields, ...res.rows.hiddenFields];
          this.resetList = JSON.parse(JSON.stringify(this.filterData));
        }
      });
    },
    moveEnd() {},
    confirm() {
      let showFieldIds = this.filterData.reduce((str, ele, index) => {
        ele.show ? (str += (str.length != 0 ? "," : "") + ele.id) : "";
        return str;
      }, "");
      let hiddenFieldIds = this.filterData.reduce((str, ele) => {
        !ele.show ? (str += (str.length != 0 ? "," : "") + ele.id) : "";
        return str;
      }, "");
      this.confirmLoading = true;
      saveFieldConfig({
        objectCode: this.crmType,
        showFieldIds: showFieldIds,
        hiddenFieldIds: hiddenFieldIds,
      })
        .then((res) => {
          this.visible = false;
          this.confirmLoading = false;
          this.$emit("set-success");
        })
        .catch((err) => {
          this.visible = false;
          this.confirmLoading = false;
        });
    },
    reset() {
      this.filterData = JSON.parse(JSON.stringify(this.resetList));
    },
  },
};
</script>

<style>
</style>