<template>
  <div class="userDetail">
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('createcase.userDetails')"
      :visible="userDetailAction"
      width="50%"
      :before-close="handleClose"
    >
      <div class="userDetailFromBox">
        <el-form
          :model="userDetailFrom"
          ref="userDetailFrom"
          class="userDetailFrom"
          :rules="userDetailRules"
        >
          <el-form-item :label="$t('caselist.firstName')" prop="surname">
            <el-input v-model="userDetailFrom.surname" placeholder="" size="small"> </el-input>
          </el-form-item>
          <el-form-item :label="$t('caselist.lastName')" prop="name">
            <el-input v-model="userDetailFrom.name" placeholder="" size="small"> </el-input>
          </el-form-item>
          <el-form-item :label="$t('caselist.Email')" prop="email">
            <el-input v-model="userDetailFrom.email" placeholder="" disabled size="small"> </el-input>
          </el-form-item>
          <el-form-item :label="$t('caselist.Phone')" >
            <el-input v-model="userDetailFrom.mobile" placeholder="" size="small">
              <el-select
                style="width:90px;"
                filterable
                :remote-method="remoteMethod"
                v-model="userDetailFrom.areaCode"
                remote
                @visible-change="areaCodeFocus"
                slot="prepend"
              >
                <el-option v-for="val in countryCode" :key="val.id" :label="val.areaCode" :value="val.id">{{val.country+' '+val.areaCode}}</el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('caselist.Role')" prop="roleType">
            <!-- <el-select v-model="userDetailFrom.isAdmin" placeholder="" :disabled="userDetail.isAdmin==1" size="small">
              <el-option label="Sales Staff &Technical Supporter" :value="0"></el-option>
              <el-option label="Admin" :value="1" disabled></el-option>
              <el-option label="Sales Staff" :value="3"></el-option>
              <el-option label="Technical Supporter" :value="4"></el-option>
            </el-select> -->
            <el-cascader
              :disabled="userDetail.isAdmin==1"
              size="small"
              v-model="userDetailFrom.roleType"
              collapse-tags
              :options="options"
              :props="{ multiple: true }"
              @change="handleChange">
            </el-cascader>
          </el-form-item>
          <el-form-item :label="$t('caselist.Status')" prop="status">
            <el-select v-model="userDetailFrom.status" placeholder="" size="small" :disabled="userDetail.isAdmin==1">
              <el-option label="Activated" :value="1"></el-option>
              <el-option label="Frozen" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">{{ $t("case.cancel") }}</el-button>
        <el-button type="primary" @click="handleSave('userDetailFrom')" V-loading="saveLoading" size="small">{{
          $t("case.submit")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Lockr from "lockr";
import {resetUserInfo,resetRole} from '../../api/case'
import { parse } from 'qs';
export default {
  name: "userDetail",
  props: {
    userDetail: {
      type: Object,
      require: true,
      default: {},
    },
    userDetailAction: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    // var checkEmail = (rule, value, callback) => {
    //   const eMailReg =
    //     /^([a-zA-Z0-9_+-.])+@([a-zA-Z0-9_+-])+((\.[a-zA-Z0-9_-]{2,5}){1,2})$/;
    //   if (!value) {
    //     return callback(new Error(this.$t("case.emailplaceholder2")));
    //   }
    //   setTimeout(() => {
    //     if (eMailReg.test(value)) {
    //       callback();
    //     } else {
    //       callback(new Error(this.$t("case.emailplaceholder")));
    //     }
    //   }, 200);
    // };
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
         callback();
      }
    };
    var checkFirstName = (rule, value, callback) => {
      if (!value) {
        return callback();
      } else {
        if (value.length > 50) {
          callback(new Error(this.$t("case.firstName")));
        } else {
          callback();
        }
      }
    };
    var checklastName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("case.lastName")));
      } else {
        if (value.length > 50) {
          callback(new Error(this.$t("case.lastName2")));
        } else {
          callback();
        }
      }
    };
    return {
      saveLoading:false,
      userDetailFrom: {
        areaCode: "",
        name: "",
        surname:"",
        email: "",
        mobile: "",
        isAdmin: "",
        status: "",
        roleType:[],
      },
      userDetailRules: {
        name: [
          {
            required: true,
            validator: checklastName,
            trigger: "blur",
          },
        ],
        surname: [
          {
            validator: checkFirstName,
            trigger: "blur",
          },
        ],
        email: [
          // { required: true, validator: checkEmail, trigger: "blur" }
          { required: true, message: this.$t("case.emailplaceholder2"), trigger: 'blur' },
          { type: 'email', message: this.$t("case.emailplaceholder"), trigger: ['blur', 'change'] }
          ],
        roleType: [{ required: true, message: this.$t("case.role"), trigger: "change" }],
        status: [{ required: true, message: this.$t("case.statusplaceholder"), trigger: "change" }],
        // mobile: [
        //   { validator: checkPhone,trigger: "blur"}
        // ],
      },
      options:[
        {
          value:1,
          label: 'Administrator',
           disabled: true,
        },
        {
          value:2,
          label: 'Normal User',
          children:[
            {
              value:3,
              label:'Sales Staff',
            },
            {
              value:4,
              label:'Technical Supporter',
            },
          ]
        }
      ],
      countryCode:[],
    };
  },
  methods: {
    handleClose() {
      this.$emit("userDetailProp", {status:false,isSave:false});
    },
    handleSave(userDetailFrom) {
      this.$refs[userDetailFrom].validate((valid) => {
        if (valid) {
          this.saveLoading=true;
          let params=JSON.parse(JSON.stringify(this.userDetailFrom))
          params.userId =this.userDetail.id;
          delete params.roleType
          if(params.isAdmin!==this.userDetail.isAdmin){
            let data={
              userIds:this.userDetail.id,
              isAdmin:params.isAdmin
            }
            resetRole(data).then(res=>{
            }).catch(err=>{
              console.log('role',err);
            })
          }
          resetUserInfo(params).then(res=>{
            this.$message({
              type:'success',
              message:'Submit sucessfully!'
            })
            this.saveLoading=false;
            this.$emit("userDetailProp", {status:false,isSave:true});
          }).catch(err=>{
            this.saveLoading=false;
          })
        }
      });
    },
    handleChange(val){
      if(val.length==1){
        if(val[0].length==2){
          this.userDetailFrom.isAdmin=val[0][1]
        }else if(val[0].length==1){
          this.userDetailFrom.isAdmin=1
        }
      }else if(val.length==2){
        this.userDetailFrom.isAdmin=0
      }
    },
    remoteMethod(qurey){
      this.countryCode=Lockr.get('countryCode')
      this.countryCode=this.countryCode.filter(val=>{
        if((val.country+" "+val.areaCode).toLowerCase().replace(/\s/g,"").indexOf(qurey.toLowerCase().replace(/\s/g,""))!=-1){
          return true
        }
        return false
      })
    },
    areaCodeFocus(qurey){
      setTimeout(() => {
        if(!qurey){
        this.countryCode=Lockr.get('countryCode')
        }
      }, 200);
    }
  },
  created(){
    this.countryCode=Lockr.get('countryCode')
    this.userDetailFrom.name=this.userDetail.name;
    this.userDetailFrom.surname=this.userDetail.surname;
    this.userDetailFrom.email=this.userDetail.email;
    this.userDetailFrom.mobile=this.userDetail.mobile;
    this.userDetailFrom.isAdmin=this.userDetail.isAdmin;
    this.userDetailFrom.status=this.userDetail.status;
    this.userDetailFrom.areaCode=this.userDetail.areaCode?Number(this.userDetail.areaCode):'';
    if(this.userDetail.isAdmin==0){
      let arr=[[2,3],[2,4]]
      this.userDetailFrom.roleType=arr;
    }else if(this.userDetail.isAdmin==3){
      let arr=[[2,3]]
      this.userDetailFrom.roleType=arr;
    }else if(this.userDetail.isAdmin==4){
      let arr=[[2,4]]
      this.userDetailFrom.roleType=arr;
    }else if(this.userDetail.isAdmin==1){
      let arr=[1]
      this.userDetailFrom.roleType=arr;
    }
  }
};
</script>

<style scoped lang="less">
.userDetailFromBox {
  width: 100%;
  .userDetailFrom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /deep/ .el-form-item {
      width: 48%;
      .el-select {
        width: 100%;
      }
    }
  }
  /deep/ .country-selector.has-value .country-selector__label {
    transform: translateY(-25%) !important;
  }
  /deep/ .input-tel.has-value .input-tel__label {
    transform: translateY(-25%) !important;
  }
  /deep/ .input-tel.is-focused .input-tel__input {
    box-shadow: none !important;
  }
  /deep/ .country-selector.has-value .country-selector__input {
    box-shadow: none !important;
  }
  /deep/ .el-cascader{
    width: 100%;
  }
  /deep/.el-input-group__prepend {
    background-color: #fff !important;
  }
}
</style>