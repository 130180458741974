<template>
  <div>
      <div class="shoppingCart">
          <div class="cartTitle">
              <p>{{$t('productSelection.Cart')}}({{cartList.length>0?cartList[0].carItem.length:0}})</p>
              <i class="el-icon-close" @click="close"></i>
          </div>
          <div class="cartItem" v-if="!refresh" v-loading='!refresh'>
          </div>
          <div class="cartItem" v-if="refresh">
              <CartItem  @showDetails='showDetails' :index-form='indexForm' @getDetails='getDetails'  :list='item' :index='key' v-for="(item,key) in cartList" :key="key" @showChecked='showChecked' @ItemChange='ItemChange' :checked='checked'/>
          </div>
          <div class="cartFooter">
                <el-button size="medium" :disabled='!this.itemIdList.length>0' type="primary" @click="saveResults"><i><img src="@/assets/img/save.png" alt=""></i>{{$t('productSelection.SaveSelectionResults')}}</el-button>
          </div>
      </div>
  </div>
</template>

<script>
import CartItem from './cartItem.vue'
import { saveResults } from '@/api/productSelection'
export default {
    name:'ShoppingCart',
    components: {
        CartItem
    },
    props:{
        cartList:{
            type:Array,
            default:[]
        },
        indexForm:{
            type:Object,
            default:function (){
                return {}
            }
        },
        refresh:{
            type:Boolean,
            default:true
        }
    },
    watch: {
        //this.arry 通过数组长度判断是否全选
        'this.arry':{
            handler(){
                
            } 
        }
    },
    data () {
        return {
        title:'Bank BJB/IAG',
        lastTime:null,
        checked:true,
        isIndeterminate:false,
        cartItem:[
        ],
        itemIdList:[],
        arry:[]
        }
    },
    created () {
     
    },
    methods: {
        //拼接购物车组件传出来的值
        ItemChange(val){
            this.itemIdList = []
            let productList = val
            productList.forEach(item=>{
                this.itemIdList.push(item.carItemId)
            })
            //多个项目选择拼接 暂时不删除 *****
            // if(this.arry.length>0){
            //     for(var i=0;i<this.arry.length;i++){
            //         if(val.index==this.arry[i].index){
            //             this.arry[i].val=val.val
            //             return
            //         }else if(val.index!==this.arry[i]&&i==this.arry.length-1){
            //             this.arry.push(val)
            //         }
            //     }
            // }else{
            //     this.arry.push(val)
            // }
        },
        //保存选型结果
        saveResults(){
            let params = {
                'carItemIdList':this.itemIdList,
                'operationType': 2,
                'entryId': localStorage.getItem('entryId')
            }
            this.$emit('SaveSelectionResults',params)
        },
        close(){
            this.$emit('closeCart',false)
        },
        showChecked(val){
            this.isIndeterminate=val
        },
        showDetails(item){
            this.$emit('showDetails',item)
        },
        getDetails(){
            this.$emit('getDetails')
        }
    }
}
</script>

<style lang="less" scoped>
    .shoppingCart{
        // width: 31.25rem;
        width: 100%;
        height: 100%;
        // height: 31.5625rem;
        background: #FFFFFF;
        box-shadow: 0px 10px 50px rgba(177, 181, 201, 0.55);
        opacity: 1;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        .cartTitle{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 19px 20px 16px 20px;
            height: 54px;
            font-size: 16px;
            font-family: FZLanTingHeiS-DB-GB;
            font-weight: 400;
            line-height: 19px;
            color: #343A40;
            opacity: 1;
            border-bottom: 1px solid #CED4DA;  
             .el-icon-close{
                 cursor: pointer;
             }
        }
        .cartItem{
                padding: 15px 20px;
                height: 80%;
                overflow: auto;
            }
        .cartFooter{
            height: 54px;
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 0 16px 0 20px;
            border-top: 1px solid #CED4DA;
          /deep/  .el-button{
                span{
                    display: flex;
                    img{
                    height: 14px;
                    width: 14px;
                    margin-right: 7px;
                }
                }
                
            }
        }
    }
</style>