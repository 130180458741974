<template>
  <div class="workorderDetail" id="workorderDetail">
    <!-- 头部区域 -->
    <div class="wod_header">
      <h3 class="orderDetail_title">{{ $t("case.orderdetail") }}</h3>
      <i class="el-icon-close" @click="handleClose"></i>
    </div>

    <div class="wod_mian">
      <!-- 详情区域 -->
      <div class="wod_mian_orderDetail">
        <div class="orderDetail_title_top">
          <div class="companyDeatil">
            <p>
              <span>{{$t('createcase.companyname')+'：'}}</span
              >{{
                detailPageData.companyName ? detailPageData.companyName : "--"
              }}
            </p>
            <p>
              <span>{{$t('createcase.customer')+'：'}}</span
              >{{ detailPageData.customer ? detailPageData.customer : "--" }}
            </p>
            <p style="display: flex; justify-content: flex-end">
              <el-tooltip
                :disabled="!upgradedVal"
                :content="$t('case.hasEscalated')"
                placement="top"
              >
                <div
                  style="display: flex; justify-content: flex-end; width: auto"
                >
                  <el-button
                    size="small"
                    icon="el-icon-upload"
                    @click="upgradeDialog = true"
                    :disabled="
                      detailPageData.upgrade == 1 ||
                      detailPageData.status == 0 ||
                      upgradedVal
                    "
                    >{{
                      detailPageData.upgrade == 1 || upgradedVal
                        ? $t('case.escalated')
                        : $t('case.escalate')
                    }}</el-button
                  >
                </div>
              </el-tooltip>
            </p>
          </div>
        </div>
        <div class="orderDetail_info">
          <div class="orderDetail_info_item">
            <h4>{{$t('case.productDetail')}}</h4>
            <div class="detailCont">
              <p>
                <span>{{$t('createcase.product')+'：'}}</span
                >{{ porductName[0] ? porductName[0].name : "--" }}
              </p>
              <p>
                <span>{{$t('createcase.version')+'：'}}</span
                >{{ versionName[0] ? versionName[0].name : "--" }}
              </p>
              <p>
                <span>{{$t('createcase.poc')+'：'}}</span>{{ detailPageData.poc == 1 ? "Yes" : "No" }}
              </p>
              <p>
                <span>{{$t('createcase.sn')+'：'}}</span
                >{{ detailPageData.sn ? detailPageData.sn : "--" }}
              </p>
              <p>
                <span>{{$t('createcase.gatewayid')+'：'}}</span
                >{{
                  detailPageData.gatewayId ? detailPageData.gatewayId : "--"
                }}
              </p>
            </div>
          </div>
          <div class="orderDetail_info_item">
            <h4>{{$t('case.orderdetail')}}</h4>
            <div class="detailCont">
              <p><span>{{$t('createcase.caseNo')+'：'}}</span>{{ detailPageData.caseNo }}</p>
              <p>
                <span>{{$t('createcase.issueSeverity')+'：'}}</span
                >{{ impactLevelDic[detailPageData.impactLevel].name }}
              </p>
              <p>
                <span>{{$t('createcase.status')+'：'}}</span
                >{{ detailPageData.status == 1 ? "Open" : "closed" }}
              </p>
              <p style="width: 100%">
                <span>{{$t('createcase.issuetitle')+'：'}}</span><span style="word-break: break-all; color:#333; display:inline-block; padding:5px 10px;">{{ detailPageData.issueTitle }}</span>
              </p>
              <p style="width: 100%">
                <span>{{$t('createcase.issuedescriptions')+'：'}}</span>
                <pre style="white-space: break-spaces; width:100%; font-family: 'MicrosoftYaHei', 'Avenir', Helvetica, Arial, sans-serif; padding:5px 10px;">{{ detailPageData.issueDescriptions }}</pre>
              </p>
              <p style="width: 100%; display: flex">
                <span style="display: inline-block; height: fit-content; width:100px;"
                  >{{$t('createcase.uploadFiles')+'：'}}</span
                >
                <span class="donwfiles">
                  <a
                    v-for="val in filesList"
                    :key="val.uid"
                    :download="val.name"
                    style="color: #409eff;line-height: 20px;"
                    @click="handleDownfile(val)"
                    >{{ val.name }}</a
                  >
                </span>
              </p>
            </div>
          </div>
          <div class="orderDetail_info_item">
            <h4>{{$t('case.contactDetail')}}</h4>
            <div class="detailCont">
              <p><span>{{$t('createcase.contact')+'：'}}</span>{{ detailPageData.contact }}</p>
              <p><span>{{$t('createcase.tel')+'：'}}</span>{{ areaCode+' '+detailPageData.telephone }}</p>
              <p><span>{{$t('createcase.email')+'：'}}</span>{{ detailPageData.email }}</p>
              <p style="width: 100%">
                <span>{{$t('createcase.ccemail')+'：'}}</span
                >{{ detailPageData.ccEmail ? detailPageData.ccEmail : "--" }}
              </p>
              <p>
                <span>{{$t('createcase.timezone')+'：'}}</span
                >{{ timeZone[0] ? timeZone[0].timeZone : "--" }}
              </p>
              <p>
                <span>{{$t('createcase.dete')+'：'}}</span
                >{{
                  detailPageData.timeStamp
                    ? moment(parseInt(detailPageData.timeStamp)).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : "--"
                }}
              </p>
            </div>
          </div>
          <div class="orderDetail_info_item">
            <h4>{{$t('case.othersDetail')}}</h4>
            <div class="detailCont">
              <p><span>{{$t('createcase.submitter')+'：'}}</span>{{ detailPageData.createdByName }}</p>
              <p>
                <span>{{$t('createcase.updateTime')+'：'}}</span
                >{{
                  detailPageData.modifiedDate
                    ? moment(detailPageData.modifiedDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : "--"
                }}
              </p>
              <p>
                <span>{{$t('createcase.createdTime')+'：'}}</span
                >{{
                  detailPageData.createdDate
                    ? moment(detailPageData.createdDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : "--"
                }}
              </p>
              <p>
                <span>{{$t('createcase.escalationTime')+'：'}}</span
                >{{
                  detailPageData.upgradeTime
                    ? moment(detailPageData.upgradeTime).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : "--"
                }}
              </p>
              <p>
                <span>{{$t('createcase.closedTime')+'：'}}</span
                >{{
                  detailPageData.closeTime
                    ? moment(detailPageData.closeTime).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : "--"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="wod_mian_data">
        <div class="data_menu">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">{{ $t("case.daily") }}</el-menu-item>
            <el-menu-item index="2">{{ $t("case.records") }}</el-menu-item>
            <el-menu-item index="3" v-if="detailPageData.status == 0&&(isAdminAndUser||isUserRate)">{{
              $t("case.evaluation")
            }}</el-menu-item>
          </el-menu>
        </div>
        <div class="data_item" v-if="activeIndex == '1'">
          <el-table
            :data="dailyDetailData"
            style="width: 100%"
            :stripe="true"
            border
            size="small"
          >
            <el-table-column :label="$t('createcase.date')" width="120" align="center">
              <template slot-scope="scope">
                <span>{{
                  moment(scope.row.createdDate).format("YYYY-MM-DD")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('createcase.dailyContent')" prop="content" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="createdByName"
              :label="$t('createcase.handler')"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="from"
              :label="$t('createcase.operation')"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <span
                  @click="dailyDetail(scope.row)"
                  style="color: rgb(85, 110, 230); cursor: pointer"
                  >Detail</span
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              @size-change="dailySizeChange"
              @current-change="dailyCurrentChange"
              :current-page="dailypagination.pageNumber"
              :page-sizes="[10, 15, 30, 60, 100]"
              :page-size="dailypagination.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="dailypagination.pageTotal"
            >
            </el-pagination>
          </div>
        </div>
        <div class="data_item" v-if="activeIndex == '2'">
          <el-table
            :data="emailDetailData"
            style="width: 100%"
            :stripe="true"
            border
            size="small"
          >
            <el-table-column
              prop="email"
              :label="$t('createcase.from')"
              align="center"
              width="300"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="emailTitle"
              :label="$t('createcase.emailTitle')"
              align="center"
            ></el-table-column>
            <el-table-column :label="$t('createcase.date')" width="120" align="center">
              <template slot-scope="scope">
                <span>{{
                  moment(scope.row.createdDate).format("YYYY-MM-DD")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="from"
             :label="$t('createcase.operation')"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <span
                  @click="recordDetail(scope.row)"
                  style="color: rgb(85, 110, 230); cursor: pointer"
                  >Detail</span
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              @size-change="emailSizeChange"
              @current-change="emailCurrentChange"
              :current-page="emailpagination.pageNumber"
              :page-sizes="[10, 15, 30, 60, 100]"
              :page-size="emailpagination.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="emailpagination.pageTotal"
            >
            </el-pagination>
          </div>
        </div>
        <div class="data_item" v-if="activeIndex == '3'">
          <div class="item_rate">
            <h3 class="item_rate_title">
              <span style="color: red">*</span>{{$t('createcase.PleaseRateUs')}}
            </h3>
            <div class="item_rate_status">
              <div class="rate_status_left">
                <ul>
                  <li
                    v-for="val in ratePoint"
                    :key="val"
                    :class="{ borderClass: val == 10 }"
                  ></li>
                  <div class="rateNote">
                    <span
                      v-for="val in ratePoint"
                      :key="val"
                      @click="handleRateNote(val)"
                    ></span>
                  </div>
                  <span class="fixedslider" @click="handleRateNote(0)"></span>
                  <p class="rateLine" :style="rateLine"><span></span></p>
                </ul>
                <p class="ratelevel">
                  <span>{{$t('createcase.veryDissatisfied')}}</span>
                  <span>{{$t('createcase.verySatisfed')}}</span>
                </p>
              </div>
              <div class="rate_status_right">
                <div class="rateGrade">
                  <span>{{ rateForm.rate }}</span>
                  <p>{{$t('createcase.Point')}}</p>
                </div>
              </div>
            </div>
            <div class="item_rate_helpful">
              <h4>
                <span style="color: red">*</span>{{$t('createcase.ishelpful')}}
              </h4>
              <div class="rate_helpful_box">
                <p
                  class="rateGood"
                  :class="{ showBadImg: showRateImg == 'good' }"
                  @click="handleRateImd('good')"
                >
                  <img
                    :src="!showGoodImg ? rateImg.good1 : rateImg.good2"
                    alt="yes"
                  /><span>{{$t('createcase.yes')}}</span>
                </p>
                <p
                  class="rateBad"
                  style="margin-left: 20px"
                  :class="{ showBadImg: showRateImg == 'bad' }"
                  @click="handleRateImd('bad')"
                >
                  <img
                    :src="!showBadImg ? rateImg.bad1 : rateImg.bad2"
                    alt="no"
                  /><span>{{$t('createcase.no')}}</span>
                </p>
              </div>
            </div>
            <div class="item_rate_reason">
              <el-form
                ref="rateForm"
                :model="rateForm"
                class="rateForm"
                :rules="rateRules"
              >
                <el-form-item
                  style="margin-bottom: 0"
                  v-if="this.rateForm.rate <= 6"
                  prop="lowScoresType"
                >
                  <h4>
                    <span style="color: red">*</span>{{$t('createcase.ReasonsOfLow')}}
                  </h4>
                  <el-checkbox-group
                    v-model="rateForm.lowScoresType"
                    :max="3"
                    :disabled="detailPageData.isRate !== 0 || allDisabled"
                    @change="lowScoresTypeChange"
                  >
                    <el-checkbox
                      v-for="item in ReasonsType"
                      :label="item.value"
                      :key="item.value"
                      name="type"
                      >{{ item.label }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item
                  v-if="rateForm.lowScoresType.includes('7')"
                  prop="lowScoresReason"
                >
                  <el-input
                    type="textarea"
                    ref="input"
                    placeholder="Please enter detail other reasons."
                    v-model="rateForm.lowScoresReason"
                    :disabled="detailPageData.isRate !== 0 || allDisabled"
                    resize="none"
                  ></el-input>
                </el-form-item>
                <el-form-item style="margin-top: 10px" prop="comment">
                  <h4>{{$t('createcase.Comment')}}</h4>
                  <el-input
                    type="textarea"
                    v-model="rateForm.comment"
                    :disabled="detailPageData.isRate !== 0 || allDisabled"
                    resize="none"
                  ></el-input>
                </el-form-item>
                <el-form-item style="display: flex; justify-content: center">
                  <el-button size="small" @click="handleClose">{{
                    $t("case.back")
                  }}</el-button>
                  <el-button
                    size="small"
                    :disabled="allDisabled"
                    :loading="saveLoading"
                    v-if="detailPageData.isRate == 0"
                    style="background: #556ee6; color: #fff"
                    @click="evaluationSubmit('rateForm')"
                    >{{ $t("case.submit") }}</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 组件区域 -->
    <upgrade-dialog
      @upgraded="upgraded"
      @showUpgradeDialog="showUpgradeDialog"
      :upgradeDialogAction="upgradeDialog"
      :upgradeCaseNo="detailPageData.caseId"
    ></upgrade-dialog>
    <el-dialog
      :title="$t('createcase.emailDetail')"
      :visible.sync="emailDialog"
      width="60%"
      :modal="false"
      center
      :before-close="emailDialogClose"
    >
      <div class="emailDetailInfo">
        <div class="infoItemBxo">
          <p><span>{{$t('createcase.caseNo')+'：'}}</span>{{ emailDetailInfo.caseNo }}</p>
          <p><span>{{$t('createcase.from')+'：'}}</span>{{ emailDetailInfo.email }}</p>
          <p><span>{{$t('createcase.to')+'：'}}</span>{{ emailDetailInfo.toEmail }}</p>
          <p><span>{{$t('createcase.cc')+'：'}}</span>{{ emailDetailInfo.ccEmail }}</p>
          <p>
            <span>{{$t('createcase.date')+'：'}}</span
            >{{ moment(emailDetailInfo.createdDate).format("YYYY-MM-DD") }}
          </p>
          <p><span>{{$t('createcase.emailTitle')+'：'}}</span>{{ emailDetailInfo.emailTitle }}</p>
          <p style="display: flex; flex-wrap:wrap;">
            <span>{{$t('createcase.Attachments')+'：'}}</span>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('case.viewEmail')"
              placement="top"
            >
              <span>
                <p
                  style="color: rgb(64, 158, 255); line-height:20px; padding:0;     width: fit-content;"
                  v-for="(val, index) in filesNameData"
                  :key="index"
                  >{{ val }}</p
                >
              </span>
            </el-tooltip>
          </p>
        </div>
        <div class="emailConetn">
          <h4>{{$t('createcase.emailContent')+'：'}}</h4>
          <p><pre v-html="emailDetailInfo.emailContent" style="white-space: break-spaces; width:100%;"></pre></p>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="emailDialogClose" size="small">{{$t('case.back')}}</el-button>
      </span> -->
    </el-dialog>
    <el-dialog
      :title="$t('createcase.dailySummariesDetail')"
      :visible.sync="dailyDialog"
      width="60%"
      :modal="false"
      center
      :before-close="dailyDialogClose"
    >
      <div class="emailDetailInfo">
        <div class="infoItemBxo">
          <p><span>{{$t('createcase.caseNo')+'：'}}</span>{{ dailyDetailInfo.caseNo }}</p>
          <p><span>{{$t('createcase.handler')+'：'}}</span>{{ dailyDetailInfo.createdByName }}</p>
          <p>
            <span>{{$t('createcase.date')+'：'}}</span
            >{{ moment(dailyDetailInfo.createdDate).format("YYYY-MM-DD") }}
          </p>
        </div>
        <div class="emailConetn">
          <h4>{{$t('createcase.dailyContent')+'：'}}</h4>
          <p><pre v-html="dailyDetailInfo.dailyContent" style="white-space: break-spaces; width:100%;"></pre></p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Lockr from "lockr";
import upgradeDialog from "./upgradeDialog.vue";
import {
  commentSave,
  commentDetail,
  getDailyByCaseNo,
  getEmailByCaseNo,
  getAllVersion,
  downloadFile,
} from "../../../api/case.js";
import moment from "moment";
export default {
  components: { upgradeDialog },
  name: "workorderDetail",
  props: {
    detailPageData: {
      type: Object,
      require: true,
      default: {},
    },
    rated: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    var checklowScoresReason = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Please enter reasons of low scores."));
      }
      setTimeout(() => {
        if (value.length < 1000) {
          callback();
        } else {
          callback(
            new Error("Reasons of low scores cannot exceed 1000 characters.")
          );
        }
      }, 200);
    };
    var checkcomment = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      setTimeout(() => {
        if (value.length < 1000) {
          callback();
        } else {
          callback(
            new Error("Reasons of low scores cannot exceed 1000 characters.")
          );
        }
      }, 200);
    };
    return {
      allDisabled: false,
      upgradedVal: false,
      emailDialog: false,
      dailyDialog: false,
      activeIndex: "1",
      emailDetailInfo: {},
      dailyDetailInfo: {},
      emailDetailData: [],
      dailyDetailData: [],
      rateLine: {
        width: "100%",
      },
      ReasonsType: [
        { label: "Slow response", value: "1" },
        { label: "Solved inefficiently", value: "2" },
        { label: "Weak technical ability", value: "3" },
        { label: "Bad attitude", value: "4" },
        { label: "Product issue", value: "5" },
        { label: "Issue not solved", value: "6" },
        { label: "Others", value: "7" },
      ],
      showBadImg: false,
      showGoodImg: true,
      upgradeDialog: false,
      saveLoading: false,
      showRateImg: "good",
      rateImg: {
        bad1: require("@/assets/img/bad1.png"),
        bad2: require("@/assets/img/bad2.png"),
        good1: require("@/assets/img/good1.png"),
        good2: require("@/assets/img/good2.png"),
      },
      ratePoint: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      rateForm: {
        rate: 10,
        isHelpful: "1",
        lowScoresType: [],
        lowScoresReason: "",
        comment: "",
      },
      rateRules: {
        lowScoresType: [
          {
            required: "true",
            message: "Please select reasons of low scores.",
            trigger: "change",
          },
        ],
        lowScoresReason: [
          {
            required: "true",
            validator: checklowScoresReason,
            trigger: "blur",
          },
        ],
        comment: [
          {
            required: "true",
            validator: checkcomment,
            trigger: "blur",
          },
        ],
      },
      TimeZoneData: [],
      impactLevelDic: {
        4: { name: "Low", color: { color: "#34C38F" } },
        3: { name: "Medium", color: { color: "#50A5F1" } },
        2: { name: "High", color: { color: "#F1B44C" } },
        1: { name: "Critical", color: { color: "#F46A6A" } },
      },
      emailpagination: {
        pageNumber: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      dailypagination: {
        pageNumber: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      allProductData: [],
      allVersionData: [],
      filesList: [],
      filesName: [],
      isAdminAndUser: false,
      isUserRate: false,
      filesNameData: [],
      countryCode:[],
    };
  },
  methods: {
    //获取详情
    WorkOrderInfo() {
      commentDetail(this.detailPageData.caseNo).then((res) => {
        this.rateForm.rate = Number(res.rows[0].rate);
        if (res.rows[0].isHelpful == "1") {
          this.rateForm.isHelpful = "1";
          this.showRateImg = "good";
          this.showGoodImg = true;
          this.showBadImg = false;
        } else {
          this.rateForm.isHelpful = "0";
          this.showRateImg = "bad";
          this.showBadImg = true;
          this.showGoodImg = false;
        }
        this.rateForm.lowScoresType = res.rows[0].lowScoresType.split(",");
        this.rateForm.lowScoresReason = res.rows[0].lowScoresReason;
        this.rateForm.comment = res.rows[0].comment;
        switch (this.rateForm.rate) {
          case 0:
            this.rateLine.width = "0%";
            break;
          case 1:
            this.rateLine.width = "10%";
            break;
          case 2:
            this.rateLine.width = "20%";
            break;
          case 3:
            this.rateLine.width = "30%";
            break;
          case 4:
            this.rateLine.width = "40%";
            break;
          case 5:
            this.rateLine.width = "50%";
            break;
          case 6:
            this.rateLine.width = "60%";
            break;
          case 7:
            this.rateLine.width = "70%";
            this.$set(this.rateForm, "lowScoresType", []);
            this.rateForm.lowScoresReason = "";
            break;
          case 8:
            this.rateLine.width = "80%";
            this.$set(this.rateForm, "lowScoresType", []);
            this.rateForm.lowScoresReason = "";
            break;
          case 9:
            this.rateLine.width = "90%";
            this.$set(this.rateForm, "lowScoresType", []);
            this.rateForm.lowScoresReason = "";
            break;
          case 10:
            this.rateLine.width = "100%";
            this.$set(this.rateForm, "lowScoresType", []);
            this.rateForm.lowScoresReason = "";
            break;

          default:
            break;
        }
      });
    },
    handleSelect(val) {
      this.activeIndex = val;
    },
    //获取日报详情
    DailyByCase(val) {
      let params = val;
      params.caseNo = this.detailPageData.caseNo;
      getDailyByCaseNo(params).then((res) => {
        this.dailyDetailData = res.rows.rows;
        this.dailyDetailData.forEach((val, index) => {
          val.dailyContent = val.dailyContent.replace(
            /<img src="(.*?)" style="(.*?)">/g,
            '<a href="$1" target="_blank" style="color:#0c60e1;">$1</a>'
          );
        });
        this.dailypagination.pageTotal = res.rows.total;
      });
    },
    dailySizeChange(val) {
      this.dailypagination.pageSize = val;
      this.DailyByCase(this.dailypagination);
    },
    dailyCurrentChange(val) {
      this.dailypagination.pageNumber = val;
      this.DailyByCase(this.dailypagination);
    },

    //获取邮箱详情
    EmailByCaseNo(val) {
      let params = val;
      params.caseNo = this.detailPageData.caseNo;
      getEmailByCaseNo(params).then((res) => {
        this.emailDetailData = res.rows.rows;
        this.emailDetailData.forEach((val, index) => {
          val.emailContent = val.emailContent.replace(
            /<img src="(.*?)" style="(.*?)">/g,
            '<a href="$1" target="_blank" style="color:#0c60e1;">$1</a>'
          );
        });
        this.emailpagination.pageTotal = res.rows.total;
      });
    },
    emailSizeChange(val) {
      this.emailpagination.pageSize = val;
      this.EmailByCaseNo(this.emailpagination);
    },
    emailCurrentChange(val) {
      this.emailpagination.pageNumber = val;
      this.EmailByCaseNo(this.emailpagination);
    },
    recordDetail(val) {
      this.emailDialog = true;
      this.emailDetailInfo = val;
      this.filesNameData = val.fileNames !== "" ? val.fileNames.split(",") : [];
      this.filesName = this.emailDetailInfo.fileNames
        ? this.emailDetailInfo.fileNames.split(",")
        : [];
    },
    dailyDetail(val) {
      this.dailyDialog = true;
      this.dailyDetailInfo = val;
    },
    emailDialogClose() {
      this.emailDialog = false;
    },
    dailyDialogClose() {
      this.dailyDialog = false;
    },

    //关闭页面
    handleClose() {
      this.$emit("detailPageStatus", { status: false, isSave: true });
    },
    //点击是否有帮助
    handleRateImd(val) {
      if (this.detailPageData.isRate !== 0 || this.allDisabled) return false;
      this.showRateImg = val;
      if (val == "good") {
        (this.rateForm.isHelpful = "1"), (this.showGoodImg = true);
        this.showBadImg = false;
      } else {
        (this.rateForm.isHelpful = "0"), (this.showBadImg = true);
        this.showGoodImg = false;
      }
    },
    //点击显示升级工单弹窗
    showUpgradeDialog(val) {
      this.upgradeDialog = val;
    },
    //提交评价
    evaluationSubmit(rateForm) {
      this.saveLoading = true;
      this.$refs[rateForm].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.rateForm));
          params.caseNo = this.detailPageData.caseNo;
          params.lowScoresType = params.lowScoresType.join(",");
          commentSave(params)
            .then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.saveLoading = false;
              this.allDisabled = true;
              setTimeout(() => {
                this.$emit("detailPageStatus", { status: false, isSave: true });
              }, 500);
            })
            .catch(() => {
              this.saveLoading = false;
            });
        } else {
          this.saveLoading = false;
        }
      });
    },
    //点击评分
    handleRateNote(val) {
      if (this.detailPageData.isRate !== 0 || this.allDisabled) return false;
      this.rateForm.rate = val;
      switch (val) {
        case 0:
          this.rateLine.width = "0%";
          break;
        case 1:
          this.rateLine.width = "10%";
          break;
        case 2:
          this.rateLine.width = "20%";
          break;
        case 3:
          this.rateLine.width = "30%";
          break;
        case 4:
          this.rateLine.width = "40%";
          break;
        case 5:
          this.rateLine.width = "50%";
          break;
        case 6:
          this.rateLine.width = "60%";
          break;
        case 7:
          this.rateLine.width = "70%";
          this.$set(this.rateForm, "lowScoresType", []);
          this.rateForm.lowScoresReason = "";
          break;
        case 8:
          this.rateLine.width = "80%";
          this.$set(this.rateForm, "lowScoresType", []);
          this.rateForm.lowScoresReason = "";
          break;
        case 9:
          this.rateLine.width = "90%";
          this.$set(this.rateForm, "lowScoresType", []);
          this.rateForm.lowScoresReason = "";
          break;
        case 10:
          this.rateLine.width = "100%";
          this.$set(this.rateForm, "lowScoresType", []);
          this.rateForm.lowScoresReason = "";
          break;

        default:
          break;
      }
    },
    upgraded(val) {
      this.upgradedVal = val;
    },
    //自动聚焦
    lowScoresTypeChange(val) {
      if (val.includes("7")) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
    //获取版本号
    allVersion() {
      getAllVersion(this.detailPageData.product).then((res) => {
        this.allVersionData = res.rows;
      });
    },
    //下载附件
    handleDownfile(val) {
      let fileTypeinfo = val.name.split(".");
      fileTypeinfo = fileTypeinfo[fileTypeinfo.length - 1];
      downloadFile(val.url).then((res) => {
        let fileType = {
          xls: "application/vnd.ms-excel",
          xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          ppt: "application/vnd.ms-powerpoint",
          pdf: "application/pdf",
          docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          doc: "application/msword",
          txt: "text/plain",
          mp4: "video/mp4",
          jpg: "image/jpeg",
          png: "image/png",
          gif: "image/gif",
          bmp: "application/x-bmp",
          tiff: "image/tiff",
          jpeg: "image/jpeg",
          rm: "application/vnd.rn-realmedia",
          avi: "video/avi",
          asf: "video/x-ms-asf",
          asf: "video/x-ms-asf",
          wmv: "video/x-ms-wmv",
          mpg: "video/mpg",
          mpeg: "video/mpg",
        };
        let blob = new Blob([res.data], { type: fileType[fileTypeinfo] });
        this.pdfUrl = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        var filename = val.name;
        a.href = this.pdfUrl;
        a.download = filename;
        a.click();
      });
    },
  },
  mounted() {
    if (this.rated) {
      this.activeIndex = "3";
      this.$nextTick(() => {
        this.$el.querySelector(".rateForm").scrollIntoView(true);
      });
    }
  },
  created() {
    this.countryCode=Lockr.get('countryCode')
    let userInfo = this.$store.state.user.userInfo;
    if (
      this.detailPageData.createdBy == userInfo.userId ||
      userInfo.isAdmin == 1
    ) {
      this.isAdminAndUser = true;
    } else {
      this.isAdminAndUser = false;
    }
    if (
      this.detailPageData.isRate == 1 &&
      this.detailPageData.createdBy !== userInfo.userId &&
      userInfo.isAdmin !== 1
    ) {
      this.isUserRate = true;
    } else {
      this.isUserRate = false;
    }
    this.TimeZoneData = Lockr.get("access_pp_TimeZoneList");
    this.allProductData = Lockr.get("access_pp_allProduct");
    this.filesList = JSON.parse(this.detailPageData.enclosure);
    if (this.detailPageData.isRate == 1) this.WorkOrderInfo();
    if (this.detailPageData.upgrade == 1) this.upgradedVal = true;
    this.DailyByCase(this.dailypagination);
    this.EmailByCaseNo(this.emailpagination);
    this.allVersion();
  },
  computed: {
    moment() {
      return moment;
    },
    timeZone() {
      return this.TimeZoneData.filter((item) => {
        if (item.id == this.detailPageData.timeZone) {
          return true;
        }
      });
    },
    porductName() {
      return this.allProductData.filter((val) => {
        if (this.detailPageData.product == val.id) {
          return true;
        }
        // return false;
      });
    },
    versionName() {
      return this.allVersionData.filter((val) => {
        if (this.detailPageData.version == val.id) {
          return true;
        }
        // return false;
      });
    },
    areaCode(){
      let areaCode
      this.countryCode.forEach(val=>{
        if(this.detailPageData.areaCode==val.id){
          areaCode=val.areaCode
        }
      })
      return areaCode
    }
  },
};
</script>

<style scoped lang='less'>
.workorderDetail {
  width: 100%;
  background: #f4f5f8;
  z-index: 999;
  .wod_header {
    width: 100%;
    padding: 10px 20px 0 20px;
    background: #fff;
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
    .orderDetail_title {
      font-size: 18px;
    }
    i {
      font-size: 22px;
      cursor: pointer;
    }
  }
  .wod_mian {
    width: 100%;
    border-radius: 4px;
    background: #fff;
    padding: 0 20px;
    .wod_mian_orderDetail {
      width: 100%;
      padding: 10px 0;
      .orderDetail_title_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .companyDeatil {
          width: 100%;
          padding: 0 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            width: 33.3%;
            span {
              color: #999a9e;
            }
          }
        }
        /deep/ .el-button {
          background: #556ee6;
          color: #fff;
          font-size: 14px;
        }
        /deep/ .el-button.is-disabled {
          background: #a0cfff;
        }
      }
      .orderDetail_info {
        width: 100%;
        .orderDetail_info_item {
          width: 100%;
          margin-top: 15px;
          h4 {
            padding: 0 10px 10px 10px;
          }
          .detailCont {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 0 60px;
            p {
              width: 33.3%;
              padding: 10px 0;
              span {
                color: #999a9e;
              }
              .donwfiles {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                a {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .wod_mian_data {
      width: 100%;
      margin-top: 20px;
      .data_menu {
        width: 100%;
      }
      .data_item {
        width: 100%;
        padding: 30px 0;
        .item_rate {
          width: 100%;
          padding: 0 20px;
          .item_rate_status {
            width: 100%;
            margin-top: 25px;
            padding: 0 10px;
            display: flex;
            .rate_status_left {
              width: 50%;
              ul {
                width: 100%;
                display: flex;
                justify-content: space-between;
                position: relative;
                li {
                  width: 10%;
                  height: 15px;
                  border-top: 3px solid #ced4da;
                  border-left: 1px solid transparent;
                  border-left-color: #ced4da;
                }
                .borderClass {
                  border-right: 1px solid #ced4da;
                }
                .rateNote {
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 51%;
                  transform: translate(-50%, -50%);
                  display: flex;
                  justify-content: space-around;
                  z-index: 10;
                  span {
                    width: 10%;
                    height: 15px;
                    background-color: transparent;
                    cursor: pointer;
                  }
                }
                .fixedslider {
                  width: 15px;
                  height: 15px;
                  display: inline-block;
                  background: #fff;
                  border: 2px solid #556ee6;
                  border-radius: 50%;
                  position: absolute;
                  top: -6px;
                  left: -7px;
                  z-index: 2;
                  cursor: pointer;
                }
                .rateLine {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 3px;
                  background: #556ee6;
                  transition: all 0.5s;
                  display: flex;
                  justify-content: flex-end;
                  span {
                    width: 15px;
                    height: 15px;
                    background: #fff;
                    border: 2px solid #556ee6;
                    display: inline-block;
                    border-radius: 50%;
                    transform: translate(50%, -38%);
                  }
                }
              }
              .ratelevel {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 10px 0;
              }
            }
            .rate_status_right {
              width: 45%;
              .rateGrade {
                margin-left: 20px;
                display: flex;
                align-items: center;
                span {
                  font-size: 24px;
                  color: #556ee6;
                  font-weight: 600;
                  display: inline-block;
                  width: 27px;
                  text-align: center;
                }
                p {
                  font-size: 18px;
                  color: #556ee6;
                  margin-left: 5px;
                }
              }
            }
          }
          .item_rate_helpful {
            width: 100%;
            margin-top: 20px;
            .rate_helpful_box {
              width: 100%;
              padding: 25px 0;
              display: flex;
              p {
                display: flex;
                align-items: center;
                border: 1px solid #74788d;
                justify-content: center;
                width: 80px;
                height: 36px;
                cursor: pointer;
                img {
                  width: 16px;
                  height: 16px;
                }
                span {
                  color: #74788d;
                  margin-left: 10px;
                }
              }
              .showBadImg {
                border: 2px solid #556ee6;
                span {
                  color: #556ee6;
                }
              }
            }
          }
          .item_rate_reason {
            width: 100%;
            .rateForm {
              width: 100%;
              margin-top: 10px;
              /deep/ .el-textarea__inner {
                height: 86px;
                font-family: "MicrosoftYaHei", "Avenir", Helvetica, Arial,
                  sans-serif;
              }
              /deep/.el-button.is-disabled,
              .el-button.is-disabled:focus,
              .el-button.is-disabled:hover {
                color: #fff;
                background-color: #a0cfff !important;
                border-color: #a0cfff !important;
              }
            }
          }
        }
        .pagination {
          width: 100%;
          padding: 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .emailDetailInfo {
    width: 100%;
    .infoItemBxo {
      width: 100%;
      background: #eff2f7;
      padding: 10px 20px;
      p {
        width: 100%;
        padding: 10px 0;
      }
    }
    .emailConetn {
      width: 100%;
      margin-top: 10px;
      background: #eff2f7;
      padding: 10px 20px;
      max-height: 400px;
      overflow: auto;
      p {
        margin-top: 10px;
      }
    }
  }
  /deep/ .el-dialog {
    .el-dialog__header {
      background: #fff;
      border: 0;
    }
    .el-dialog__footer {
      border: 0;
    }
  }
}
</style>