<template>
  <div>
    <div v-show="showcontent"
         class="content">
      <common-head :productOprions="productOption"
                   :statusOptions="statusOptions"
                   @handler-change="handlerChange"
                   :resetNum="resetNum"></common-head>
      <div class="table-head">
        <el-button type="primary"
                   size="medium"
                   @click="addAssets">
          <span class="btn-content">
            <img class="img"
                 src="@/assets/svg/add.svg"><span>Add Asset</span>
          </span>
        </el-button>
        <el-button plain
                   size="medium"
                   @click="handleRefresh">
          <span class="btn-content">
            <img class="img"
                 src="@/assets/svg/refresh.svg"><span>Refresh</span>
          </span>
        </el-button>
        <el-button plain
                   size="medium"
                   @click="handleExport(searchData)">
          <span class="btn-content">
            <img class="img"
                 src="@/assets/svg/export.svg"><span>Export</span>
          </span>
        </el-button>
      </div>
      <common-table :tableHeadList="tableHeadList"
                    :tableData="tableData"
                    :pageNumber="pageNumber"
                    :pageSize="pageSize"
                    :total="total"
                    :hasPagination="true"
                    @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange"
                    @rowClick="rowClick">
        <template v-slot:status="slot">
          <span :style="statusStyle[slot.scope.row.licStatus]">
            {{statusOptions[slot.scope.row.licStatus].name}}
          </span>
        </template>
        <template v-slot:operation="slot">
          <el-button size="medium"
                     plain
                     @click="editActivate(slot.scope.row)">Edit</el-button>
          <el-button size="medium"
                     plain
                     @click="handelUpdate(slot.scope.row)">{{
                    slot.scope.row.licStatus===0?'Activate':'Update'
                  }}</el-button>
          <el-dropdown trigger="click">
            <el-button plain
                       :disabled="slot.scope.row.licStatus===0||slot.scope.row.licStatus===4"
                       size="medium">More
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleDownload(slot.scope.row.id)">Download License</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </common-table>
    </div>
    <el-dialog title="Edit Activation"
               :visible.sync="dialogVisible"
               width="25%"
               :before-close="handleClose">
      <p class="activation-message">Automatic Activation Enable. When Automatic Activation is enabled,
        the device will be activated automatically after it connects to the Internet.
        Automatic activation is not available for software products.</p>
      <el-radio v-model="radio"
                :label="1">Enabled</el-radio>
      <el-radio v-model="radio"
                :label="0">Disabled</el-radio>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">Cancel</el-button>
        <el-button type="primary"
                   @click="submitActivate">Confirm</el-button>
      </span>
    </el-dialog>
    <add-device v-if="showAddDevice"
                @hide-view="hideView"
                @addDeviceChildMsg="addDeviceChildMsg"></add-device>
    <device-details v-if="showDetail"
                    :detailsMsg="detailsMsg"
                    @hide-view="hideView"></device-details>
  </div>
</template>
<script>
import CommonTable from '../components/commonTable.vue'
import CommonHead from '../components/commonHead.vue'
import AddDevice from '../components/addDevice.vue'
import DeviceDetails from '../components/deviceDetails.vue'
import {
  myAssetsList,
  getProductTypeList,
  downloadLicense,
  exprotDevice,
  updateAutoActivate,
} from '@/api/assets'
export default {
  name: 'myAssetsIndex',
  components: {
    CommonTable,
    CommonHead,
    AddDevice,
    DeviceDetails,
  },
  data() {
    return {
      tableHeadList: [
        {
          data: {
            label: 'Serial Number',
            prop: 'sn',
            name: '',
            width: 150,
          },
        },
        {
          data: {
            label: 'Gateway ID',
            prop: 'deviceId',
            name: '',
            width: 150,
          },
        },
        {
          data: {
            label: 'Product',
            prop: 'productType',
            name: '',
            width: 100,
          },
        },
        {
          data: {
            label: 'Model',
            prop: 'model',
            name: '',
            width: 100,
          },
        },
        {
          slot: 'status',
          data: {
            label: 'Status',
            prop: 'licStatus',
            width: 140,
          },
        },
        {
          data: {
            label: 'Order No.',
            prop: 'orderId',
            name: '',
          },
        },
        {
          data: {
            label: 'Added Time',
            prop: 'updateTime',
            name: '',
          },
        },
        {
          data: {
            label: 'Updated Time',
            prop: 'updateTime',
            name: '',
          },
        },
        {
          data: {
            label: 'Expired Date',
            prop: 'softwareUpdateTime',
            name: '',
          },
        },
        {
          data: {
            label: 'Auto Activation',
            prop: 'autoActivate',
            name: '',
          },
        },
        {
          slot: 'operation',
          data: {
            label: 'Operation',
            prop: '',
            width: 350,
          },
        },
      ],
      tableData: [],
      productOption: [],
      statusOptions: [
        { id: 0, name: 'Inactivated' },
        { id: 1, name: 'Activated' },
        { id: 2, name: 'To be Updated' },
        { id: 999, name: '' },
        { id: 4, name: 'Expired' },
      ],
      statusStyle: {
        0: {
          background: '#F4A12D',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: 'inline-block',
        },
        1: {
          background: '#32C093',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: 'inline-block',
        },
        2: {
          background: '#007AFF',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: 'inline-block',
        },
        4: {
          background: '#79828C',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: 'inline-block',
        },
      },
      showAddDevice: false,
      showcontent: true,
      showDetail: false,
      detailsMsg: {
        show: false,
        row: {},
      },
      searchData: {},
      resetNum: 0,
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      radio: 1,
      currentEditActivate: {},
    }
  },

  created() {
    this.getMyAssetsList(), this.getproductList()
  },

  methods: {
    //编辑设备激活
    editActivate(val) {
      this.dialogVisible = true
      this.currentEditActivate = val
      this.radio = this.currentEditActivate.autoActivate
    },
    handleClose() {
      this.dialogVisible = false
    },
    //提交设备激活
    submitActivate() {
      const params = {
        id: this.currentEditActivate.id,
        autoActivate: this.radio,
      }
      updateAutoActivate(params).then((res) => {
        this.getMyAssetsList(this.searchData)
      })
      this.dialogVisible = false
    },
    //分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getMyAssetsList()
    },

    //
    handleCurrentChange(val) {
      this.pageNumber = val
      this.getMyAssetsList()
    },

    addDeviceChildMsg(data) {
      if (data.isSave) {
        this.getMyAssetsList()
      }
      this.showAddDevice = data.close
      this.showDetail = false
      this.showcontent = true
    },
    //顶部筛选
    handlerChange(data) {
      this.searchData = JSON.parse(JSON.stringify(data))
      this.getMyAssetsList(data)
    },

    //重置刷新
    handleRefresh() {
      this.resetNum++
      this.getMyAssetsList()
    },
    //获取列表
    getMyAssetsList(data) {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
      if (data && Object.keys(data).length) {
        params.createBeginTime =
          data.addedTime !== null && data.addedTime && data.addedTime.length > 0
            ? data.addedTime[0]
            : undefined
        params.createEndTime =
          data.addedTime !== null && data.addedTime && data.addedTime.length > 0
            ? data.addedTime[1]
            : undefined
        params.expiredBeginTime =
          data.expiredTime !== null &&
          data.expiredTime &&
          data.expiredTime.length > 0
            ? data.expiredTime[0]
            : undefined
        params.expiredEndTime =
          data.expiredTime !== null &&
          data.expiredTime &&
          data.expiredTime.length > 0
            ? data.expiredTime[1]
            : undefined
        params.licStatus = data.status !== '' ? data.status : undefined
        params.productType = data.productType
        params.searchName = data.searchName.replace(/\s*/g, '')
      }
      myAssetsList(params).then((res) => {
        this.tableData = res.rows.rows
        this.total = res.rows.total
      })
    },
    //获取产品线
    getproductList() {
      getProductTypeList().then((res) => {
        this.productOption = res.rows
      })
    },
    //添加
    addAssets() {
      this.showAddDevice = true
      this.showDetail = false
      this.showcontent = false
    },
    //表格点击
    rowClick(data, column) {
      if (column.property === 'sn') {
        this.detailsMsg.row = data
        this.detailsMsg.activeIndex = 1

        this.showAddDevice = false
        this.showcontent = false
        this.showDetail = true
      }
    },
    //关闭弹窗回调
    hideView() {
      this.showAddDevice = false
      this.showDetail = false
      this.showcontent = true
    },
    //导出
    handleExport(data) {
      const params = {
        pageNumber: '',
        pageSize: '',
      }
      if (data && Object.keys(data).length) {
        params.createBeginTime =
          data.addedTime !== null && data.addedTime && data.addedTime.length > 0
            ? data.addedTime[0]
            : undefined
        params.createEndTime =
          data.addedTime !== null && data.addedTime && data.addedTime.length > 0
            ? data.addedTime[1]
            : undefined
        params.expiredBeginTime =
          data.expiredTime !== null &&
          data.expiredTime &&
          data.expiredTime.length > 0
            ? data.expiredTime[0]
            : undefined
        params.expiredEndTime =
          data.expiredTime !== null &&
          data.expiredTime &&
          data.expiredTime.length > 0
            ? data.expiredTime[1]
            : undefined
        params.licStatus = data.status !== '' ? data.licStatus : undefined
      }
      exprotDevice(params).then((res) => {
        const a = document.createElement('a')
        const blob = new Blob([res.data])
        const objectUrl = URL.createObjectURL(blob)
        a.setAttribute('href', objectUrl)
        a.setAttribute('download', 'Device List.xlsx')
        a.click()
        URL.revokeObjectURL(objectUrl)
      })
    },

    //更新
    handelUpdate(data) {
      this.detailsMsg.row = data
      this.detailsMsg.activeIndex = 2

      this.showDetail = true
      this.showcontent = false
      this.showAddDevice = false
    },

    //下载
    handleDownload(id) {
      let params = id
      downloadLicense(params).then((res) => {
        let fileName = res.headers['content-disposition']
          .split(';')[1]
          .split('=')[1]
        const a = document.createElement('a')
        const blob = new Blob([res.data])
        const objectUrl = URL.createObjectURL(blob)
        a.setAttribute('href', objectUrl)
        a.setAttribute('download', fileName)
        a.click()
        URL.revokeObjectURL(objectUrl)
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  margin-top: 10px;
  .table-head {
    margin: 12px 0;
    .btn-content {
      display: flex;
      align-items: center;
      .img {
        margin-right: 5px;
      }
    }
  }
}
.activation-message {
  word-break: break-word;
  margin-bottom: 15px;
  font-size: 16px;
}
</style>