<template>
  <div class="createWorkOrder">
    <div class="cwo_header">
      <h4 class="cwo_header_title">{{ $t("createcase.createcase") }}</h4>
      <i class="el-icon-close" @click="closeCreatePage"></i>
    </div>
    <div class="cwo_main">
      <div class="cwo_main_item">
        <div class="item_steps">
          <p class="steps_header">1</p>
          <p class="steps_line"></p>
        </div>
        <div class="item_content">
          <div class="item_content_start">
            <p>{{ $t("casetitle.1") }}</p>
          </div>
          <el-form
            :model="createWorkOrderData"
            class="userInfoForm"
            :rules="createWorkOrderRules"
            ref="userInfoForm"
          >
            <el-form-item :label="$t('createcase.companyname')">
              <el-input
                disabled
                :placeholder="$t('createcase.companynameplaceholder')"
                v-model="createWorkOrderData.companyName"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('createcase.customer')">
              <el-autocomplete
                :disabled="allDisabled"
                value-key="name"
                :placeholder="$t('createcase.customerplaceholder')"
                :fetch-suggestions="customerSearch"
                :maxlength="500"
                v-model="createWorkOrderData.customer"
                :trigger-on-focus="false"
                size="small"
              ></el-autocomplete>
            </el-form-item>
          </el-form>
          <div class="item_content_product">
            <span class="product_icon"></span>
            <h3 class="product_title">
              <span>1.1</span>{{ $t("casetitle.2") }}
            </h3>
            <el-form
              :model="createWorkOrderData"
              class="productForm"
              :rules="createWorkOrderRules"
              ref="productForm"
            >
              <el-form-item
                :label="$t('createcase.product')"
                prop="product"
                id="product"
              >
                <el-select
                  :disabled="allDisabled"
                  v-model="createWorkOrderData.product"
                  :placeholder="$t('createcase.productplaceholder')"
                  size="small"
                  @change="productChange"
                >
                  <el-option
                    v-for="val in allProductData"
                    :key="val.id"
                    :label="val.name"
                    :value="val.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('createcase.version')"
                prop="version"
                id="version"
              >
                <el-select
                  :disabled="allDisabled"
                  v-model="createWorkOrderData.version"
                  :placeholder="$t('createcase.versionplaceholder')"
                  size="small"
                >
                  <el-option
                    v-for="val in allVersionData"
                    :key="val.id"
                    :label="val.name"
                    :value="val.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :disabled="allDisabled"
                :label="$t('createcase.gatewayid')"
                prop="gatewayId"
                id="gatewayId"
                style="position: relative"
              >
                <span style="position: absolute; top: -5px; right: 0">
                  <!-- <span style="margin-right:5px; padding:0 4px; border:1px solid black; border-radius: 50%; font-size:12px;">?</span> -->
                  <a
                    href="https://community.sangfor.com/forum.php?mod=viewthread&tid=1057&highlight="
                    target="_blank"
                    style="color: #6173e8"
                    >{{ $t("case.findGateway") }}</a
                  >
                </span>
                <el-input
                  :disabled="allDisabled"
                  :placeholder="$t('createcase.gatewayplaceholder')"
                  v-model="createWorkOrderData.gatewayId"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('createcase.sn')" prop="sn">
                <el-input
                  :disabled="allDisabled"
                  :placeholder="$t('createcase.snPlaceholder')"
                  v-model="createWorkOrderData.sn"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('createcase.poc')" prop="poc" id="poc">
                <el-radio-group
                  v-model="createWorkOrderData.poc"
                  :disabled="allDisabled"
                >
                  <el-radio label="1">Yes</el-radio>
                  <el-radio label="0">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="item_content_issue">
            <span class="issue_icon"></span>
            <h3 class="issue_title"><span>1.2</span>{{ $t("casetitle.3") }}</h3>
            <el-form
              :model="createWorkOrderData"
              class="issueForm"
              :rules="createWorkOrderRules"
              ref="issueForm"
            >
              <el-form-item
                :label="$t('createcase.issuetitle')"
                prop="issueTitle"
                id="issueTitle"
              >
                <el-input
                  :disabled="allDisabled"
                  maxlength="255"
                  type="text"
                  show-word-limit
                  v-model="createWorkOrderData.issueTitle"
                  @blur="searchArticle"
                  :placeholder="$t('createcase.issusplaceholder')"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('createcase.issuedescriptions')"
                prop="issueDescriptions"
                id="issueDescriptions"
              >
                <el-input
                  :disabled="allDisabled"
                  maxlength="4000"
                  show-word-limit
                  type="textarea"
                  :placeholder="$t('createcase.descriptionsplaceholder')"
                  v-model="createWorkOrderData.issueDescriptions"
                ></el-input>
              </el-form-item>
              <el-upload
                :disabled="allDisabled"
                class="upload-demo"
                drag
                :headers="{ Authorization: 'Bearer ' + token }"
                :action="apiUrl + 'api-file/pp/files/upload'"
                :on-remove="handleRemove"
                :on-error="handleError"
                :on-success="handleSuccess"
                :before-upload="beforeAvatarUpload"
                multiple
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  {{ $t("createcase.uploadtext1")
                  }}<em
                    :style="allDisabled == true ? 'color:#606266;' : '#C0C4CC;'"
                  >
                    {{ $t("createcase.uploadtext2") }}</em
                  >
                </div>
                <div class="el-upload__tip" slot="tip">
                  {{ $t("createcase.uploadtext3") }}
                </div>
              </el-upload>
            </el-form>
          </div>
        </div>
        <div class="item_article">
          <div class="item_article_float" :style="articleFloatHeight"></div>
          <div class="item_article_box">
            <h3 class="item_article_heade">{{ $t("createcase.article") }}</h3>
            <div
              class="item_article_count"
              v-if="articleData.length > 0"
              v-loading="loadingArticle"
            >
              <div
                class="count_item"
                v-for="(val, index) in articleData"
                :key="val.articleId"
                @click="gokonwledgeBase(val, 'article')"
              >
                <h6>
                  {{ index + 1 + "." + " " }}<span v-html="val.title"></span>
                </h6>
                <p><span v-html="val.content"></span></p>
              </div>
            </div>
            <div
              class="item_article_count"
              v-if="!articleData.length > 0"
              v-loading="loadingArticle"
            >
              <p class="noData">No Data</p>
            </div>
            <div class="item_article_more" v-if="articleData.length > 0">
              <p class="MoreArticle" @click="gokonwledgeBase({}, 'more')">
                {{ $t("createcase.moreraticles") }}
                <i class="el-icon-arrow-right"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cwo_main_item">
        <div class="item_steps">
          <p class="steps_header">2</p>
          <p class="steps_line"></p>
        </div>
        <div class="item_content" style="width: 100%; min-height: 260px">
          <div class="item_content_start">
            <p>{{ $t("casetitle.4") }}</p>
          </div>
          <div class="item_content_business">
            <div
              class="business_item"
              style="border: 1px solid #34c38f; border-top: 6px solid #34c38f"
              :class="{ active: businessBorder !== 'low' }"
              @click="selectBusiness('low', $event)"
            >
              <h6>
                Low
                <i
                  class="el-icon-circle-check"
                  style="color: rgb(52, 195, 143)"
                  v-if="businessBorder == 'low'"
                ></i>
              </h6>
              <p>
                1. The issues for certain features/capabilities not impacting to
                business operations and no loss of functionality.<br />
                2. Documentation Issues.<br />
                3. General " how to " questions.
              </p>
            </div>
            <div
              class="business_item"
              style="border: 1px solid #50a5f1; border-top: 6px solid #50a5f1"
              :class="{ active: businessBorder !== 'medium' }"
              @click="selectBusiness('medium', $event)"
            >
              <h6>
                Medium<i
                  class="el-icon-circle-check"
                  style="color: rgb(80, 165, 241)"
                  v-if="businessBorder == 'medium'"
                ></i>
              </h6>
              <p>
                1. Performance of the network or application is imparied with
                limited impact on business operations and an acceptable
                workaround or solution exitst.<br />
                2. The issue with non-critical feature or functionality.<br />
                3. Successful workaround in place for a severity 2 issue.
              </p>
            </div>
            <div
              class="business_item"
              style="border: 1px solid #f1b44c; border-top: 6px solid #f1b44c"
              :class="{ active: businessBorder !== 'high' }"
              @click="selectBusiness('high', $event)"
            >
              <h6>
                High<i
                  class="el-icon-circle-check"
                  style="color: rgb(241, 180, 76)"
                  v-if="businessBorder == 'high'"
                ></i>
              </h6>
              <p>
                1. Network or application is operational but highly degrated
                performance to the point of major impact on usage.<br />
                2. Continuous or frequent instabilities affecting customer
                business or network operations.<br />
                3. Inability to deploy a feature, function or capability.<br />
                4. Successful workaround in place for a serverity 1 issue.
              </p>
              <div class="noti" v-if="businessBorder == 'high'">
                <p>
                  Please<a
                    href="https://bitly.com/Contact_Sangfor"
                    target="_blank"
                    style="color: #40a9ff"
                  >
                    contact us</a
                  >
                  to make sure the issue can be fixed in a timely manner.
                </p>
              </div>
            </div>
            <div
              class="business_item"
              style="border: 1px solid #f46a6a; border-top: 6px solid #f46a6a"
              :class="{ active: businessBorder !== 'serious' }"
              @click="selectBusiness('serious', $event)"
            >
              <h6>
                Critical<i
                  class="el-icon-circle-check"
                  style="color: rgb(244, 106, 106)"
                  v-if="businessBorder == 'serious'"
                ></i>
              </h6>
              <p>
                1. Network or application outage, "down or unavailable".<br />
                2. Impaired functionality, critically impacting customer
                business.<br />
              </p>
              <div class="noti" v-if="businessBorder == 'serious'">
                <p>
                  Please<a
                    href="https://bitly.com/Contact_Sangfor"
                    target="_blank"
                    style="color: #40a9ff"
                  >
                    contact us</a
                  >
                  to make sure the issue can be fixed in a timely manner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cwo_main_item">
        <div class="item_steps">
          <p class="steps_header">3</p>
          <p class="steps_line"></p>
        </div>
        <div class="item_content" style="width: 100%">
          <div class="item_content_start">
            <p>{{ $t("casetitle.5") }}</p>
          </div>
          <div class="item_content_contact">
            <el-form
              :model="createWorkOrderData"
              class="contactForm"
              :rules="createWorkOrderRules"
              ref="contactForm"
            >
              <el-form-item
                :label="$t('createcase.contact')"
                prop="contact"
                id="contact"
              >
                <el-input
                  :disabled="allDisabled"
                  v-model="createWorkOrderData.contact"
                  :placeholder="$t('createcase.contactplaceholder')"
                  size="small"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="Contact Way" prop="contactWay">
                <el-select
                  size="small"
                  v-model="createWorkOrderData.contactWay"
                >
                  <el-option label="tel" value="1"></el-option>
                  <el-option label="email" value="2"></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item
                :label="$t('createcase.tel')"
                prop="telephone"
                id="telephone"
              >
                <el-input
                  :disabled="allDisabled"
                  :placeholder="$t('createcase.telplaceholder')"
                  v-model="createWorkOrderData.telephone"
                  size="small"
                  class="input-with-select"
                >
                  <el-select
                    v-model="createWorkOrderData.areaCode"
                    no-match-text="No Data"
                    filterable
                    remote
                    @visible-change="areaCodeFocus"
                    :remote-method="remoteMethod"
                    slot="prepend"
                  >
                    <el-option v-for="val in countryCode" :key="val.id" :label="val.areaCode" :value="val.id">{{val.country+' '+val.areaCode}}</el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item
                :label="$t('createcase.email')"
                prop="email"
                id="email"
              >
                <el-input
                  :disabled="allDisabled"
                  v-model="createWorkOrderData.email"
                  :placeholder="$t('createcase.emailplaceholder')"
                  size="small"
                ></el-input>
              </el-form-item>
              <div class="CcEmail_box">
                <p class="CcEmail_title">
                  <span>{{ $t("createcase.ccemail") }}</span>
                  <!-- <i
                    class="el-icon-circle-plus-outline"
                    style="font-size: 16px; cursor: pointer; color: #556ee6"
                    @click="AddCcEmail"
                  ></i> -->
                </p>
                <div
                  class="input_item"
                  v-for="(val, index) in createWorkOrderData.ccEmail"
                  :key="index"
                >
                  <el-form-item
                    :prop="'ccEmail.' + index"
                    :rules="ccEmailRules.ccEmail"
                  >
                    <el-input
                      :disabled="allDisabled"
                      v-model="createWorkOrderData.ccEmail[index]"
                      size="small"
                    >
                      <i
                        slot="suffix"
                        class="el-input__icon el-icon-delete"
                        @click="delectCcEmail(index)"
                        style="cursor: pointer"
                        v-if="index !== 0"
                      ></i>
                    </el-input>
                  </el-form-item>
                  <i
                    :class="{ disabledColor: allDisabled == true }"
                    class="el-icon-circle-plus-outline addccemail"
                    style="font-size: 16px; cursor: pointer; color: #556ee6"
                    v-if="index == 0"
                    @click="AddCcEmail"
                  ></i>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <div class="cwo_main_item">
        <div class="item_steps">
          <p class="steps_header">4</p>
          <p class="steps_line"></p>
        </div>
        <div class="item_content" style="width: 100%">
          <div class="item_content_start">
            <p>{{ $t("casetitle.6") }}</p>
          </div>
          <div class="item_content_contact">
            <el-form
              :model="createWorkOrderData"
              class="contactTimeForm"
              ref="contactTimeForm"
              :rules="createWorkOrderRules"
            >
              <el-form-item :label="$t('createcase.timezone')">
                <el-select
                  :disabled="allDisabled"
                  v-model="createWorkOrderData.timeZone"
                  filterable
                  clearable
                  :placeholder="$t('createcase.zoneplaceholder')"
                  size="small"
                >
                  <el-option
                    :label="val.timeZone"
                    :value="val.id"
                    v-for="val in TimeZoneData"
                    :key="val.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('createcase.dete')">
                <el-date-picker
                  :disabled="allDisabled"
                  :picker-options="pickerOptions"
                  size="small"
                  v-model="createWorkOrderData.timeStamp"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :placeholder="$t('createcase.dateplaceholder')"
                >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="$t('createcase.Preferredcontactway')"> -->
              <!-- <el-select
                  size="small"
                  v-model="createWorkOrderData.preferredContactWay"
                  :placeholder="$t('createcase.Preferredcontactway2')"
                >
                  <el-option label="Telephone" value="1"></el-option>
                  <el-option label="Email" value="2"></el-option>
                </el-select> -->
              <!-- </el-form-item> -->
              <el-form-item></el-form-item>
            </el-form>
          </div>
          <div class="btnBox">
            <el-button
              size="small"
              style="background: #fff"
              @click="closeCreatePage"
              >{{ $t("createcase.cancel") }}</el-button
            >
            <el-button
              :disabled="allDisabled"
              :loading="saveLoading"
              type="primary"
              size="small"
              style="background: #556ee6"
              @click="saveCase"
              >{{ $t("createcase.submit") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Lockr from "lockr";
import {
  workOrderSave,
  getAllVersion,
  getCustomers,
  getKbArticles,
} from "../../../api/case";
import { filterXSS } from "../../../utils/filterXSS";
export default {
  name: "createWorkOrder",
  data() {
    // var checkCcEmail = (rule, value, callback) => {
    //   const eMailReg =
    //     /^([a-zA-Z0-9_+-.])+@([a-zA-Z0-9_+-])+((\.[a-zA-Z0-9_-]{2,5}){1,2})$/;
    //   if (!value) {
    //     return callback();
    //   }
    //   setTimeout(() => {
    //     if (eMailReg.test(value)) {
    //       callback();
    //     } else {
    //       callback(new Error(this.$t("createcase.checkemail")));
    //     }
    //   }, 200);
    // };
    // var checkEmail = (rule, value, callback) => {
    //   const eMailReg =
    //     /^([a-zA-Z0-9_+-.])+@([a-zA-Z0-9_+-])+((\.[a-zA-Z0-9_-]{2,5}){1,2})$/;
    //   if (!value) {
    //     return callback(new Error(this.$t("createcase.emailplaceholder")));
    //   }
    //   setTimeout(() => {
    //     if (eMailReg.test(value)) {
    //       callback();
    //     } else {
    //       callback(new Error(this.$t("createcase.checkemail")));
    //     }
    //   }, 200);
    // };
    var checkSN = (rule, value, callback) => {
      const snLength = [8, 10, 11, 16, 40];
      if (!value) {
        return callback();
      }
      setTimeout(() => {
        if (snLength.includes(value.length)) {
          callback();
        } else {
          callback(new Error(this.$t("createcase.checksn")));
        }
      }, 200);
    };
    var checkGateWayID = (rule, value, callback) => {
      const snLength = [8, 10, 11, 16, 40];
      if (!value) {
        return callback(new Error(this.$t("createcase.gatewayplaceholder")));
      }
      setTimeout(() => {
        if (snLength.includes(value.length)) {
          callback();
        } else {
          callback(new Error(this.$t("createcase.checkgateway")));
        }
      }, 200);
    };
    var checkIssueDescriptions = (rule, value, callback) => {
      if (!value) {
        return callback(
          new Error(this.$t("createcase.checkissuedescriptions"))
        );
      } else {
        if (value.length > 4000) {
          callback(new Error(this.$t("createcase.checkissuedescriptions2")));
        } else {
          callback();
        }
      }
    };
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("createcase.checktel")));
      } else {
        if (!this.createWorkOrderData.areaCode){
          callback(new Error(this.$t("createcase.checkCountryCode")));
        }else{
           if(value.toString().length > 50){
            callback(new Error(this.$t('formVaild.head') + 50 + this.$t('formVaild.tail')));
          }else{
            callback();
          }
        }
      }
    };
    var vaildContact = (rule, value, callback) => {
      if(!value){
        callback(new Error(this.$t("createcase.checkcontact")));
      }else{
        if(value.toString().length > 50){
          callback(new Error(this.$t('formVaild.head') + 50 + this.$t('formVaild.tail')));
        }else{
          callback();
        }
      }
    };

    var vaildEmail = (rule, value, callback) => {
      if(!value){
        callback(new Error(this.$t("createcase.emailplaceholder")));
      }else{
         if(value.toString().length > 50){
          callback(new Error(this.$t('formVaild.head') + 50 + this.$t('formVaild.tail')));
        }else{
          callback();
        }
      }
    };

    var vaildccEmail = (rule, value, callback) => {
         if(value && value.toString().length > 50){
          callback(new Error(this.$t('formVaild.head') + 50 + this.$t('formVaild.tail')));
        }else{
          callback();
        }
    };
    return {
      allDisabled: false,
      businessBorder: "low",
      articleFloatHeight: { height: "0px" },
      createWorkOrderData: {
        areaCode: "",
        companyName: "",
        customer: "",
        product: "",
        version: "",
        gatewayId: "",
        sn: "",
        poc: "",
        issueTitle: "",
        issueDescriptions: "",
        impactLevel: 1,
        contact: "",
        contactWay: "1",
        telephone: "",
        email: "",
        ccEmail: [""],
        timeZone: "",
        timeStamp: "",
        fileList: [],
        industry: "",
        // preferredContactWay: "",
      },
      createWorkOrderRules: {
        product: [
          {
            required: true,
            message: this.$t("createcase.checkproduct"),
            trigger: "change",
          },
        ],
        version: [
          {
            required: true,
            message: this.$t("createcase.checkversion"),
            trigger: "change",
          },
        ],
        sn: [
          {
            validator: checkSN,
            trigger: "blur",
          },
        ],
        poc: [
          {
            required: true,
            message: this.$t("createcase.checkpoc"),
            trigger: "change",
          },
        ],
        gatewayId: [
          {
            required: true,
            validator: checkGateWayID,
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            validator: vaildContact,
            trigger: ["bulr", "change"],
          },
        ],
        issueTitle: [
          {
            required: true,
            message: this.$t("createcase.checkissustitle"),
            trigger: "blur",
          },
        ],
        issueDescriptions: [
          {
            required: true,
            validator: checkIssueDescriptions,
            trigger: ["bulr", "change"],
          },
        ],
        telephone: [
          {
            required: true,
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        email: [
          // { required: true, validator: checkEmail, trigger: "blur" }
          { 
            required: true, 
            // message: this.$t("createcase.emailplaceholder"), 
            validator: vaildEmail,
            trigger: 'blur' 
          },
          { type: 'email', message: this.$t("createcase.checkemail"), trigger: ['blur', 'change'] }
          ],
      },
      ccEmailRules: {
        ccEmail: [
          { required: true, validator: vaildccEmail, trigger: ["blur"] },
          { 
            type: 'email', 
            message: this.$t("createcase.checkemail"), 
            trigger: ['blur', 'change'] 
          }
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          let seven = 8 * 24 * 60 * 60 * 1000;
          let sevenDays = Date.now() + seven - 8.64e7;
          return (
            time.getTime() < Date.now() - 8.64e7 || time.getTime() > sevenDays
          );
          // return time.getTime() < Date.now() - 8.64e7;
        },
      },
      token: "",
      saveLoading: false,
      loadingArticle: false,
      TimeZoneData: [],
      allProductData: [],
      allVersionData: [],
      customerList: [],
      articleData: [],
      countryCode:[],
    };
  },
  methods: {
    //获取版本号
    productChange(val) {
      let params = {
        keyWord: this.createWorkOrderData.issueTitle,
        moduleId: "",
        pageNumber: 1,
        pageSize: 5,
        sortType: "",
        menuId: val + "",
      };
      this.KbArticles(params);
      this.createWorkOrderData.version = "";
      getAllVersion(val).then((res) => {
        this.allVersionData=res.rows.filter(val=>{
          if(val.name=='unknown version'||val.name=='custom version'){
            return false;
          }
          return true
        })
        // this.allVersionData = res.rows;
      });
    },
    //搜索文章
    searchArticle() {
      // if (this.createWorkOrderData.issueTitle == "") return false;
      if (this.createWorkOrderData.product == "") {
        let params = {
          keyWord: this.createWorkOrderData.issueTitle,
          moduleId: "76",
          pageNumber: 1,
          pageSize: 5,
          sortType: "",
          menuId: "",
        };
        this.KbArticles(params);
      } else {
        let params = {
          keyWord: this.createWorkOrderData.issueTitle,
          moduleId: "",
          pageNumber: 1,
          pageSize: 5,
          sortType: "",
          menuId: this.createWorkOrderData.product + "",
        };
        this.KbArticles(params);
      }
    },
    //选择问题等级
    selectBusiness(val, el) {
      if (this.allDisabled == true) return false;
      const _this =
        el.target === el.currentTarget ? el.target : el.currentTarget;
      this.businessBorder = val;
      if (val == "low") {
        _this.style.backgroundColor = "#F4FFFC";
        this.createWorkOrderData.impactLevel = 4;
        _this.style.boxShadow = "0px 8px 20px rgba(114, 165, 146, 0.35)";
      } else if (val == "medium") {
        _this.style.backgroundColor = "rgba(32, 78, 217, 0.07)";
        _this.style.boxShadow = "0px 8px 20px rgba(114, 165, 146, 0.35)";
        this.createWorkOrderData.impactLevel = 3;
      } else if (val == "high") {
        // this.$alert(this.$t("createcase.contactadmin"), "Notice:", {
        //   confirmButtonText: "Confirm",
        //   callback: () => {
        _this.style.backgroundColor = "rgba(255, 149, 0, 0.08)";
        _this.style.boxShadow = "0px 8px 20px rgba(114, 165, 146, 0.35)";
        this.createWorkOrderData.impactLevel = 2;
        // },
        // });
      } else if (val == "serious") {
        // this.$alert(this.$t("createcase.contactadmin"), "Notice:", {
        //   confirmButtonText: "Confirm",
        //   callback: () => {
        _this.style.backgroundColor = "rgba(230, 80, 80, 0.05)";
        this.createWorkOrderData.impactLevel = 1;
        _this.style.boxShadow = "0px 8px 20px rgba(114, 165, 146, 0.35)";
        // },
        // });
      }
    },
    //关闭页面
    closeCreatePage() {
      this.$emit("createPageStatus", { status: false, isSave: false });
    },
    //点击添加CC Email
    AddCcEmail() {
      if (this.allDisabled == true) return false;
      this.createWorkOrderData.ccEmail.push("");
    },
    //删除CC Email
    delectCcEmail(index) {
      if (this.allDisabled == true) return false;
      this.createWorkOrderData.ccEmail.splice(index, 1);
    },
    //提交工单
    saveCase() {
      let caseList = [];
      caseList.push(
        this.checkForm("userInfoForm"),
        this.checkForm("productForm"),
        this.checkForm("issueForm"),
        this.checkForm("contactForm"),
        this.checkForm("contactTimeForm")
      );
      Promise.all(caseList)
        .then(() => {
          this.saveLoading = true;
          let params = JSON.parse(JSON.stringify(this.createWorkOrderData));
          params.fileList = params.fileList.filter((val) => {
            if (val.url) {
              return true;
            }
          });
          this.$confirm(this.$t("case.confirmSubmit"), this.$t("case.notice"), {
            confirmButtonText: this.$t("case.confirm"),
            cancelButtonText: this.$t("case.cancel"),
            type: "warning",
            closeOnClickModal: false,
          })
            .then(() => {
              params.ccEmail = params.ccEmail ? params.ccEmail.join(";") : "";
              // params.issueDescriptions = filterXSS(params.issueDescriptions);
              workOrderSave(params)
                .then((res) => {
                  this.saveLoading = false;
                  this.$message({
                    type: "success",
                    message: "Submit sucessfully!",
                  });
                  // this.allDisabled = true;
                  setTimeout(() => {
                    this.$emit("createPageStatus", {
                      status: false,
                      isSave: true,
                    });
                  }, 500);
                })
                .catch(() => {
                  this.saveLoading = false;
                  this.$message({
                    type: "success",
                    message: res.msg,
                  });
                });
            })
            .catch(() => {
              this.saveLoading = false;
            });
        })
        .catch((err) => {
          // let errMsg = "";
          let errArr = [];
          for (let key in err) {
            // errMsg += err[key][0].field + "，";
            // 提示信息： err[key][0].field
            errArr.push(err[key][0].field);
          }
          this.$el.querySelector("#" + errArr[0]).scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          // errMsg = errMsg.substring(0, errMsg.length - 1);
          // this.$message({
          //   type: "error",
          //   message:
          //     "Please complete the following required items：" + errMsg + ".",
          //   duration: 4000,
          // });
          this.saveLoading = false;
        });
    },
    checkForm(formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid, obj) => {
          if (valid) {
            resolve();
          } else {
            reject(obj);
          }
        });
      });
    },
    //获取客户
    customerSearch(val, cb) {
      let params = {
        name: val,
      };
      getCustomers(params).then((res) => {
        this.customerList = res.rows;
        var customerList = this.customerList;
        var results = val
          ? customerList.filter(this.customerFilter(val))
          : customerList;
        cb(results);
      });
    },
    customerFilter(val) {
      return (customer) => {
        return customer.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
      };
    },
    //限制文件上传大小
    beforeAvatarUpload(file) {
      const fileType = [
        ".cxg",
        ".jpg",
        ".gif",
        ".bmp",
        ".tiff",
        ".png",
        ".jpeg",
        ".rm",
        ".mvb",
        ".avi",
        ".asf",
        ".wmv",
        ".mov",
        ".mpg",
        ".mpeg",
        ".vob",
        ".fiv",
        ".mp4",
        ".zip",
        ".rar",
        ".gz",
        ".bz2",
        ".tgz",
        ".cab",
        ".7z",
        ".bz",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".pdf",
        ".txt",
        ".Z",
      ];
      let checkfileType = fileType.filter((val) => {
        if (file.name.toLowerCase().indexOf(val) !== -1) {
          return true;
        }
      });
      const islt10M = file.size / 1024 / 1024 < 10;
      if (!islt10M) {
        this.$message.error(this.$t("createcase.islt10M"));
        return islt10M;
      } else if (checkfileType < 1) {
        this.$message.error(this.$t("createcase.uploadtext3"));
        return false;
      } else {
        this.createWorkOrderData.fileList.push({
          uid: file.uid,
          size: file.size,
          name: file.name,
        });
      }
    },
    handleRemove(file) {
      this.createWorkOrderData.fileList.forEach((val, index) => {
        if (val.uid == file.uid) {
          this.createWorkOrderData.fileList.splice(index, 1);
        }
      });
    },
    handleSuccess(file) {
      this.createWorkOrderData.fileList.forEach((val) => {
        if (val.name == file.fileName) {
          val.url = file.url;
          val.groupUrl = file.groupUrl;
        }
      });
    },
    handleError(err) {
      let myError = err.toString(); //转字符串
      myError = myError.replace("Error: ", ""); //去掉前面的
      myError = JSON.parse(myError); //转对象
      this.$message({
        type: "error",
        message: myError.msg,
        duration: 4000,
      });
    },

    //获取kb文章
    KbArticles(val) {
      this.loadingArticle = true;
      let params = val;
      getKbArticles(params)
        .then((res) => {
          this.articleData = res.rows.rows ? res.rows.rows : res.rows;
          this.loadingArticle = false;
        })
        .catch(() => {
          this.articleData = [];
          this.loadingArticle = false;
        });
    },
    //跳转知识库
    gokonwledgeBase(val, type) {
      let url = "";
      switch (process.env.NODE_ENV) {
        case "development":
          url = "http://localhost:8081";
          break;
        case "devtest":
          url = "http://200.200.4.153:8080";
          break;
        case "uat":
          url = "http://200.200.4.187:8080";
          break;
        case "production":
          url = "http://knowledgebase.sangfor.com/";
          break;

        default:
          break;
      }
      if (type == "more") {
        window.open(url + "/searchDetail");
      } else {
        let token = Lockr.get("access_pp_token");
        let data = {
          articleType: val.articleType,
          articleId: val.articleId,
          keyword: "",
        };
        // window.open(url + "/detailPage?&articleData=" + JSON.stringify(data));
        window.open(
          url +
            "/home?data=" +
            JSON.stringify(data) +
            "&token=" +
            token +
            "&jform=sp"
        );
      }
    },
    handleScroll() {
      let scrollTop = document.querySelector(".el-main").scrollTop;
      if (scrollTop > 400) return false;
      this.articleFloatHeight.height = scrollTop + "px";
    },
    remoteMethod(qurey){
      this.countryCode=Lockr.get('countryCode')
      this.countryCode=this.countryCode.filter(val=>{
        if((val.country+" "+val.areaCode).toLowerCase().replace(/\s/g,"").indexOf(qurey.toLowerCase().replace(/\s/g,""))!=-1){
          return true
        }
        return false
      })
    },
    areaCodeFocus(qurey){
      setTimeout(() => {
        if(!qurey){
        this.countryCode=Lockr.get('countryCode')
        }
      }, 200);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  created() {
    this.TimeZoneData = Lockr.get("access_pp_TimeZoneList");
    this.allProductData = Lockr.get("access_pp_allProduct");
    this.countryCode=Lockr.get('countryCode')
    let userInfo = JSON.parse(JSON.stringify(this.$store.state.user.userInfo));
    this.createWorkOrderData.companyName = userInfo.channelName;
    this.createWorkOrderData.email = userInfo.email;
    this.createWorkOrderData.telephone = userInfo.mobile;
    this.createWorkOrderData.areaCode = userInfo.areaCode?Number(userInfo.areaCode):'';
    this.createWorkOrderData.contact = userInfo.surname
      ? userInfo.surname + " " + userInfo.name
      : userInfo.name;
    this.token = Lockr.get("access_pp_token");
    console.log(userInfo);
  },
};
</script>

<style scoped lang="less">
.createWorkOrder {
  width: 100%;
  background: #fff;
  z-index: 999;
  border-radius: 4px;
  position: relative;
  .cwo_header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cwo_header_title {
      font-size: 18px;
      padding: 10px 0;
    }
    i {
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .cwo_main {
    width: 100%;
    padding: 20px;
    .cwo_main_item {
      display: flex;
      .item_steps {
        position: relative;
        width: 32px;
        display: flex;
        justify-content: center;
        .steps_header {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #556ee6;
          text-align: center;
          line-height: 30px;
          font-size: 20px;
          color: #fff;
          font-weight: 500;
          position: absolute;
          top: 0;
          left: 0;
        }
        .steps_line {
          border: 1px dashed #a1a4b4;
        }
      }
      .item_content {
        width: 60%;
        padding: 0 10px 65px 10px;
        .userInfoForm {
          width: 100%;
          display: flex;
          justify-content: space-between;
          /deep/ .el-form-item {
            width: 46%;
            .el-autocomplete {
              width: 100%;
            }
          }
        }
        .item_content_start {
          width: 100%;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          margin-bottom: 30px;
        }
        .item_content_product {
          width: 100%;
          position: relative;
          .product_icon {
            display: inline-block;
            position: absolute;
            width: 14px;
            height: 14px;
            border: 2px solid #556ee6;
            background: #fff;
            border-radius: 50%;
            top: 4px;
            left: -33px;
          }
          .product_title {
            span {
              color: #556ee6;
              margin-right: 8px;
            }
            font-size: 18px;
          }
          .productForm {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 25px;
            /deep/ .el-form-item {
              width: 46%;
              margin-bottom: 15px;
              .el-form-item__label {
                line-height: 30px;
              }
              .el-select {
                width: 100%;
              }
              .el-radio-group {
                width: 100%;
              }
            }
          }
        }
        .item_content_issue {
          width: 100%;
          position: relative;
          margin-top: 15px;
          .issue_icon {
            display: inline-block;
            position: absolute;
            width: 14px;
            height: 14px;
            border: 2px solid #556ee6;
            background: #fff;
            border-radius: 50%;
            top: 4px;
            left: -33px;
          }
          .issue_title {
            span {
              color: #556ee6;
              margin-right: 8px;
            }
            font-size: 18px;
          }
          .issueForm {
            margin-top: 25px;
            /deep/textarea {
              min-height: 150px !important;
              font-family: "MicrosoftYaHei", "Avenir", Helvetica, Arial,
                sans-serif;
            }
            /deep/ .el-upload-dragger {
              width: 250px;
              height: 100px;
              .el-icon-upload {
                font-size: 48px;
                margin: 10px 0 10px;
              }
            }
            /deep/ .el-upload-list__item-name {
              margin-left: 20px;
            }
            /deep/ .el-upload-list__item-status-label {
              left: 0;
              display: block;
            }
          }
        }
        .item_content_business {
          width: 100%;
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          .business_item {
            width: 20%;
            padding: 20px;
            border-radius: 8px;
            transition: all 0.2s;
            min-width: 200px;
            cursor: pointer;
            background: #f4fffc;
            box-shadow: 0px 8px 20px rgba(114, 165, 146, 0.35);
            position: relative;
            h6 {
              font-size: 20px;
              color: #74788d;
              display: flex;
              justify-content: space-between;
            }
            p {
              color: #74788d;
              line-height: 20px;
              font-size: 14px;
              margin-top: 8px;
            }
            .noti {
              position: absolute;
              bottom: -70px;
              left: 0;
            }
          }
          .active {
            background: none !important;
            box-shadow: none !important;
            h6 {
              color: #343a40;
            }
            p {
              color: #343a40;
            }
          }
        }
        .item_content_contact {
          width: 100%;
          .contactForm {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .CcEmail_box {
              width: 100%;
              // display: flex;
              // flex-wrap: wrap;
              //   justify-content: space-between;
              .CcEmail_title {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                span {
                  margin-right: 10px;
                }
              }
              .input_item {
                width: 28%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: relative;
                .disabledColor {
                  color: #c0c4cc !important;
                  cursor: default !important;
                }
                /deep/ .el-form-item {
                  width: 100%;
                  .el-form-item__content {
                    display: flex;
                    align-items: center;
                  }
                }
                .addccemail {
                  position: absolute;
                  top: 20%;
                  right: -30px;
                  font-size: 20px !important;
                }
              }
              .inputMargin {
                margin-left: 8%;
              }
            }
            /deep/ .el-form-item {
              width: 28%;
              margin-bottom: 15px;
              .el-form-item__content {
                line-height: 30px;
              }
              .el-select .el-input {
                width: 90px;
              }
              .input-with-select .el-input-group__prepend {
                background-color: #fff;
              }
              .el-date-editor.el-input,
              .el-date-editor.el-input__inner {
                width: 100%;
              }
            }
          }
          .contactTimeForm {
            display: flex;
            justify-content: space-between;
            /deep/ .el-form-item {
              width: 28%;
              margin-bottom: 15px;
              .el-select {
                width: 100%;
              }
              .el-date-editor.el-input,
              .el-date-editor.el-input__inner {
                width: 100%;
              }
            }
          }
        }
        .btnBox {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 20px;
          /deep/ .el-button {
            margin-right: 10px;
          }
          /deep/ .el-button--primary.is-disabled,
          .el-button--primary.is-disabled:active,
          .el-button--primary.is-disabled:focus,
          .el-button--primary.is-disabled:hover {
            color: #fff;
            background-color: #a0cfff !important;
            border-color: #a0cfff !important;
          }
        }
      }
      .item_article {
        width: 33%;
        margin-left: 60px;
        .item_article_float {
          width: 100%;
        }
        .item_article_box {
          width: 100%;
          border-radius: 4px;
          padding: 20px;
          background: #f4f5f8;
          max-height: 645px;
          .item_article_heade {
            width: 100%;
            font-size: 20px;
            color: #343a40;
            font-weight: 600;
          }
          .item_article_count {
            width: 100%;
            padding: 20px 0;
            height: 560px;
            overflow-y: auto;
            position: relative;
            .count_item {
              width: 100%;
              margin-bottom: 30px;
              h6 {
                font-size: 16px;
                line-height: 24px;
                color: rgb(85, 110, 230);
                cursor: pointer;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 5px 0;
              }
              p {
                padding-left: 20px;
                line-height: 20px;
                color: #74788d;
                cursor: pointer;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
            }
            .noData {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 18px;
              color: #74788d;
            }
          }
          .item_article_more {
            width: 100%;
            .MoreArticle {
              font-weight: bold;
              color: #556ee6;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
