<template>
  <div class="scene-container">
    <div class="scene-list">
      <div
        v-for="(item, index) in sceneList"
        :key="index"
        :class="{ 'scene-list-item-select': item.id == sceneSelectId }"
        class="scene-list-item"
        @click="selectScene(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div>
      <flexbox class="handle-button" @click.native="addScene">
        <img class="handle-button-icon" src="@/assets/img/scene_add.png" />
        <div class="handle-button-name">{{ "新建场景" }}</div>
      </flexbox>
      <flexbox class="handle-button" @click.native="setScene">
        <img class="handle-button-icon" src="@/assets/img/scene_set.png" />
        <div class="handle-button-name">{{ "编辑场景" }}</div>
      </flexbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getScene } from "@/api/common";
export default {
  name: "SceneList",
  props: {
    /** 没有值就是全部类型 有值就是当个类型 */
    crmType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 场景列表
      sceneSelectId: -1,
      sceneList: [],
    };
  },
  mounted() {
    this.getSceneList();
  },
  methods: {
    getSceneList() {
      var objectCode = "";
      objectCode = this.crmType;
      getScene({
        objectCode,
      }).then((res) => {
        this.sceneList = res.rows.filter((e) => e.isHide == 0);
        let isDefaultData = {};
        this.sceneList.map((item) => {
          if (item.isDefault == 1) {
            isDefaultData = item;
          }
        });
        this.sceneSelectId =
          isDefaultData.id || (this.sceneList[0] && this.sceneList[0].id);
        if (this.sceneSelectId) {
          this.selectScene(
            isDefaultData.id ? isDefaultData : this.sceneList[0]
          );
        }
      });
    },
    // 选择场景、
    selectScene(item) {
      this.sceneSelectId = item.id;
      this.$emit("scene", {
        id: item.id,
        name: item.name,
        bydata: item.data,
      });
      this.$emit("hidden-scene");
    },
    // 添加场景
    addScene() {
      this.$emit("scene-handle", { type: "add" });
    },
    // 设置场景
    setScene() {
      this.$emit("scene-handle", { type: "set" });
    }
  },
};
</script>

<style lang="less" scoped>
.scene-container {
  position: relative;
  width: 150px;
}
.scene-list {
  max-height: 240px;
  overflow-y: auto;
  font-size: 12px;
  .scene-list-item {
    color: #333;
    padding: 10px 15px;
    cursor: pointer;
    background-color: white;
  }
  .scene-list-item:hover {
    background-color: #f7f8fa;
    color: #3e84e9;
  }

  .scene-list-item-select {
    color: #3e84e9;
    background-color: #f7f8fa;
  }
}

.handle-button {
  padding: 10px 20px;
  cursor: pointer;
  .handle-button-icon {
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
  .handle-button-name {
    font-size: 12px;
  }
}
.handle-button:hover {
  color: #3e84e9;
}
</style>