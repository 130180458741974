<template>
  <div>
    <!-- <c-r-m-table-header
      ref="crmTableHead"
      class="crm-table-header"
      :crmType="crmType"
      :crmName="crmName"
      :fieldList="fieldFormList"
      searchPlaceholder="Please input Application NO,Customer Name or Project Name"
      @on-search="crmSearch"
      @filter="handleFilter"
    >
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        style="background-color: #556ee6; margin-left: 12px"
        @click="showPackageSave"
        >设备使用申请</el-button
      >
    </c-r-m-table-header> -->
    <flexbox class="crm-table-header" justify="space-between">
      <flexbox-item></flexbox-item>
      <flexbox-item style="margin: 0 20px; width: 400px">
        <el-input
          v-model="search"
          size="small"
          :placeholder="$t('other.102')"
          clearable
          @keyup.enter.native="crmSearch(search)"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native="crmSearch(search)"
          />
        </el-input>
      </flexbox-item>
      <flexbox-item>
        <flexbox justify="flex-end">
          <!-- 设备使用申请 -->
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            style="background-color: #556ee6; margin-left: 12px"
            @click="showPackageSave"
            >{{ $t("allEquipment.equipmentApply") }}</el-button
          >
        </flexbox>
      </flexbox-item>
    </flexbox>
    <el-table
      v-loading="loading"
      id="crm-table"
      ref="crmTable"
      :data="list"
      :height="tableHeight"
      :cell-style="cellStyle"
      class="n-table--border"
      stripe
      border
      highlight-current-row
      style="width: 100%"
      @row-click="handleRowClick"
      @header-dragend="handleHeaderDragend"
    >
      <el-table-column
        type="index"
        width="50"
        :label="$t('allEquipment.header.NO')"
        fixed
        style="text-align: center"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in fieldList"
        :key="index"
        :prop="item.fieldName"
        :label="item.name + '&' + item.fieldCode"
        :width="item.width"
        :formatter="fieldFormatter"
        sortable
        show-overflow-tooltip
      >
        <template slot="header" slot-scope="scope">
          <div class="table-head-name">
            {{ scope.column.label && scope.column.label.split("&")[0] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column />
      <el-table-column fixed="right" width="100">
        <template slot="header" slot-scope="slot">
          <div style="display: flex; align-items: center">
            <span style="margin-right: 10px">Action</span>
            <fields-pover
              :crmType="crmType"
              @set-success="setSave"
            ></fields-pover>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="p-contianer">
      <el-pagination
        :current-page="pageNumber"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :total="total"
        class="p-bar"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 相关详情页面 -->
    <c-r-m-all-detail
      :visible.sync="showAllDview"
      :crm-type="rowType"
      :row="row"
      :id="rowID"
      @handle="handleHandle"
    />
    <c-r-m-create-view
      :crmType="rowType"
      :visible.sync="showCreate"
      :action="createActionInfo"
    ></c-r-m-create-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import table from "@v/mixins/table";
import CRMTableHeader from "@c/CRMTableHeader.vue";
import FieldsPover from "@c/fieldsManager/FieldsPover.vue";
import { getPackageList } from "@/api/allEquipment";
import { findDIctName, findFullName, moneyFormat } from "@/utils";
export default {
  name: "PackageIndex",
  components: {
    CRMTableHeader,
    FieldsPover,
  },
  mixins: [table],
  data() {
    return {
      crmType: "package",
      crmName: "package",
      createActionInfo: {
        type: "save",
      }, // 新建、编辑数据
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.updateTableHeight();
    });
  },
  methods: {
    /** 通过回调控制style */
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (row.packageId && column.property == "packageName") {
        return { color: "#3E84E9", cursor: "pointer" };
      } else {
        return "";
      }
    },
    /** 列表操作 */
    // 当某一行被点击时会触发该事件
    handleRowClick(row, column, event) {
      if (column && row.packageId && column.property == "packageName") {
        this.showAllDview = true;
        this.rowType = "packageDetail";
        this.row = row;
        this.rowID = row.packageId;
      }
    },
    showPackageSave() {
      this.showCreate = true;
      this.rowType = "borrowEquipmentApply";
    },
    /** 通过回调控制style */
    fieldFormatter(row, column) {
      if (column.property == "itemStatus") {
        return findDIctName("product_status", row[column.property]);
      } else if (column.property == "itemQouta") {
        return moneyFormat(row[column.property]);
      } else if (
        column.property == "expirationTime" ||
        column.property == "endDate"
      ) {
        return row[column.property]
          ? moment(row[column.property]).format("YYYY-MM-DD")
          : "--";
      } else if (column.property == "packageStatus") {
        return findDIctName("package_status", row[column.property]);
      } else if (column.property == "depositStatus") {
        return findDIctName("deposit_status", row[column.property]);
      } else {
        return row[column.property] || "--";
      }
    },
    getList(refresh) {
      this.loading = true;
      if (refresh) {
        this.pageNumber = 1;
      }
      let params = {};
      params = Object.assign(
        {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          deptId: this.userInfo.deptId,
          productType: "2",
          searchName: this.search,
          channelId: this.userInfo.channelId,
        }
        // this.sortData
      );
      getPackageList(params)
        .then((res) => {
          this.list = res.rows.rows;
          this.pageSize = res.rows.pageSize;
          this.total = res.rows.total;
          this.loading = false;
          this.$nextTick(() => {
            this.updateTableHeight();
            this.$refs.crmTable && this.$refs.crmTable.doLayout();
          });
        })
        .catch((err) => {
          window.console.log("err", err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.crm-table-header {
  font-size: 13px;
  height: 50px;
  padding: 0;
}
</style>