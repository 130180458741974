<template>
<div class='productSelection'>
    <!-- <el-input style="width: 35%;" size="mini" ></el-input> -->  
    <div class="productTitle">
        <h2 class="title">{{$t('productSelection.productSelection')}}</h2> 
    <el-form ref="form" :model="form" label-position="left" :rules="rules" @submit.native.prevent>
        <div class="notice_div">
            <el-form-item :label="$t('productSelection.projectName')+':'" prop="entryName">
            <el-input style="width: 80%;" maxlength="500" :disabled='cartList.length>0' size="medium" v-model="form.entryName" @blur="()=>{form.entryName = Trim(form.entryName)}"></el-input>  
        </el-form-item>
        <span class="notice">{{$t('productSelection.notice')}}</span>
        </div>
        <el-form-item>
            <el-button size="medium" v-if="cartList.length>0" @click="ReplaceTheProject">{{$t('productSelection.ReplaceTheProject')}}</el-button>
        </el-form-item>
    </el-form>
    </div>
    <el-menu
      :default-active="productSelectionMenu"
      mode="horizontal"
      text-color="#A1A4B4"
      active-text-color="#556EE6"
      class="productSelectionMenu"
      @select="handleSelect"
    >
      <el-menu-item index="Scenario">{{$t('productSelection.Performance')}}</el-menu-item>
      <el-menu-item index="Competitor">{{$t('productSelection.Competitor')}}</el-menu-item>
    </el-menu>
      <div class="productComponent">
      <keep-alive>
          <!-- 组件 -->
        <component ref="componentList" :clearForm='clearForm' :clearPage='clearPage' :details='details' :showComponent="showComponent" :index-form='form' @SaveSelectionResults='SaveSelectionResults' :is="getComponentName" @pageScroll='pageScroll' :Detail='Detail' :entry-name='form.entryName' @getDetails='getDetails' @addShoppingCart='addShoppingCart'></component>
      </keep-alive>
    </div>
    <div class="shoppingCart" @click="showCart">
        <el-badge :value="cartList.length>0?cartList[0].carItem.length:0" class="item" >
        <!-- <el-button type="primary"  class="shoppingCartBtn">购物车</el-button> -->
        <span class="shoppingCartBtn" ><img src="../../../assets/cart.png" alt="" srcset=""></span>
        </el-badge>
    </div>
    <Shopping v-if="shoppingShow"  :refresh='refresh' @SaveSelectionResults='SaveSelectionResults' @getDetails='getDetails' :index-form='form' class="shopping" :cartList='cartList' @closeCart='closeCart' @showDetails='showDetails'/>
    <!-- <Details class="details" v-if="showDetail"/> -->
</div>
  
</template>

<script>
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import  Competitor from './components/competitor'
import  Scenario  from './components/scenario'
import  Shopping  from './components/shoppingCart'
import { getCarDetail,removeCarDetail,saveResults,addShoppingCart } from '@/api/productSelection'
import { getAuth } from '@/utils/auth' // 验权
export default {
    components: {
        Competitor,Scenario,Shopping
    },
    //路由守卫  监听本页面退出
     beforeRouteLeave (to, from, next) {
         if(!this.allowTo && getAuth()){
          this.$confirm(this.$t('productSelection.confirmMsg'), this.$t('productSelection.promptTips'), {
          confirmButtonText: this.$t('common.btn.sure'),
          cancelButtonText: this.$t('common.btn.cancel'),
          type: 'warning'
        }).then(()=>{
            next()
        }).catch(()=>{
        })        
         }else{
             next()
         }
        if(from.path==='/productSelection/productSelection'){
          window.removeEventListener('beforeunload', this.beforeunloadFn,false)
        }
        NProgress.done()
  },
  data () {
      return {
        productSelectionMenu:'Scenario',
        shoppingShow:false,
        form:{
            entryName:''
        },
        allowTo:false,
        refresh:true,
        showDetail:false,
        Detail:false,
        cartList:[],
        showComponent:true,
        clearPage:false,
        rules:{
            entryName:[{required: true,message:'',trigger: ['change','blur']}]
        },
        details:{},
        clearForm:true
      }
  },
  computed: {
      //组件判断
      getComponentName(){
          if(this.productSelectionMenu == 'Scenario'){
              this.form.recommendType = 1
              return 'Scenario'
          }else if(this.productSelectionMenu == 'Competitor'){
              this.form.recommendType = 2
              return 'Competitor'
          }
      }
  },
  watch: {
      'form.entryName':{
          handler(val){
              if(!val.length>0){
                  this.allowTo = false
              }
          }
      },
      'cartList':{
          handler(val){
              if(!val.length>0){
                  this.allowTo = true
              }else{
                  this.allowTo = false
              }
          }
      },
  },
  created () {
      this.removeCarDetail()  
      this.$nextTick(()=>[
               this.$refs.form.clearValidate(),//清除校验
            ])
      window.addEventListener('beforeunload', this.beforeunloadFn)
  },
  mounted () {
  },
  beforeDestroy(){ 
  },
  destroyed(){
    window.removeEventListener('beforeunload', this.beforeunloadFn,false)
  },
  methods: {
      handleSelect(val){
        this.productSelectionMenu = val
      },
      showCart(){          
          this.shoppingShow = this.shoppingShow == false?true:false
      },
      closeCart(val){
          this.shoppingShow = val
      },
      //打开弹窗隐藏滚动条
      pageScroll(val){
          var productSelection = document.getElementsByClassName('productSelection')[0]
          if(val==true){
              productSelection.style='overflow: hidden;'
          }else if(val==false){
              productSelection.style='overflow: auto;'
              this.Detail= false
          }
      },
      showDetails(val){
          this.Detail = false
          this.details = val
          setTimeout(() => {
             this.Detail = true
          }, 100);
          this.shoppingShow = false
      },
      //浏览器监听
        // bindCloseBrowser() {
        //     window.addEventListener("beforeunload",this.beforeunloadHandler);
        // },
        // beforeunloadHandler(e) {
        //     e.returnValue = "确定要关闭窗口吗？";
        // },
        // removeCloseBrowser(){
        //     window.removeEventListener("beforeunload", this.beforeunloadHandler,false)
        // },
        beforeunloadFn(e){
            e.returnValue = "确定要关闭窗口吗？";
        },
    //获取购物车详情
    getCarDetail(){
        this.refresh = false
        getCarDetail().then(res=>{
            if(res.code == 0){
                this.cartList = res.rows
                this.showComponent = true
                this.refresh = true
            }
        })
    },
    getDetails(){
        this.getCarDetail()
    },
    //更换项目
    ReplaceTheProject(){
         this.$confirm(this.$t('productSelection.confirmMsg'), this.$t('productSelection.promptTips'), {
          confirmButtonText: this.$t('common.btn.sure'),
          cancelButtonText: this.$t('common.btn.cancel'),
          type: 'warning'
        }).then(()=>{
            this.allowTo=false
            this.clearPage = !this.clearPage
            this.removeCarDetail()
        }).catch(()=>{

        })
    },
    //清空购物车
    removeCarDetail(){
        removeCarDetail().then(()=>{
            this.$set(this.form,'entryName','')
            this.getCarDetail()
            this.showComponent = false
            this.clearForm  = !this.clearForm
        })
     },
     //保存选型结果
        SaveSelectionResults(params){
            saveResults(params).then(res=>{
                if(res.code == 0){
                    this.allowTo = true
                    this.$message.success(this.$t('productSelection.saveSuccess'))
                    this.$router.push('/SelectionOfRecord/SelectionOfRecord')
                }
            })
        },
     //加入购物车
     addShoppingCart(params){
          addShoppingCart(params).then(res=>{
                if(res.code == 0){
                localStorage.setItem('entryId',res.rows)
                this.getCarDetail()
                this.$message.success(this.$t('productSelection.enterSuccess'))
                }
            })
     },
     //失焦去除收尾空格
     Trim(mystr){
      //去掉首部空格
      while (mystr.indexOf(" ")==0 && mystr.length>1){
            mystr=mystr.substring(1,mystr.length);
       }
       //去掉尾部空格
       while (mystr.lastIndexOf(" ")==(mystr.length-1) && mystr.length>1){
           mystr=mystr.substring(0,mystr.length-1);
      }      
      if(mystr == " "){
        return "";    
      }        
        return mystr;
 }
  }
}
</script>

<style lang="less" scoped>
    .productSelection{
        height: 100%;
        background-color: #ffffff;
        overflow: auto;
        .productTitle{
            padding: 29px 20px 0px 20px ;
            position: relative;
            /deep/ .el-form{
                display: flex;
                .el-form-item{
                    flex: 1;
                    .el-form-item__content{
                         .el-input{
                        width: 100%;
                    }
                    .el-button{
                        background: #556EE6;
                        border: 1px solid #556EE6;
                        opacity: 1;
                        border-radius: 2px;
                        font-size: 14px;
                        font-family: FZLanTingHeiS;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                    }
                }
            }
        .title{
            display: inline-block;
            font-size: 18px;
            font-family: FZLanTingHeiS-DB-GB;
            // font-weight: 400;
            line-height: 21px;
            color: #343A40;
            opacity: 1;
            margin-bottom: 25px;
        }
         .notice{
        position: absolute;
        bottom: 0;
        left: 6rem;
        font-size: 12px;
        font-family: FZLanTingHeiS;
        font-weight: 400;
        line-height: 26px;
        color: #F46A6A;
        opacity: 1;
    }
        }
        .productComponent{
            overflow-x: auto;
            padding: 0 20px 32px 20px;
            
            // height: 92%;
        }
        .shoppingCart{
            position: absolute;
            right: 3px;
            top: 112px;
            cursor: pointer;
            z-index: 9;
            .item{
                .shoppingCartBtn{
                    writing-mode: vertical-lr;
                    width: 40px;
                    height: 80px;
                    background: #556EE6;
                    opacity: 1;
                    border-radius: 8px 0px 0px 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    // font-weight: 600;
                    // line-height: 20px;
                    color: #FFFFFF;
                    opacity: 1;
                    .el-icon-shopping-cart-2{
                        display: inline-block;
                        font-size: 20px;
                    }
            }
            }
            /deep/.el-badge__content.is-fixed{
                position: absolute;
                top: 22px;
                right: 24px;
            }
            .el-badge__content {
                background-color: #F56C6C;
                border-radius: 10px;
                color: #FFF;
                display: inline-block;
                font-size: 12px;
                height: 14px;
                line-height: 14px;
                padding: 0 3px;
                text-align: center;
                white-space: nowrap;
                border: 1px solid #FFF;
            }
        }
        .shopping{
            display: flex;
            position: absolute;
            right: 3.125rem;
            top: 65px;
            z-index: 999;
            width: 29%;
            height: 55%;
        }
    }
    .notice_div{
        position: relative;
        flex: 1;
        /deep/.el-form-item{
            display: flex;
            .el-form-item__content{
                flex: 5;
                .el-input{
                    width: 80%;
                }
            }
        }
    }
    /deep/ .el-menu-item{
        font-weight: bold;
    }
    .notice_div{
        /deep/ .el-form-item__error{
            display: none;
        }
    }
    @import "../../../styles/tooltip.css";
</style>