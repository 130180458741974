<template>
  <div class="workorder">
    <div class="wo_header" v-if="noticeDetail">
      <div class="wo_heade_tooltip">
        <h4>{{this.$t('case.serviceProviderNotice')}}</h4>
      </div>
      <div class="wo_heade_card">
        <div class="wo_card_item">
          <p>
            <pre v-html="noticeDetails" style="white-space: break-spaces; width:100%; line-height:22px;"></pre>
            <!-- <span v-html="noticeDetails"></span> -->
          </p>
        </div>
      </div>
    </div>
    <div class="wo_main">
      <!-- 搜索区域 -->
      <div class="wo_main_head">
        <el-form ref="form" :model="workOrderForm" class="workOrderForm">
          <el-form-item>
            <el-date-picker
              type="daterange"
              range-separator="-"
              :start-placeholder="$t('caselist.startdate')"
              :end-placeholder="$t('caselist.enddate')"
              v-model="beginAndEndTime"
              style="width: 100%"
              size="small"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select
              multiple
              collapse-tags
              v-model="workOrderForm.product"
              :placeholder="$t('caselist.porduct')"
              size="small"
            >
              <el-option
                v-for="item in ProductData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="workOrderForm.status"
              :placeholder="$t('caselist.status')"
              size="small"
            >
              <el-option label="Closed" value="0"></el-option>
              <el-option label="Open" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="workOrderForm.issueTitle"
              size="small"
              :placeholder="$t('caselist.issustitle')"
              prefix-icon="el-icon-search"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="workOrderForm.sn"
              size="small"
              :placeholder="$t('caselist.snid')"
              prefix-icon="el-icon-search"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="
              width: 16%;
              min-width: 280px;
              margin-left: auto;
              margin-right: 0;
            "
          >
            <el-button
              type="primary"
              @click="handleSearch('Search')"
              icon="el-icon-search"
              size="small"
              >{{ $t("caselist.search") }}</el-button
            >
            <el-button
              type="primary"
              @click="handleSearch('Refesh')"
              icon="el-icon-refresh"
              size="small"
              >{{ $t("caselist.reset") }}</el-button
            >
            <el-button
              type="primary"
              @click="handleCreate"
              icon="el-icon-circle-plus-outline"
              size="small"
              >{{ $t("caselist.create") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <!-- 表格区域 -->
      <div class="wo_main_table" v-if="showTable">
        <el-table
          border
          ref="multipleTable"
          v-loading="tableLoading"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column :label="$t('createcase.caseNo')" align="center" width="130">
            <template slot-scope="scope">
              <p
                style="color: #556ee6; cursor: pointer"
                @click="goWorkOrderDetail(scope.row, 'caseNo')"
              >
                {{ scope.row.caseNo ? scope.row.caseNo : "--" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('createcase.customer')"
            align="center"
            width="130"
            :show-overflow-tooltip="true"
            prop="customer"
          >
          </el-table-column>
          <el-table-column :label="$t('createcase.product')" width="80" align="center"
            ><template slot-scope="scope">
              <p
                style="
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{
                  ProductData.filter((val) => {
                    if (scope.row.product == val.id) {
                      return true;
                    }
                    return false;
                  })[0]
                    ? ProductData.filter((val) => {
                        if (scope.row.product == val.id) {
                          return true;
                        }
                        return false;
                      })[0].name
                    : ""
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('createcase.title')"
            align="center"
            :show-overflow-tooltip="true"
            prop="issueTitle"
          >
          </el-table-column>
          <el-table-column
          :label="$t('createcase.description')"
            align="center"
          >
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.issueDescriptions.substring(0,1000)" placement="top" :disabled="!(scope.row.issueDescriptions.length>27)" :open-delay="300">
              <span style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;width: 100%; display: inline-block;">{{scope.row.issueDescriptions}}</span>
            </el-tooltip>
          </template>
          </el-table-column>
          <el-table-column :label="$t('createcase.status')" width="80" align="center"
            ><template slot-scope="scope">{{
              scope.row.status == 1 ? "Open" : "Closed"
            }}</template>
          </el-table-column>
          <el-table-column :label="$t('createcase.Severity')" width="120" align="center">
            <template slot-scope="scope"
              ><span :style="impactLevelDic[scope.row.impactLevel].color">{{
                impactLevelDic[scope.row.impactLevel].name
              }}</span></template
            ></el-table-column
          >
          <el-table-column :label="$t('createcase.updateTime')" width="140" align="center"
            ><template slot-scope="scope">
              {{
                scope.row.modifiedDate
                  ? moment(scope.row.modifiedDate).format("YYYY-MM-DD HH:mm:ss")
                  : "--"
              }}</template
            ></el-table-column
          >
          <el-table-column :label="$t('createcase.submitter')" width="120" align="center"
            ><template slot-scope="scope">
              {{ scope.row.createdByName }}</template
            ></el-table-column
          >
          <el-table-column :label="$t('createcase.Evaluation')" width="120" align="center"
            ><template slot-scope="scope">
              <span
                class="rateColor"
                @click="goWorkOrderDetail(scope.row, 'rate')"
                style="cursor: pointer"
                v-if="scope.row.status !== 1&&scope.row.isRate == 0&&(userInfo.isAdmin==1||userInfo.userId==scope.row.createdBy)"
                :class="{
                  isRateTrue: scope.row.isRate == 1,
                  isCaseOpen: scope.row.status == 1,
                }"
                >Rate</span
              >
              <span class="rateColor"
              :class="{
                  isRateTrue: scope.row.isRate == 1,
                }"
                v-if="scope.row.status !== 1&&scope.row.isRate == 1"
                @click="goWorkOrderDetail(scope.row, 'rate')"
                style="cursor: pointer">Rated</span>
              </template
            ></el-table-column
          >
        </el-table>
      </div>
      <div class="wo_main_pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="workOrderForm.pageNumber"
          :page-sizes="[10, 15, 30, 60, 100]"
          :page-size="workOrderForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
      <div></div>
    </div>

    <!-- 组件区域 -->
    <workorder-detail
      v-if="showWorkOrderDetail"
      class="detailPage"
      @detailPageStatus="detailPageStatus"
      :detailPageData="detailPageData"
      :rated="rated"
    ></workorder-detail>
    <create-work-order
      v-if="showCreatePage"
      class="createPage"
      @createPageStatus="createPageStatus"
    ></create-work-order>
  </div>
</template>

<script>
import CreateWorkOrder from "./createWorkOrder.vue";
import workorderDetail from "./workorderDetail.vue";
import {
  getWorkOrderlist,
  getTimeZoneList,
  getNotice,
  getAllProduct,
  getAreaCode
} from "../../../api/case.js";
import Lockr from "lockr";
import moment from "moment";
import { parse } from "qs";
export default {
  components: { workorderDetail, CreateWorkOrder },
  name: "workorder",
  data() {
    return {
      showWorkOrderDetail: false,
      showCreatePage: false,
      tableLoading: false,
      showTable: true,
      detailPageData: {},
      rated: false,
      beginAndEndTime: [],
      workOrderForm: {
        product: [],
        sn:'',
        status: "",
        issueTitle: "",
        issueDescriptions: "",
        beginTime: "",
        endTime: "",
        pageNumber: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      defaultWorkOrderForm: [],
      tableData: [],
      impactLevelDic: {
        4: { name: "Low", color: { color: "#34C38F" } },
        3: { name: "Medium", color: { color: "#50A5F1" } },
        2: { name: "High", color: { color: "#F1B44C" } },
        1: { name: "Critical", color: { color: "#F46A6A" } },
      },
      ProductData: [],
      noticeDetail: "",
      userInfo: {},
    };
  },
  methods: {
    //搜索
    handleSearch(val) {
      if (val == "Search") {
        let params = JSON.parse(JSON.stringify(this.workOrderForm));
        params.issueTitle =
          params.issueTitle !== ""
            ? params.issueTitle.replace(/(^\s*)|(\s*$)/g, "")
            : "";
        params.beginTime = this.beginAndEndTime[0];
        params.endTime = this.beginAndEndTime[1];
        this.WorkOrderList(params);
      } else if (val == "Refesh") {
        this.beginAndEndTime = [];
        this.workOrderForm = JSON.parse(
          JSON.stringify(this.defaultWorkOrderForm)
        );
        let params = this.workOrderForm;
        this.WorkOrderList(params);
      }
    },
    //获取工单列表
    WorkOrderList(val) {
      this.tableLoading = true;
      getWorkOrderlist(val)
        .then((res) => {
          this.tableData = res.rows.rows;
          this.pageTotal = res.rows.total;
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableData = [];
          this.pageTotal = 0;
          this.tableLoading = false;
        });
    },
    //打开创建工单页面
    handleCreate() {
      this.showTable = false;
      this.showCreatePage = true;
    },
    handleSelectionChange() {},
    //打开详情页
    goWorkOrderDetail(val, rate) {
      if (rate == "rate") {
        if (val.status == 0) {
          this.showTable = false;
          this.rated = true;
          this.detailPageData = val;
          this.showWorkOrderDetail = true;
        } else {
          this.$message({
            message: "Case is not closed and cannot be evaluated.",
            type: "warning",
          });
        }
      } else {
        this.showTable = false;
        this.rated = false;
        this.detailPageData = val;
        this.showWorkOrderDetail = true;
      }
    },
    //关闭创建工单页面
    createPageStatus(val) {
      this.showCreatePage = val.status;
      this.showTable = true;
      if (val.isSave) {
        this.WorkOrderList(this.workOrderForm);
      }
    },
    //关闭详情页
    detailPageStatus(val) {
      this.showTable = true;
      this.showWorkOrderDetail = val.status;
      if (val.isSave) {
        this.WorkOrderList(this.workOrderForm);
      }
    },
    //获取时区
    TimeZoneList() {
      getTimeZoneList().then((res) => {
        Lockr.set("access_pp_TimeZoneList", res.rows);
      });
    },
    //分页
    handleSizeChange(val) {
      this.workOrderForm.pageSize = val;
      let params = JSON.parse(JSON.stringify(this.workOrderForm));
      params.pageSize = val;
      this.WorkOrderList(params);
    },
    handleCurrentChange(val) {
      this.workOrderForm.pageNumber = val;
      let params = JSON.parse(JSON.stringify(this.workOrderForm));
      params.pageNumber = val;
      this.WorkOrderList(params);
    },

    //获取服务商通知
    allNotice() {
      getNotice().then((res) => {
        this.noticeDetail = res.rows ? res.rows.notice_detail : "";
      });
    },

    //获取产品线
    allProduct() {
      getAllProduct().then((res) => {
        this.ProductData=res.rows.filter(val=>{
          if(val.name!=="Others"){
            return true
          }
          return false
        })
        Lockr.set("access_pp_allProduct", this.ProductData);
      });
    },
    //获取国际区号
    getCountryCode(){
      getAreaCode({}).then(res=>{
        Lockr.set('countryCode',res.rows)
      })
    }
  },
  created() {
    //初始化表单所有值
    this.defaultWorkOrderForm = JSON.parse(JSON.stringify(this.workOrderForm));
    this.userInfo = this.$store.state.user.userInfo;
    this.WorkOrderList(this.defaultWorkOrderForm);
    this.TimeZoneList();
    this.allProduct();
    this.allNotice();
    this.getCountryCode();
  },
  computed: {
    moment() {
      return moment;
    },
    noticeDetails() {
      if (this.noticeDetail) {
        return this.noticeDetail.replace(/[\n]/g, "<br/>");
      }
    },
  },
};
</script>

<style scoped lang="less">
.workorder {
  width: 100%;
  position: relative;
  .wo_header {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    padding: 10px 20px 0 20px;
    .wo_heade_tooltip {
      width: 100%;
      padding: 10px 0;
      font-size: 14px;
      line-height: 26px;
    }
    .wo_heade_card {
      width: 100%;
      display: flex;
      background: #f4f5f8;
      border-radius: 4px;
      padding: 20px 15px;
      .wo_card_item {
        width: 100%;
        span {
          color: #999a9e;
        }
        p {
          color: #343a40;
          word-break: break-word;
        }
      }
    }
  }
  .wo_main {
    width: 100%;
    border-radius: 4px;
    background: #fff;
    padding: 20px;
    .wo_main_table {
      width: 100%;
      /deep/ .el-table {
        .cell {
          div {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .rateColor {
        color: #409eff;
      }
      .isRateTrue {
        color: #343a40;
      }
      .isCaseOpen {
        color: rgb(241, 180, 76) !important;
      }
    }
    .wo_main_head {
      width: 100%;
      .workOrderForm {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /deep/ .el-form-item {
          width: 17.5%;
          margin-right: 1%;
          .el-select {
            width: 100%;
          }
          .el-button {
            background: #556ee6;
          }
        }
      }
    }
    .wo_main_pagination {
      width: 100%;
      margin-top: 15px;
      padding: 0 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .detailPage {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .createPage {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@import "../../../styles/tooltip.css";
</style>