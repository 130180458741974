<template>
  <div>
    <flexbox class="th-container" justify="space-between">
      <flexbox-item>
        <flexbox>
          <el-popover
            v-model="showScene"
            trigger="click"
            popper-class="no-padding-popover"
            width="150"
          >
            <flexbox slot="reference">
              <div class="condition_title" style="white-space: nowrap">
                {{ sceneData.name || getDefaultSceneName() }}
              </div>
              <i
                class="el-icon-arrow-down el-icon--right"
                style="color: #777"
              />
            </flexbox>
            <scene-list
              ref="sceneList"
              :crmType="crmType"
              @scene="sceneSelect"
              @scene-handle="sceneHandle"
              @hidden-scene="handleScene"
            />
          </el-popover>
          <img
            class="c-filtrate"
            src="@/assets/img/c_filtrate.png"
            @click="showFilterClick"
          />
          <div
            class="condition_title"
            @click="showFilterClick"
            style="white-space: nowrap"
          >
            {{ "高级筛选" }}
          </div>
        </flexbox>
      </flexbox-item>
      <flexbox-item style="margin: 0 20px; width: 400px">
        <slot name="center">
          <el-input
            v-model="searchName"
            size="small"
            :placeholder="searchPlaceholder"
            clearable
            @input="inputChange"
            @keyup.enter.native="searchInput"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native="searchInput"
            />
          </el-input>
        </slot>
      </flexbox-item>
      <flexbox-item>
        <flexbox justify="flex-end">
          <slot />
        </flexbox>
      </flexbox-item>
    </flexbox>
    <filter-form
      :dialogVisible.sync="showFilter"
      ref="filterForm"
      :crm-type="crmType"
      :field-list="fieldList"
      :obj="filterObj"
      @filter="handleFilter"
    />
    <filter-content
      v-if="filterObj.form && filterObj.form.length > 0"
      :obj="filterObj"
      @delete="handleDeleteField"
    />
    <scene-set
      :dialog-visible.sync="showSceneSet"
      :field-list="fieldList"
      :crmType="crmType"
      @save-success="updateSceneList"
    />
    <scene-create
      :field-list="fieldList"
      :crmType="crmType"
      :dialog-visible.sync="showSceneCreate"
      :obj="sceneFilterObj"
      @saveSuccess="updateSceneList"
    />
  </div>
</template>

<script>
import SceneList from "./sceneForm/SceneList.vue";
import FilterForm from "./FilterForm/index.vue";
import FilterContent from "./FilterForm/filterContent.vue";
import SceneCreate from "./sceneForm/SceneCreate";
import SceneSet from "./sceneForm/SceneSet";
import { saveScene } from "@/api/common";
export default {
  name: "CRMTableHead",
  components: {
    SceneList,
    FilterForm,
    FilterContent,
    SceneCreate,
    SceneSet,
  },
  props: {
    /** 没有值就是全部类型 有值就是当个类型 */
    crmType: {
      type: String,
      default: "",
    },
    crmName: {
      type: String,
      default: "",
    },
    fieldList: {
      type: Array,
      default: [],
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showScene: false,
      showFilter: false,
      sceneData: { id: "", bydata: "", name: "" },
      filterObj: { form: [] }, // 筛选确定数据
      showSceneSet: false, // 展示场景设置
      showSceneCreate: false, // 展示场景添加
      sceneFilterObj: { form: [] }, // 筛选确定数据
      searchName: "",
    };
  },
  mounted() {},
  methods: {
    showFilterClick() {
      this.showFilter = true;
    },
    // 获取默认场景名字
    getDefaultSceneName() {
      if (this.crmType == "projectApplication") {
        return "全部设备";
      }
    },
    inputChange() {
      this.$emit("update:search", this.searchName);
    },
    // 进行搜索操作
    searchInput() {
      this.$emit("on-search", this.searchName);
    },
    handleFilter(form) {
      this.filterObj = form;
      this.showFilter = false;
      if (form.saveChecked) {
        var objectCode = "";
        objectCode = this.crmType;
        saveScene({
          objectCode,
          objectName: this.crmName,
          isDefault: form.saveDefault ? 1 : 0,
          name: form.saveName,
          data: JSON.stringify(form.obj),
        })
          .then((res) => {
            this.updateSceneList();
          })
          .catch(() => {});
      }
      this.$emit("filter", form.obj);
    },
    // 删除某项
    handleDeleteField(data) {
      this.filterObj = data.obj;
      this.$emit("filter", this.filterObj.obj);
    },
    /**  创建保存成功 */
    updateSceneList() {
      this.$refs.sceneList.getSceneList();
    },
    // 场景操作
    /** 选择了场景 */
    sceneSelect(data) {
      this.sceneData = data;
      var sceneSelectTime = setInterval(() => {
        if (this.fieldList.length) {
          this.filterObj.obj = JSON.parse(data.bydata);
          let byData = JSON.parse(data.bydata);
          for (const key in byData) {
            byData[key].fieldName = key;
            this.getFieldObj(byData[key]);
          }
          clearInterval(sceneSelectTime);
        }
      }, 500);
      this.$emit("scene", data);
    },
    handleScene() {
      this.filterObj.form = [];
      this.showScene = false;
    },
    getFieldObj(formItem) {
      if (formItem.form && Object.keys(formItem.form).length) {
        this.filterObj.form.push(formItem.form);
      } else {
        const obj = this.fieldList.find((item) => {
          return item.fieldCode === formItem.fieldName;
        });
        if (obj) {
          var formData = {};
          formData.formTypeWeb = obj.formTypeWeb;
          if (formData.formTypeWeb == "select") {
            formData.setting = obj.options || [];
          }
          Object.assign(formData, formItem);
          formData.name = obj.name;
          if (formData.formTypeWeb == "select") {
            formData.value = formItem.value.split();
          } else if (
            formData.formTypeWeb == "date" ||
            formData.formTypeWeb === "datetime"
          ) {
            try {
              var value = JSON.parse(formItem.value);
              if (typeof value === "object" && value) {
                formData.value = value;
              } else {
                formData.value = [
                  formartDate(formItem.startDate),
                  formartDate(formItem.endDate),
                ];
              }
            } catch (e) {
              formData.value = [
                formartDate(formItem.startDate),
                formartDate(formItem.endDate),
              ];
            }
          } else if (formData.formTypeWeb == "IndustryCategory") {
            formData.value = formData.valueSave;
            formData.valueContent =
              formData.valueSave.length &&
              formData.valueSave.map((item) => {
                return findFullName(
                  handleId(item, 1),
                  this.$store.state.dict.dict.customer_business,
                  "value",
                  "list",
                  true
                );
              });
          }
          this.filterObj.form.push(formData);
        }
      }
    },
    // 场景选择里添加/编辑场景操作
    sceneHandle(data) {
      if (data.type == "set") {
        this.showSceneSet = true;
      } else if (data.type == "add") {
        this.showSceneCreate = true;
      }
    },
    /**  创建保存成功 */
    updateSceneList() {
      this.$refs.sceneList.getSceneList();
    },
  },
};
</script>

<style lang="less" scoped>
.th-container {
  font-size: 13px;
  height: 50px;
  padding: 0;
}
/** 场景和筛选 */
.c-filtrate,
.condition_title {
  cursor: pointer;
}
.condition_title:hover {
  color: #3e84e9;
}
.c-filtrate {
  margin: 0 5px 0 20px;
  width: 12px;
}
</style>