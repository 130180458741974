import request from '@/utils/request'

//推荐产品
export function getProductList (data) {
    return request({
        url:'/sf-partnerportal/productSelection/recommend',
        method:'post',
        data
    })
}

//获取竞品公司产品
export function getCompetitorProduct (data) {
    return request({
        url:'/sf-partnerportal/productSelection/getCompetitorProduct',
        method:'post',
        data
    })
}

//加入购物车
export function addShoppingCart (data) {
    return request({
        url:'/sf-partnerportal/productSelection/car/add',
        method:'post',
        data
    })
}

//获取购物车详情
export function getCarDetail () {
    return request({
        url:'/sf-partnerportal/productSelection/car/detail',
        method:'post'
    })
}

//清空购物车
export function removeCarDetail () {
    return request({
        url:'/sf-partnerportal/productSelection/car/remove',
        method:'post'
    })
}

//删除购物车中的产品
export function deleteProduct (data) {
    return request({
        url:'/sf-partnerportal/productSelection/car/delete',
        method:'post',
        data
    })
}

//产品详情
export function getDetail (data) {
    return request({
        url:'/sf-partnerportal/productSelection/detail',
        method:'post',
        data
    })
}

//保存选型结果
export function saveResults (data) {
    return request({
        url:'/sf-partnerportal/productSelection/save',
        method:'post',
        data
    })
}

// 下载zip
export function filedfsDownload(data) {
    return request({
      url: 'api-file/pp/files/download',
      method: 'post',
      responseType: 'arraybuffer',
      data
    })
  }