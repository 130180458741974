<template>
  <div class="scenario">
      <el-form ref="form" :model="form" label-position="left" :rules="rules" @submit.native.prevent>
        <!-- <el-form-item :label="$t('productSelection.projectName')+':'" prop="projectName">
            <el-input style="width: 35%;" size="mini" v-model="form.projectName"></el-input>
            <span class="notice">{{$t('productSelection.notice')}}</span>
        </el-form-item> -->
        <div class="form-header">
        <!-- <h2 class="form-h2">Please Select the Scenario</h2> -->
        <div :title="$t('productSelection.SelectResult')">
            <CreateSections  :showMust='true' :title="$t('productSelection.SelectTheScenario')" :noShowLine='true'/>
        </div>
        
        <el-form-item prop="coreSceneId">
            <el-radio-group  v-model="form.coreSceneId" size="small" @change="radioChange">
                <!-- <el-tooltip v-for="(item,key) in scenarioList" class="item" effect="light"  :content="item.description" placement="bottom-start" :key='key'> -->
               <el-popover
                placement="bottom-start"
                width="400"
                v-for="(item,key) in scenarioList"
                :key="key"
                trigger="hover">
                <!-- {{item.description}} -->
                <span v-html="item.description" class="deshtml"></span>
                <el-radio slot="reference" :disabled='!(entryName.length>0)' :key="key" :label="item.value" border>{{item.lable}}</el-radio>
               </el-popover>
                <!-- </el-tooltip> -->
            </el-radio-group>
        </el-form-item>
        </div>
       <div class="form-header">
        <!-- <h2 class="form-h2">If the Belows are in Your Consideration?</h2> -->
        <CreateSections :title="$t('productSelection.Consideration')" :noShowLine='true'/>
        <el-form-item prop="optionalSceneList">
              <el-checkbox-group v-model="form.optionalSceneList" @change="checkboxChange">
                    <el-checkbox :disabled='!(entryName.length>0)' v-for="(item,key) in considerationList" :key="key" :label="item.value">{{item.lable}}</el-checkbox>
                </el-checkbox-group>
        </el-form-item>
        </div>
        <div class="form-header">
        <!-- <h2 class="form-h2">Please Input the Throughput  Required</h2>-->
        <CreateSections :showMust='true' :title="$t('productSelection.ThroughputRequired')" :noShowLine='true'/> 
        <div class="throughputRequired">
            <el-form-item :label="$t('productSelection.Throughput')+':'" prop="throughPut">
            <el-input  :disabled='!(entryName.length>0)' style="width: 50%;" size="mini"  v-model="form.throughPut"></el-input>
        </el-form-item>
        <el-form-item v-if="form.optionalSceneList.indexOf(8)!==-1" :label="$t('productSelection.IPsecThroughput')+':'" prop="ipSecThroughPut">
            <el-input :disabled='!(entryName.length>0)'  style="width: 50%;" size="mini" v-model="form.ipSecThroughPut"></el-input>
        </el-form-item>
        </div>
        </div>
        <div class="footer">
            <el-form-item>  
            <!-- <el-button v-show="false" class="reset">Reset</el-button> -->
            <el-button type="primary" :disabled='!(entryName.length>0)' @click="submitForm" class="submit" >{{$t('productSelection.submit')}}</el-button>
        </el-form-item>
        </div>
        
      </el-form>
      <div class="form-header">
        <!-- <h2 class="form-h2">Here are the Recommended Models</h2>        -->
        <CreateSections :title="$t('productSelection.Models')" :noShowLine='true'/> 
        <div class="ProductCard" v-if='ProductCardList.length>0'>
            <div v-for="(item,key) in ProductCardList" :key="key" style="margin-right: 70px;">
                <!--  -->
            <el-tooltip :key="key"  class="item" effect="light" :disabled='!item.productDesc'  :content="item.productDesc"  placement="right">
                <ProductCard :entryName='entryName' :indexForm='indexForm' @addShoppingCart='addShoppingCart'  :item="item" :length='ProductCardList.length'  :index='key' @showDetails='showDetails' @getDetails='getDetails' @SaveSelectionResults='SaveSelectionResults'/>
            </el-tooltip>
            </div>
        </div>
         <div class='nodata' v-else-if='!showNoneTips' v-loading='loading'>
              {{$t('productSelection.nodata')}}
          </div>
            <div class='nodata' v-else v-loading='loading'>
                <span v-html="$t('productSelection.showNoneTips')">
                    <!-- {{$t('productSelection.showNoneTips')}} -->
                </span>
              
          </div>
      </div>
      <Details class="details" :loadingDetail='loadingDetail' v-if="showDetail||Detail" :indexForm='indexForm' @addShoppingCart='addShoppingCart' :productDetails='productDetails' @closeDetails='closeDetails' @SaveSelectionResults='SaveSelectionResults'/>
  </div>
</template>

<script>
import ProductCard from './productCard.vue'
import CreateSections from "@c/CreateSections"
import Details from './details'
import { getProductList, getDetail, saveResults,addShoppingCart } from '@/api/productSelection'
export default {
    name:'Scenario',
    components: {
        ProductCard,CreateSections,Details
    },
    props: {
        Detail:{
            type:Boolean,
            default:false
        },
        entryName:{
            type:String,
            default:''
        },
        indexForm:{
            type:Object,
            default:{},
            require:true
        },
        showComponent:{
            type:Boolean,
            default:true,
            required:true
        },
        details:{
            type:Object,
            default:function (){
                return {}
            }
        },
        clearPage:{
            type:Boolean,
            default:false,
            required:true
        },
        clearForm:{
            type:Boolean,
            default:false,
            required:true
        },
    },
    data () {
        let numberRuelrs = (rule,value,callback)=>{
            //大于0的校验
            if(Number(value)<=0){
                callback(new Error(this.$t('productSelection.notAllowedMsg')))
            }else{
                callback()
            }
        }
        return {
            showDetail:false,
            loading:false,
            productDetails:{},
            showNoneTips:false,
            form:{
                optionalSceneList:[]
            },
            rules:{
               throughPut:[
                    { required: true, message: this.$t('productSelection.notAllowed'), trigger: ['change','blur'] },
                    //正则校验 整数以及小数位长度
                    { pattern:/^\d{1,12}?$/, message: this.$t('productSelection.notAllowedMsg'), trigger: ['change','blur'] },
                    { validator:numberRuelrs,message: this.$t('productSelection.notAllowedMsg'), trigger: ['change','blur']}
              ],
               ipSecThroughPut:[
                   { required: true, message:  this.$t('productSelection.notAllowed'), trigger: ['change','blur'] },
                   { pattern:/^\d{1,12}?$/, message: this.$t('productSelection.notAllowedMsg'), trigger: ['change','blur'] },
                   { validator:numberRuelrs,message: this.$t('productSelection.notAllowedMsg'), trigger: ['change','blur']}
               ],
            //    optionalSceneList:[
            //        { required: true, message:  this.$t('productSelection.notAllowed'), trigger: 'change' }
            //    ],
               coreSceneId:[
                   { required: true, message:  this.$t('productSelection.notAllowed'), trigger: 'change' }
               ],
            },
            scenarioList:[
                {
                lable:'Internet Gateway(Basic)',
                description:`<p>Deployed your Internet edge, the Firewall provides application-level visibility, control, and logging. And enhance your network security with the IPS module.</p>`,
                value:6
                },{
                lable:'Internet Gateway(Advanced)',
                description:`<p>Deployed your Internet edge, the Firewall provides application-level visibility, control, logging, and intrusion prevention(IPS).  Enhanced with industrial-leading artificial intelligence and threat intelligence to give you the best capabilities against known and unknown threats.</p>`,
                value:1
                },{
                lable:'Ransomware Protection',
                description:`<p>If the Ransomware attack is your top concern, this will be your choice. Fully enabled NGFW feature with industrial-leading artificial intelligence and threat intelligence to give you the best security.</p>`,
                value:2
                },{
                lable:'SD-WAN',
                description:`<p>SD-WAN with Sangfor NGAF is your best choice when you have multiple branches across regions. Sangfor NGAF connects all sites with SD-WAN technology. Stable, secured, and easy to manage, everything you need to with SD-WAN is ready here.</p>`,
                value:3
                },{
                lable:'Data Center Protection',
                description:`<p>When you have a data center, especially Web-based applications need to protect. Sangfor NGAF provides an industrial-leading firewall and WAF in a single appliance.</p>`,
                value:4
                },{
                lable:'Full Protection + Platform-X',
                description:`<p>Do you manage multiple NGAF in your organization, or are you an MSP? Sangfor NGAF protects your network while Sangfor platform-X helps manage all devices, logs, reporting via cloud centrally.</p>
                <p>Also, 30 Sangfor Endpoint Secure clients are offered free by default when you choose this scenario.</p>`,
                value:5
                }
            ],
            considerationList:[
                 {
                lable:'WAF',
                value:7
                },{
                lable:'IPsec VPN',
                value:8
                },{
                lable:'HTTPS Decryption',
                value:9
                }
            ],
            ProductCardList:[],
            loadingDetail:false
        }
    },
    created () {
    },
    watch:{
        showComponent:{
            handler(val){
             if(val==false){
            this.$set(this.form,'optionalSceneList',[])
            this.$nextTick(()=>[
               this.$refs.form.clearValidate(),//清除校验
               this.ProductCardList = [],
               this.showNoneTips = false
            ])
             }
         },
            deep:true
        },
        entryName(val){
            if(val.length==0){
             this.ProductCardList = []
             this.showNoneTips = false  
            } 
        },
        clearForm(){
            this.$set(this.form,'optionalSceneList',[])
            this.$set(this.form,'coreSceneId',[])
            this.$set(this.form,'throughPut','')
            this.$set(this.form,'ipSecThroughPut','')
            this.$nextTick(()=>[
               this.$refs.form.clearValidate(),//清除校验
            ])
        },
        Detail(val){
          if(val){
              this.showDetails(this.details)
          }
      },
      'form.throughPut'(val){
              this.ProductCardList = []
              this.showNoneTips = false    
      },
      'form.ipSecThroughPut'(val){
              this.ProductCardList = []
              this.showNoneTips = false
              
      },
      clearPage(){
          this.ProductCardList=[]
      }
    },
    methods: {
        //radio check 修改清除其他选项
        radioChange(val){
            this.$set(this.form,'throughPut','')
            this.$set(this.form,'ipSecThroughPut','')
            this.ProductCardList = []
            this.$nextTick(()=>[
               this.$refs.form.clearValidate(),//清除校验
            ])
            if(val==3){
                this.form.optionalSceneList = [8]
            } else if(val==4){
                this.form.optionalSceneList = [7]
            }else{
                this.form.optionalSceneList = []
            }
        },
        checkboxChange(){
            this.$set(this.form,'throughPut','')
            this.$set(this.form,'ipSecThroughPut','')
            this.ProductCardList = []
            this.$nextTick(()=>[
               this.$refs.form.clearValidate(),//清除校验
            ])
        },
        //重置
        resetForm(){

        },
        getDetails(){
            this.$emit('getDetails')
        },
        //提交
        submitForm(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    let param = {
                        'entryName': this.indexForm.entryName,
                        'recommendType': this.indexForm.recommendType,
                        'coreSceneId': this.form.coreSceneId,
                        'ipSecThroughPut': this.form.ipSecThroughPut,
                        'optionalSceneList': this.form.optionalSceneList,
                        'throughPut' : this.form.throughPut 
                    }
                   getProductList(param).then(res=>{
                       if(res.code==0){
                           this.ProductCardList = res.rows
                           this.showNoneTips = true
                           this.loading= false
                       }
                   })
                }
            })
        },
        showDetails(val){
            this.loadingDetail= true
            getDetail(val.productId).then(res=>{
                if(res.code == 0){                
                this.productDetails = res.rows
                this.productDetails.sceneList = val.sceneList
                this.loadingDetail = false
                this.showDetail=true
                }
            })
            this.$emit('pageScroll',this.showDetail)
        },
        closeDetails(){
            this.showDetail=false
            this.Detail = false
            this.$emit('pageScroll',this.showDetail)
        },
        //加入购物车
        addShoppingCart(params){
            this.$emit('addShoppingCart', params)
        },
        //保存选型结果
        SaveSelectionResults(params){
            this.$emit('SaveSelectionResults',params)
        }
    }
}
</script>
<style lang="less" scoped>
// @import '../'
    .scenario{
        height: 100%;
        // position: relative;
       /deep/ .el-form{
           border-bottom: 1px solid #CED4DA;
           margin-bottom: 40px;
           margin-top: 36px;
           .el-form-item{
                display: flex;
                flex-direction: column;
                padding-left: 18px;
                .el-form-item__content{
                    margin-left: 0 !important;
                    // width: 35%;
                    .notice{
                        font-size: 12px;
                        font-family: FZLanTingHeiS;
                        font-weight: 400;
                        line-height: 26px;
                        color: #F46A6A;
                        opacity: 1;
                        margin-left: 7px;
                     }
                    .el-radio-group{
                        // display: flex;
                        width: 100%;
                        // justify-content: space-between;
                        span{
                            // flex: 1;
                            width: 225px;
                            display: inline-block;
                            // width: 100%;
                            margin-right: 25px;
                            .el-popover__reference-wrapper{
                                width: 100%;
                                margin-bottom: 15px;
                            }
                        .el-radio{
                            width: 100%;
                            flex: 1;
                            padding: 20px 20px 20px 20px;
                            height: auto;
                            background-color: #f4f5f8;
                            border-color: #ced4da;
                            text-align: center;
                            .el-radio__input{
                                display: none;
                                .el-radio__inner{
                                    display: none;
                                }
                            }
                            .el-radio__label{
                                padding-left: 0;
                                font-size:14px;
                                width: 100%;
                                text-align: center;
                                word-break: break-word;
                                white-space: pre-wrap;
                                word-wrap: normal
                            }
                        }
                        .is-checked{
                            color: #556ee6;
                            background-color: rgb(226, 230, 251);
                            border-color: #556ee6;
                        }
                    }
                    .el-checkbox-group{
                        .el-checkbox__label{
                                color: #74788d;
                            }
                        .is-checked{
                            .el-checkbox__inner{
                                background-color: #556ee6;
                                border-color: #556ee6;
                            }
                            .el-checkbox__label{
                                color: #74788d;
                            }
                        }
                    }
                    }
                }
                .reset{
                    border: 1px solid #CED4DA;
                    opacity: 1;
                    border-radius: 2px;
                    color: #A1A4B4;
                }
                .submit{
                    border: 1px solid #556EE6;
                    opacity: 1;
                    border-radius: 2px;
                    background-color: #556EE6;
                    margin-left: 90px;
                }          
            }
            
        }
        .form-header{
                .el-form{
                    .el-form-item{
                    width: 100%;
                    .el-form-item__content{
                        width: 100% !important;
                    }   
                 }
                }
                
                 .form-h2{
                    font-size: 16px;
                    font-family: FZLanTingHeiS-DB-GB;
                    font-weight: 400;
                    line-height: 19px;
                    color: #343A40;
                    opacity: 1;
                    margin-bottom: 15px;
                }
                .form-h2::before{
                    content: '';
                    display: inline-block;
                    width: 7px;
                    height: 7px;
                    background: #FFFFFF;
                    border: 2px solid #556EE6;
                    border-radius: 50%;
                    opacity: 1;
                    margin-right: 8px;
                }
                .throughputRequired{
                    display: flex;
                     .el-form-item{
                        //  flex: 1;
                        width: 50%;
                     }
                }
                .ProductCard{
                    display: flex;
                    // width: 16.75rem;
                }
            }
            .footer{
                text-align: center;
            }
           
    }
   .details{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
.nodata{
    width: 100%;
    display: flex;
    // text-align: center;
    justify-content: center;
    height: 335px;
    align-items: center;
    span{
        text-align: center;

    }
}
</style>
<style lang="less">
.el-popover{
    word-break: break-word;
}
</style>