<template>
  <div>
    <div class="crm-container">
      <flexbox class="th-container" style="padding: 0">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="approvalForm.time"
          unlink-panels
          type="daterange"
          size="small"
          style="width: 300px"
          :start-placeholder="$t('approval.a9')"
          :end-placeholder="$t('approval.a10')"
          @change="handleChange"
        ></el-date-picker>
        <el-input
          size="small"
          v-model="approvalForm.flowNo"
          style="width: 200px; margin-left: 10px"
          clearable
          :placeholder="$t('approval.a8')"
          class="filter-item"
          @change="handleChange"
        />
        <el-input
          size="small"
          v-model="approvalForm.applyUsername"
          style="width: 200px; margin-left: 10px"
          clearable
          :placeholder="$t('approval.a4')"
          class="filter-item"
          @change="handleChange"
        />
      </flexbox>
      <el-table
        v-loading="loading"
        id="crm-table"
        ref="crmTable"
        :data="list"
        :height="tableHeight"
        :cell-style="cellStyle"
        class="n-table--border"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        @row-click="handleRowClick"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
          fixed
          style="text-align: center"
        ></el-table-column>
        <el-table-column
          prop="flowNo"
          :label="$t('approval.a1')"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          prop="flowName"
          :label="$t('approval.a19')"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$t('approval.a2')"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          prop="applyName"
          :label="$t('approval.a4')"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          prop="createdDate"
          :label="$t('approval.a5')"
          sortable="custom"
        >
          <template slot-scope="{ row }">
            {{ momentFormatter(row.createdDate) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('approval.a3')"
          sortable="custom"
        >
          <template slot-scope="{ row }">
            <div>
              {{ findDIctName('flow_status', row.status) }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="p-contianer">
        <el-pagination
          :current-page="pageNumber"
          :page-sizes="pageSizes"
          :page-size.sync="pageSize"
          :total="total"
          class="p-bar"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- 相关详情页面 -->
    <c-r-m-all-detail
      :visible.sync="showAllDview"
      :crm-type="rowType"
      :row="row"
      :id="rowID"
      @handle="handleHandle"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import table from '@v/mixins/table'
import CRMTableHeader from '@c/CRMTableHeader.vue'
import FieldsPover from '@c/fieldsManager/FieldsPover.vue'
import { deleteMyDraft, queryDict } from '@/api/common'
import { getListMyApproval } from '@/api/approval'
export default {
  name: 'approvalIndex',
  components: {
    CRMTableHeader,
    FieldsPover
  },
  data() {
    return {
      crmType: 'my-approval-detail',
      crmName: 'forMyApproval',
      approvalForm: {
        time: [
          moment()
            .subtract(3, 'Q')
            .format(),
          moment().format()
        ],
        flowNo: '',
        applyUsername: ''
      },
      form: {},
      flow_status: [
        { name: this.$t('filter.f79'), value: '2' },
        { name: this.$t('filter.f80'), value: '3' },
        { name: this.$t('filter.f81'), value: '5' }
      ],
      selectionList: [],
      pageNumber: 1,
      pageSize: 10,
      pageSizes: [10, 15, 30, 60, 100],
      total: 0,
      row: {},
      loading: false, // 加载动画
      tableHeight: document.documentElement.clientHeight - 240, // 表的高度
      list: [],
      showAllDview: false, // 打开CRMAllDetail页面状态
      rowID: '',
      rowType: '',
      filterObj: {}, // 高级筛选保存的内容
      sortData: {}
    }
  },
  computed: {
    ...mapGetters(['dict'])
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.approvalForm))
    this.getList()
    // if (!this.$store.state.dict.dict['flow_status']) {
    //   this.getDict(this.crmType)
    // }
  },
  methods: {
    /** 通过回调控制style */
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'flowNo') {
        return { color: '#3E84E9', cursor: 'pointer' }
      } else {
        return ''
      }
    },
    handleChange() {
      this.form = _.cloneDeep(this.approvalForm)
      if (this.approvalForm.time) {
        this.form.applyDateStart = moment(this.approvalForm.time[0]).format()
        this.form.applyDateEnd = moment(this.approvalForm.time[1]).format()
      }
      this.getList('refresh')
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange(val) {
      this.selectionList = val // 勾选的行
    },
    momentFormatter(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    /** 获取列表数据 */
    getList(refresh) {
      console.log(this.pageSize)
      this.loading = true

      if (refresh) {
        this.pageNumber = 1
      }
      let params = {}
      params = Object.assign(
        {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
        this.form,
        this.sortData
      )
      if (params._search && Object.keys(params._search).length) {
        for (const key in params._search.data) {
          if (Object.hasOwnProperty.call(params._search.data, key)) {
            const element = params._search.data[key]
            if (element.formType == 'datetime' || element.formType == 'date') {
              element.value = JSON.stringify(element.value)
            }
          }
        }
      }
      getListMyApproval(params)
        .then((res) => {
          this.list = res.rows.rows
          this.pageSize = res.rows.pageSize
          this.total = res.rows.total
          this.loading = false
          this.$nextTick(() => {
            this.updateTableHeight()
            this.$refs.crmTable && this.$refs.crmTable.doLayout()
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 更改当前页数
    handleCurrentChange(val) {
      this.pageNumber = val
      this.getList()
    },
    // 更改每页展示数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    sortChange(column) {
      console.log(column)
      this.pageNumber = 1
      this.sortData.sortName = column.prop
      if (column.order) {
        this.sortData.sortOrder = column.order == 'ascending' ? 'asc' : 'desc'
      } else {
        this.sortData.sortOrder = ''
        this.sortData.sortName = ''
      }
      this.getList()
    },
    // 当某一行被点击时会触发该事件
    handleRowClick(row, column, event) {
      console.log(row)
      this.rowID = row.businessId
      this.taskId = row.taskId
      this.status = row.status
      this.row = row
      this.showAllDview = true
      this.rowType = 'my-approval-detail'
    },
    /** 详情页操作callback */
    handleHandle(data) {
      this.getList()
    },
    updateTableHeight() {
      var offsetHei = document.documentElement.clientHeight
      var removeHeight = 0
      removeHeight = Object.keys(this.filterObj).length > 0 ? 270 : 200
      this.tableHeight = offsetHei - removeHeight
    },
    queryFormDict(...queryList) {
      queryList.map((ele) => {
        if (!this.$store.getters.dict[ele]) {
          queryDict(ele).then((res) => {
            this.$store.commit('UPDATE_DICT', {
              key: ele,
              value: res.rows
            })
          })
        }
      })
    },
    getDict() {
      this.queryFormDict('flow_status')
    },
    findDIctName(key, val) {
      if (
        this.$store.state.dict.dict[key] &&
        this.$store.state.dict.dict[key].length > 0
      ) {
        return this.$store.state.dict.dict[key].reduce((str, ele) => {
          if (ele.value == val && key !== 'PpProjectStage') {
            str = ele.name
          }
          return str
        }, '--')
      } else {
        return '-'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.th-container {
  font-size: 13px;
  height: 50px;
  padding: 0 20px;
}
</style>
