import request from '@/utils/request'

//获取工单列表
export function getWorkOrderlist(data){
    return request({
        url:'/sf-partnerportal/workOrder/list',
        method:'post',
        data:data,
    })
}

//获取工单详情
export function getWorkOrderInfo(data){
    return request({
        url:'/sf-partnerportal/workOrder/info',
        method:'post',
        data:data,
    })
}

//新建工单
export function workOrderSave(data){
    return request({
        url:'/sf-partnerportal/workOrder/save',
        method:'post',
        data:data,
    })
}

//升级工单
export function upgradeWorkOrder(data){
    return request({
        url:'/sf-partnerportal/workOrder/upgradeWorkOrder',
        method:'post',
        data:data,
    })
}

//获取时区
export function getTimeZoneList(data){
    return request({
        url:'/sf-partnerportal/timeZone/list',
        method:'post',
        data:data,
    })
}

//评论
export function commentSave(data){
    return request({
        url:'/sf-partnerportal/comment/save',
        method:'post',
        data:data,
    })
}

//获取评论详情
export function commentDetail(data){
    return request({
        url:'/sf-partnerportal/comment/getByCaseNo',
        method:'post',
        data:data,
    })
}

//获取日报详情
export function getDailyByCaseNo(data){
    return request({
        url:'/sf-partnerportal/workOrder/getDailyByCaseNo',
        method:'post',
        data:data,
    })
}

//获取邮箱详情
export function getEmailByCaseNo(data){
    return request({
        url:'/sf-partnerportal/workOrder/getEmailByCaseNo',
        method:'post',
        data:data,
    })
}

//获取产品线
export function getAllProduct(data){
    return request({
        url:'/sf-partnerportal/workOrder/getAllProduct',
        method:'post',
        data:data,
    })
}

//获取版本号
export function getAllVersion(data){
    return request({
        url:'/sf-partnerportal/workOrder/getAllVersion',
        method:'post',
        data:data,
    })
}

//获取客户名称
export function getCustomers(data){
    return request({
        url:'/sf-partnerportal/customers/getCustomers',
        method:'post',
        data:data,
    })
}

//获取服务商通知
export function getNotice(data){
    return request({
        url:'/sf-partnerportal/workOrder/getNotice',
        method:'post',
        data:data,
    })
}

//搜索kb文章
export function getKbArticles(data){
    return request({
        url:'/sf-partnerportal/workOrder/getKbArticles',
        method:'post',
        data:data,
    })
}

// 渠道用户查询
export function getAdminList(data){
    return request({
        url:'sf-partnerportal/channelUser/admin/list',
        method:'post',
        data:data,
    })
}

//修改密码
export function changeParssword(data){
    return request({
        url:'/sf-partnerportal/channelUser/admin/resetPwd',
        method:'post',
        data:data,
    })
}

//修改用户信息
export function resetUserInfo(data){
    return request({
        url:'/sf-partnerportal/channelUser/admin/update',
        method:"post",
        data:data,
    })
}
//修改角色
export function resetRole(data){
    return request({
        url:'/sf-partnerportal/channelUser/admin/updateRole',
        method:"post",
        data:data,
    })
}

//下载文件
export function downloadFile(data){
    return request({
      url:'api-file/pp/files/download',
      headers:{
        'Content-Type': 'application/json; charset=UTF-8',
      },
      responseType: 'blob',
      method:'post',
      data:data
    })
}

//获取国家区号
export function getAreaCode(data){
    return request({
      url:'/sf-partnerportal/areaCode/list',
      method:'post',
      data:data
    })
}