<template>
  <div class="head-content">
    <el-date-picker v-if="showOptions"
                    v-model="params.addedTime"
                    type="daterange"
                    class="added-time head-options"
                    range-separator="-"
                    start-placeholder="Added Start Time"
                    end-placeholder="Added End Time"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="handleChange">
    </el-date-picker>
    <el-date-picker v-if="showOptions"
                    v-model="params.expiredTime"
                    type="daterange"
                    class="expired-time head-options"
                    range-separator="-"
                    start-placeholder="Expired Start Time"
                    end-placeholder="Expired End Time"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="handleChange">
    </el-date-picker>
    <el-select clearable
               v-if="showOptions"
               v-model="params.status"
               class="status-options head-options"
               placeholder="select"
               @change="handleChange">
      <el-option v-for="item in statusOptions"
                 :key="item.id"
                 :label="item.name"
                 :value="item.id">
      </el-option>
    </el-select>
    <el-select clearable
               v-if="showOptions"
               v-model="params.productType"
               multiple
               class="product-oprions head-options"
               placeholder="select"
               @change="handleChange">
      <el-option v-for="(item,index) in productOprions"
                 :key="index"
                 :label="item"
                 :value="item">
      </el-option>
    </el-select>
    <el-input v-if="showOptions"
              placeholder="Input Model、SN or GatewayID"
              class="search head-options"
              v-model="params.searchName"
              multiple
              clearable
              @keyup.enter.native="handleChange">
      <el-button slot="append"
                 icon="el-icon-search"
                 @click="handleChange"></el-button>
    </el-input>
  </div>
</template>
<script>
export default {
  name: 'commonHead',
  data () {
    return {
      params: {
        addedTime: null,
        expiredTime: null,
        status: '',
        productType: [],
        searchName: ''
      }

    }
  },

  props: {
    productOprions: {
      type: Array,
      default: () => {
        return []
      }
    },
    statusOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    resetNum: {
      type: Number,
      default: 0
    }
  },
  watch: {
    resetNum: {
      handler (val) {
        if (val) {
          this.params.addedTime = null,
            this.params.expiredTime = null,
            this.params.status = '',
            this.params.productType = [],
            this.params.searchName = ''
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    showOptions () {
      return true
    }
  },

  methods: {
    handleChange () {
      this.$emit('handler-change', { ...this.params, isReset: true })
    },
  }
}
</script>
<style lang="less" scoped>
.head-content {
  display: flex;
  justify-content: flex-start;
  .head-options {
    margin-right: 5px;
  }
  .search {
    width: 340px;
  }
}
</style>