<template>
  <div class="hasDownLoad" >
      <h2 class="title">{{$t('productSelection.ProductList')}}</h2>
      <div class="seach_table">
          <el-date-picker
            v-model="date"
            size="medium"
            type="daterange"
            value-format='yyyy-MM-dd HH:mm:ss'
            range-separator="-"
            :start-placeholder="$t('approval.a9')"
            :end-placeholder="$t('approval.a10')">
            </el-date-picker>
             <el-input
            :placeholder="$t('productSelection.inputText')"
            size="medium"
            v-model.trim="form.entryName"
            maxlength="500"
            @keyup.enter.native="seachEntry">
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
            <el-button slot="append" icon="el-icon-search" @click="seachEntry"></el-button>
        </el-input>
      </div>
      <div v-loading='loading' style="height:500px;width: 100%;display:flex;align-items: center;justify-content: center;" v-if="!productList.length>0">
              {{$t('productSelection.nodata')}}
        </div>
      <div  v-loading='loading'  v-else class="tableClass">
        <el-scrollbar>
        <ProductTable  @getList='getList'  :list='item'   v-for="(item,index) in productList" :key="index" :index="index" :showBtn='showBtn'/>
        </el-scrollbar>
      </div>
     
        <div class="footer">
         <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pageMsg.pageNo"
            :page-size="10"
            layout="total,prev, pager, next, jumper"
            :total="pageMsg.total">
    </el-pagination>
         
      </div>
  </div>
</template>
<script>
import ProductTable from './productTable.vue'
export default {
    name:'hasDownLoad',
    components: {
      ProductTable  
    },
    props: {
        productList:{
            type:Array,
            default:[]
        },
        loading:{
            type:Boolean,
            default:true
        },
        pageMsg:{
            type:Object,
            default:{}
        },
    },
    data () {
        return {
        showBtn:false,
        cartItem:[],
        showChecked:'',
        date:'',
        form:{
            startTime:'',
            endTime:'',
            entryName:''
        },
        
        }
    },
    watch: {
        date(val){
            if(val&&val.length>0){
                this.form.startTime = val[0]
                this.form.endTime = val[1]
            }else{
                this.form.startTime = ''
                this.form.endTime = ''
            }
            this.$emit('dateChange',  this.form)
        }  
    },
    methods: {
        ItemChange(){

        },
        //分页
        handleCurrentChange(val){
            this.$emit('handleCurrentChange', val)
        },
        getList(){
            this.$emit('getDownloadList')
        },
        seachEntry(){
            this.$emit('entryNameChange',  this.form)
        }
    }
}
</script>

<style lang="less" scoped>
.hasDownLoad{
        background-color: #fff;
        h2{
            margin-bottom: 20px;
        }
    }
    .title{
            font-size: 18px;
            font-family: FZLanTingHeiS-DB-GB;
            font-weight: 600;
            line-height: 21px;
            color: #343A40;
            opacity: 1;
    }
    .footer{
            display: flex;
            justify-content: space-between;
            border-top:  1px solid #CED4DA;
            opacity: 1;
            padding-top: 24px;
            justify-content: flex-end;
        }
.tableClass{
    height: 36.875rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 99;
}
.tableClass::-webkit-scrollbar{
    display: block !important;
}
.tableClass::-webkit-scrollbar-track{
    display: block !important;
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
.seach_table{
    margin-bottom: 10px;
    display: flex;
    width: 60%;
    /deep/.el-input{
        flex: 1;
        margin-left: 20px;
    }
    /deep/.el-date-editor{
        flex: 1;
    }
}
</style>