<template>
  <div class="userList">
    <!-- 搜索区域开始 -->
    <div class="searchBox">
      <el-form ref="userFrom" :model="userFrom" class="userFrom">
        <el-form-item>
          <el-date-picker
            v-model="beginAndEndTime"
            value-format="yyyy-MM-ddTHH:mm:ss"
            type="daterange"
            size="small"
            range-separator="-"
            :start-placeholder="$t('caselist.startdate')"
            :end-placeholder="$t('caselist.enddate')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select v-model="userFrom.status" size="small" clearable :placeholder="$t('caselist.status')">
            <el-option label="Activated" value="1"></el-option>
            <el-option label="Frozen" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model="userFrom.searchName"
            :placeholder="$t('caselist.searchUser')"
            size="small"
            prefix-icon="el-icon-search"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btnBox">
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="handleSearch"
          >{{ $t("case.search") }}</el-button
        >
      </div>
    </div>
    <!-- 搜索区域结束 -->

    <!-- 表格区域开始 -->
    <div class="tableBox">
      <el-table
        ref="multipleTable"
        :data="tableData"
        v-loading="loadingTable"
        border
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column :label="$t('caselist.name')" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.row.surname
                ? scope.row.surname + " " + scope.row.name
                : scope.row.name
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('caselist.Email')" align="center" prop="email">
        </el-table-column>
        <el-table-column :label="$t('caselist.Phone')" align="center">
          <template slot-scope="scope">
            <span>{{ (scope.row.areaCode&&countryCode.length>0?countryCode.filter(val=>{if(scope.row.areaCode==val.id){return true} return false})[0].areaCode:'')+" "+(scope.row.mobile ? scope.row.mobile : "--") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('caselist.Role')" align="center" prop="role" width="140">
          <template slot-scope="scope">
            <span>{{ roleData[scope.row.isAdmin] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :label="$t('caselist.registrationDate')"
          align="center"
          prop="registrationDate"
        >
          <template slot-scope="scope">
            <span>{{
              moment(scope.row.createdDate).format("YYYY-MM-DD")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('caselist.Status')" align="center" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "Activated" : "Frozen" }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('caselist.operation')" align="center">
          <template slot-scope="scope">
            <a style="color: #409eff" @click="goDetailPage(scope.row)"
              >{{$t('caselist.detail')}}</a
            >
            <el-divider direction="vertical"></el-divider>
            <a style="color: #409eff" @click="goChangePasswordPage(scope.row)"
              >{{$t('caselist.resetPassword')}}</a
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 表格区域结束 -->

    <!-- 分页区域开始 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginationInfo.pageNumber"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="paginationInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="paginationInfo.total"
      >
      </el-pagination>
    </div>
    <!-- 分页区域结束 -->

    <!-- 组件区域开始 -->
    <user-detail
      v-if="showUserDetail"
      :userDetail="userDetail"
      :userDetailAction="showUserDetail"
      @userDetailProp="userDetailProp"
    ></user-detail>
    <!-- 组件区域结束 -->

    <!-- 弹框区域开始 -->
    <!-- 修改密码 -->
    <el-dialog
      :title="$t('caselist.resetPassword')"
      :close-on-click-modal="false"
      :visible.sync="resetPassWordDialog"
      width="30%"
      center
      :before-close="handleRPClose"
    >
      <el-form
        :model="resetPasswordForm"
        :rules="resetPasswordRules"
        ref="resetPasswordForm"
        class="resetPasswordForm"
      >
        <el-form-item :label="$t('caselist.newPassword')" prop="newPwd">
          <el-input v-model="resetPasswordForm.newPwd" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('caselist.confirmPassword')" prop="confirmPassword">
          <el-input v-model="resetPasswordForm.confirmPassword" size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleRPClose" size="small">{{$t('caselist.Cancel')}}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleRPSave('resetPasswordForm')"
          v-loading="resetPasswordLoading"
          >{{$t('caselist.Submit')}}</el-button
        >
      </span>
    </el-dialog>
    <!-- 弹框区域结束 -->
  </div>
</template>

<script>
import { parse } from "qs";
import { mapGetters } from "vuex";
import { getAdminList, changeParssword,getAreaCode } from "../../api/case";
import userDetail from "./userDetail.vue";
import Lockr from "lockr";
import moment from "moment";
export default {
  components: { userDetail },
  name: "userList",
  data() {
    var checkConfirmPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('caselist.checkPassword')));
      } else {
        if (value !== this.resetPasswordForm.newPwd) {
          return callback(
            new Error(this.$t('caselist.checkPassword3'))
          );
        } else {
          callback();
        }
      }
    };
    return {
      countryCode:[],
      resetPasswordLoading: false,
      resetPassWordDialog: false,
      showUserDetail: false,
      roleDialog: false,
      loadingTable: false,
      userFrom: {
        status: "",
        applyDateStart: "",
        applyDateEnd: "",
        searchName: "",
      },
      tableData: [],
      roleData: ["Normal User", "Administrator", "超管", "Sales Staff", "Technical Supporter"],
      paginationInfo: {
        pageSize: 10,
        total: 0,
        pageNumber: 1,
      },
      resetPasswordForm: {
        newPwd: "",
        confirmPassword: "",
      },
      resetPasswordRules: {
        newPwd: [
          {
            required: true,
            message: this.$t('caselist.checkPassword2'),
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, validator: checkConfirmPassword, trigger: "blur" },
        ],
      },
      resetPasswordInfo: {},
      roleForm: {
        role: "",
      },
      roleRules: {
        role: [{ required: true, message: "xxx", trigger: "blur" }],
      },
      userDetail: {},
      beginAndEndTime: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
    },
    //获取用户列表
    allAdminList(val) {
      this.loadingTable = true;
      let params = val;
      params.businessId = this.userInfo.channelId;
      params.pageSize = this.paginationInfo.pageSize;
      params.pageNumber = this.paginationInfo.pageNumber;
      params.applyDateStart = this.beginAndEndTime
        ? this.beginAndEndTime[0]
        : "";
      params.applyDateEnd = this.beginAndEndTime ? this.beginAndEndTime[1] : "";
      getAdminList(params)
        .then((res) => {
          this.tableData = res.rows.rows;
          this.paginationInfo.total = res.rows.total;
          this.loadingTable = false;
        })
        .catch(() => {
          (this.tableData = []), (this.loadingTable = false);
        });
    },

    //搜索
    handleSearch() {
      this.allAdminList(this.userFrom);
    },
    //打开详情页
    goDetailPage(val) {
      this.showUserDetail = true;
      this.userDetail = val;
    },
    //分页函数
    handleSizeChange(val) {
      this.paginationInfo.pageSize = val;
      this.allAdminList(this.userFrom);
    },
    handleCurrentChange(val) {
      this.paginationInfo.pageNumber = val;
      this.allAdminList(this.userFrom);
    },
    //关闭详情页操作
    userDetailProp(val) {
      this.showUserDetail = val.status;
      if (val.isSave) {
        this.allAdminList(this.userFrom);
      }
    },

    //关闭修改密码弹窗
    handleRPClose() {
      this.resetPasswordForm.newPwd='',
      this.resetPasswordForm.confirmPassword='',
      this.resetPassWordDialog = false;
    },
    goChangePasswordPage(val) {
      this.resetPassWordDialog = true;
      this.resetPasswordInfo = val;
    },
    handleRPSave(resetPasswordForm) {
      this.$refs[resetPasswordForm].validate((valid) => {
        if (valid) {
          this.resetPasswordLoading = true;
          let data = JSON.parse(JSON.stringify(this.resetPasswordForm));
          const AES_KEY = "1DB8eBb7FfeMfdfA";
          const IV = "TGYTRCN394552143";

          function encrypt(params) {
            var key = CryptoJS.enc.Utf8.parse(AES_KEY);
            var iv = CryptoJS.enc.Utf8.parse(IV);
            var jsonData = JSON.stringify(params);
            var data = CryptoJS.enc.Utf8.parse(jsonData);
            var encrypted = CryptoJS.AES.encrypt(data, key, {
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }).toString();
            return encrypted;
          }
          let field = {};
          field.newPwd = encrypt(data.newPwd);
          field.username = this.resetPasswordInfo.username;
          field.userId = this.resetPasswordInfo.id;
          changeParssword(field)
            .then((res) => {
              this.$message({
                type: "success",
                message: "Submit sucessfully!",
              });
              this.resetPassWordDialog = false;
              this.resetPasswordLoading = false;
              this.resetPasswordForm.newPwd='',
              this.resetPasswordForm.confirmPassword='',
              this.allAdminList(this.userFrom);
            })
            .catch(() => {
              this.resetPasswordLoading = false;
            });
        }
      });
    },
    //获取国际区号
    getCountryCode(){
      getAreaCode({}).then(res=>{
        this.countryCode=res.rows
        Lockr.set('countryCode',res.rows)
      })
    }
  },
  created() {
    this.getCountryCode()
    this.allAdminList(this.userFrom);
  },
  computed: {
    ...mapGetters(["userInfo"]),
    moment() {
      return moment;
    },
  },
};
</script>

<style scoped lang="less">
.userList {
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  .searchBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .userFrom {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      /deep/ .el-form-item {
        width: 18%;
        min-width: 290px;
        margin-right: 10px;
        .el-select {
          width: 100%;
        }
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
      }
    }
    .btnBox{
      padding-top: 4px;
    }
  }
  .tableBox {
    width: 100%;
  }
  .pagination {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>