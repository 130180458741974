<template>
  <div class="item">
      <div class="title">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
        <span style="white-space: pre;">{{list.entryName?list.entryName:indexForm.entryName}}</span>
      </div>
    <div class="itemList" v-if="list.carItem">
       <el-checkbox-group v-model="checkedItem" @change="handleCheckedItemChange">
                <div  v-for="(i,key) in list.carItem"  :key="key" class="group">
                    <el-checkbox :label="i" >
                    {{''}}
                    </el-checkbox>
                    <div :title="$t('productSelection.clickTitle')">
                    <img v-if="i.imgUrl" :src="i.imgUrl" alt="" @click="showDetails(i)">
                    <img v-else src="../../../../assets/default.png" alt=""  @click="showDetails(i)">
                    </div>                    
                    <div class="num">
                        <p >{{i.productName}}</p>
                    </div>
                    <div class="delete">
                       <i class="el-icon-close" @click="deleteProduct(i.carItemId)"></i>
                    </div>
                </div>    
        </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import { deleteProduct,getCarDetail } from '@/api/productSelection'
export default {
    name:'cartItem',
    props: {
        title:{
            type:String,
            require:true,
            default:''
        },
        indexForm:{
            type:Object,
            default:{}
        },
        list:{
            type:Object,
            require:true,
            default:function(){
                return {}
            }
        },
        index:{
            type:Number,
            require:true,
            default:''
        },
        checked:{
            type:Boolean,
            require:true,
            default:false
        }
    },
    data () {
        return {
            isIndeterminate: false,
            checkAll:false,
            checkedItem:[]
        }
    },
    watch: {
        checked(){
            if(this.checked == true){
                this.checkAll = true
                this.checkedItem = this.list.cartItem.map((i)=>{
                    return i
                })
                this.isIndeterminate = false;
            } else {
                this.checkedItem = []
                this.checkAll = false
            }
            let value={
                 index : this.index,
                 val : this.checkedItem
             }
             this.$emit('ItemChange',this.checkedItem)
            
        }
    },
    created () {
        this.$nextTick(()=>{
            this.checkAll = true
            this.checkedItem = this.list.carItem.map((i)=>{
                return i
            })
        this.$emit('ItemChange',this.checkedItem)
    })
    },
    methods: {
        handleCheckAllChange(val){
            if(val == true){
                this.checkedItem = this.list.carItem.map((i)=>{
                    return i
                })
                this.isIndeterminate = false;
                // this.$emit('showChecked',true)
            } else {
                this.checkedItem = []
                // this.$emit('showChecked',false)
            }
            let value={
                 index : this.index,
                 val : this.checkedItem
             }
             this.$emit('ItemChange',this.checkedItem)
            
        },
        handleCheckedItemChange(val){
             let checkedCount = val.length;
             this.checkAll = checkedCount === this.list.carItem.length;
             this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.carItem.length;
             this.$emit('showChecked',this.isIndeterminate)
             let value={
                 index : this.index,
                 val : val
             }
             this.$emit('ItemChange',val)
        },
        showDetails(item){
            this.$emit('showDetails',item)
        },
        deleteProduct(productId){
            let params = {
                'entryId':localStorage.getItem('entryId'),
                'carItemIdList':[productId]
            }
            deleteProduct(params).then(res=>{
                if(res.code==0){
                    this.$message.success(this.$t('sceneSet.9'))
                    this.$emit('getDetails')
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .item{
        
        .title{
            margin-bottom: 18px; 
            display: flex;
            span{
                margin-left: 10px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 20px;
                color: #343A40;
                opacity: 1;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .itemList{
            .el-checkbox-group{
                font-size: 14px;    
                      
                .group{
                    width: 100%;
                    height: 94px;   
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: 10px;
                    background: #F4F5F8;
                    opacity: 1;
                    border-radius: 8px;
                    padding: 12px 16px 14px 16px;
                    margin-bottom: 10px;
                    .delete{
                        flex: 4;
                        text-align: end;
                    }
                    .num{
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        /deep/ .el-input-number{
                            height: 16px;
                            display: flex;
                            // align-items: center;
                            width: auto;
                            line-height: 23px;
                        .el-input-number__decrease{
                            width: 16px;
                            height: 16px;
                            border-radius: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #A6B0CF;
                            opacity: 1;
                            color: #f4f5f8;
                            border:none;
                        }
                        .el-input-number__increase{
                            width: 16px;
                            height: 16px;
                            border-radius: 16px;
                             display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #A6B0CF;
                            opacity: 1; 
                            color: #f4f5f8;
                            border:none;
                        }
                       .el-input{
                            .el-input__inner{
                            border: none;
                            height: 16px;
                            background: #F4F5F8;
                            padding: 0;
                            font-size: 16px;
                            padding: 0 16px 0 16px;
                            }
                        }
                        }    
                    }
                    img{
                        flex: 1;
                        width: 68px;
                        // height: 68px;
                    }
                    p{
                        width: 100px;
                    }
                }
            }
        }
    }
</style>