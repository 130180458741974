import request from '@/utils/request'

//渠道设备列表
export function myAssetsList (data) {
  return request({
    url: '/sf-partnerportal/pp/assets/list',
    method: 'post',
    data: data,
  })
}

//编辑设备激活
export function updateAutoActivate (data) {
  return request({
    url: "/sf-partnerportal/pp/assets/updateAutoActivate",
    method: "post",
    data: data,
  })
}

//获取产品线
export function getProductTypeList (data) {
  return request({
    url: "/sf-partnerportal/pp/assets/getProductTypeList",
    method: "post",
    data: data,
  })
}

//客户设备列表a
export function customerAssetsList (data) {
  return request({
    url: '/sf-partnerportal/pp/customer/assets/list',
    method: 'post',
    data: data,
  })
}

//查询添加设备
export function addAsset (data) {
  return request({
    url: "/sf-partnerportal/pp/assets/addAsset",
    method: "post",
    data: data,
  })
}

//添加设备
export function addConfirm (data) {
  return request({
    url: "/sf-partnerportal/pp/assets/addConfirm",
    method: "post",
    data: data,
  })
}

//设备详情
export function deviceDetail (data) {
  return request({
    url: "/sf-partnerportal/pp/assets/detail",
    method: "post",
    data: data,
  })
}

//取消分享设备
export function unshareDevice (data) {
  return request({
    url: "/sf-partnerportal/pp/customer/assets/unShare",
    method: "post",
    data: data,
  })
}


//上传激活文件
export function uploadDevice (data, config) {
  return request({
    url: "/sf-partnerportal/pp/assets/uploadDeviceInfo",
    method: "post",
    data: data,
    onUploadProgress: config.onUploadProgress
  })
}

//下载授权文件
export function downloadLicense (data) {
  return request({
    url: "/sf-partnerportal/pp/assets/downloadLicense",
    method: "post",
    data: data,
    responseType: 'blob',
  })
}

//导出设备
export function exprotDevice (data) {
  return request({
    url: "/sf-partnerportal/pp/assets/export",
    method: "post",
    responseType: 'blob',
    data: data,
  })
}
//激活设备
export function applyLicense (data) {
  return request({
    url: "/sf-partnerportal/pp/assets/applyLicense",
    method: "post",
    data: data,
  })
}
