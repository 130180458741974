<template>
  <div class="crm-container">
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      text-color="#A1A4B4"
      active-text-color="#556EE6"
      class="equipmentMenu"
      @select="handleSelect"
    >
      <el-menu-item index="Package">Package</el-menu-item>
      <el-menu-item index="Device">Device</el-menu-item>
    </el-menu>
    <div style="padding: 0 20px">
      <keep-alive>
        <component ref="componentList" :is="getComponentName"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import packageIndex from "./packageIndex.vue";
import deviceIndex from "./deviceIndex.vue";
export default {
  components: { packageIndex, deviceIndex },
  name: "allEquipment",
  data() {
    return {
      crmType: "projectApplication",
      crmName: "所有借测设备",
      equipmentMenu: "Package",
      activeIndex: "Package",
    };
  },
  computed: {
    getComponentName() {
      if (this.equipmentMenu == "Package") {
        return "package-index";
      } else if (this.equipmentMenu == "Device") {
        return "device-index";
      }
    },
  },
  mounted() {
    if (this.$route.query.open) {
      this.equipmentMenu = this.activeIndex = this.$route.query.open;
    }
  },
  methods: {
    handleSelect(val) {
      this.equipmentMenu = val;
      this.$nextTick(() => {
        this.$refs.componentList && this.$refs.componentList.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.crm-container {
  padding: 0;
  .equipmentMenu {
    height: 54px;
    .el-menu-item {
      width: 143px;
      height: 54px;
      text-align: center;
    }
  }
}
</style>