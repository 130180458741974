<template>
  <el-dialog
    :visible.sync="visible"
    :title="'高级筛选'"
    width="900px"
    @close="handleCancel"
    v-dialogDrag
  >
    <div style="margin-bottom: 10px">{{ "筛选条件" }}</div>
    <el-form id="filter-container" class="filter-container">
      <el-form-item>
        <template v-for="(formItem, index) in form">
          <el-row :key="index" :gutter="30" style="width: 100%">
            <el-col :span="9">
              <el-select
                v-model="formItem.fieldName"
                filterable
                size="medium"
                :placeholder="'请选择要筛选的字段'"
                @change="fieldChange(formItem)"
              >
                <el-option
                  v-for="item in fieldList"
                  :key="item.fieldCode"
                  :label="item.name"
                  :value="item.fieldCode"
                />
              </el-select>
            </el-col>
            <el-col v-if="showCalCondition(formItem.formTypeWeb)" :span="4">
              <el-select
                v-model="formItem.condition"
                size="medium"
                :placeholder="'请选择'"
              >
                <el-option
                  v-for="item in calConditionOptions(
                    formItem.formTypeWeb,
                    formItem
                  )"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
            <el-col
              :span="
                formItem.formTypeWeb === 'dateTime' ||
                formItem.formTypeWeb === 'date'
                  ? 14
                  : formItem.formTypeWeb === 'requirement' ||
                    formItem.formTypeWeb === 'IndustryCategory'
                  ? 10
                  : 9
              "
            >
              <el-select
                v-if="formItem.formTypeWeb === 'select'"
                v-model="formItem.value"
                :placeholder="$t('placeholder.sceneTwo')"
                size="medium"
                multiple
                filterable
                collapse-tags
              >
                <el-option
                  v-for="item in formItem.setting"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-date-picker
                v-else-if="
                  formItem.formTypeWeb === 'date' ||
                  formItem.formTypeWeb === 'dateTime'
                "
                v-model="formItem.value"
                :value-format="
                  formItem.formTypeWeb === 'date'
                    ? 'yyyy-MM-dd'
                    : 'yyyy-MM-dd HH:mm:ss'
                "
                :type="
                  formItem.formTypeWeb === 'date'
                    ? 'daterange'
                    : 'datetimerange'
                "
                style="padding: 0px 10px"
                range-separator="-"
                :start-placeholder="$t('sceneCreate.18')"
                :end-placeholder="$t('sceneCreate.19')"
              />
              <el-input
                v-else
                v-model="formItem.value"
                size="medium"
                :placeholder="$t('placeholder.sceneTwo')"
              />
            </el-col>
            <el-col :span="1" class="delete">
              <i
                class="el-icon-error delete-btn"
                @click="handleDelete(index)"
              />
            </el-col>
          </el-row>
        </template>
      </el-form-item>
    </el-form>
    <p v-show="showErrors" class="el-icon-warning warning-info">
      <span class="desc">{{ "筛选条件中有重复项！" }}</span>
    </p>
    <el-button type="text" @click="handleAdd">+ {{ "添加筛选条件" }}</el-button>
    <div class="save">
      <el-checkbox v-model="saveChecked">{{ "保存为场景" }}</el-checkbox>
      <el-input
        v-show="saveChecked"
        v-model="saveName"
        :maxlength="30"
        class="name"
        :placeholder="'请输入场景名称，最多30个字符'"
      />
      <div v-show="saveChecked" class="save-setting">
        <el-checkbox v-model="saveDefault">{{ "设置为默认" }}</el-checkbox>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">{{ "取 消" }}</el-button>
      <el-button type="primary" @click="handleConfirm">{{ "确 定" }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      saveChecked: false, // 展示场景
      saveName: null, // 场景名称
      saveDefault: false, // 设置为默认场景
      showErrors: false,
      form: [],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      // required: true,
      default: false,
    },
    fieldList: {
      type: Array,
      // required: true,
      default: () => {
        return [];
      },
    },
    obj: {
      type: Object,
      // required: true,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          this.form = JSON.parse(JSON.stringify(this.obj.form ?? []));
          if (this.form.length == 0) {
            this.form.push({
              fieldName: "",
              name: "",
              formType: "",
              formTypeWeb: "",
              condition: "in",
              value: "",
              typeOption: [],
              setting: [],
              statusOption: [],
              typeId: "",
              statusId: "",
              address: {
                state: "",
                city: "",
                area: "",
              },
            });
          }
          this.saveChecked = false;
          this.saveDefault = false;
          this.saveName = null;
        }
        this.visible = this.dialogVisible;
      },
      deep: true,
      immediate: true,
    },

    form() {
      this.$nextTick(() => {
        var container = document.getElementById("filter-container");
        if (container) container.scrollTop = container.scrollHeight;
      });
    },
  },
  methods: {
    handleCancel() {
      this.$emit("update:dialogVisible", false);
    },
    /**
     * 添加筛选条件
     */
    handleAdd() {
      this.form.push({
        fieldName: "",
        condition: "in",
        value: "",
        formType: "",
        formTypeWeb: "",
        setting: [],
        typeOption: [],
        statusOption: [],
        typeId: "",
        statusId: "",
      });
    },
    /**
     * 是否展示条件
     */
    showCalCondition(formTypeWeb) {
      if (formTypeWeb == "date" || formTypeWeb == "dateTime") {
        return false;
      }
      return true;
    },
    /** 条件数据源 */
    calConditionOptions(formTypeWeb, item) {
      if (formTypeWeb == "text") {
        return [
          { value: "is", label: this.$t("sceneCreate.14"), disabled: false },
          { value: "isNot", label: this.$t("sceneCreate.15"), disabled: false },
          {
            value: "contains",
            label: this.$t("sceneCreate.16"),
            disabled: false,
          },
          {
            value: "notContains",
            label: this.$t("sceneCreate.17"),
            disabled: false,
          },
        ];
      } else {
        return [
          { value: "in", label: this.$t("sceneCreate.14"), disabled: false },
          { value: "isNot", label: this.$t("sceneCreate.15"), disabled: false },
        ];
      }
    },
    /**
     * 删除筛选条件
     * @param index
     */
    handleDelete(index) {
      this.$confirm(this.$t("sceneCreate.32"), this.$t("other.1"), {
        confirmButtonText: this.$t("other.4"),
        cancelButtonText: this.$t("other.5"),
        type: "warning",
      })
        .then(() => {
          this.form.splice(index, 1);
        })
        .catch(() => {});
    },
    /**
     * 当前选择的字段名改变，判断是否有重复
     * @param formItem
     */
    fieldChange(formItem) {
      const obj = this.fieldList.find((item) => {
        return item.fieldCode === formItem.fieldName;
      });
      if (obj) {
        formItem.formTypeWeb = obj.formTypeWeb;
        formItem.formType = obj.formType;
        formItem.name = obj.name;
        if (formItem.formTypeWeb == "select") {
          formItem.setting = obj.options || [];
          formItem.condition = "in";
          formItem.value = "";
        } else if (
          formItem.formTypeWeb === "date" ||
          formItem.formTypeWeb === "dateTime" ||
          formItem.formTypeWeb === "users" ||
          formItem.formTypeWeb === "usernames" ||
          formItem.formTypeWeb === "category" ||
          formItem.formTypeWeb === "IndustryCategory" ||
          formItem.formTypeWeb === "distributionchannel" ||
          formItem.formTypeWeb === "officeCity" ||
          formItem.formTypeWeb === "interestProduct" ||
          formItem.formTypeWeb === "requirement"
        ) {
          formItem.condition = "in";
          formItem.value = [];
        } else {
          formItem.value = "";
          formItem.condition = "contains";
        }
      }
      const arr = this.form.filter((item) => {
        return item.fieldName === formItem.fieldName;
      });
      if (arr.length > 1) this.showErrors = true;
      else this.showErrors = false;
    },
    /**
     * 确定选择
     */
    handleConfirm() {
      // console.log(this.form);
      if (this.showErrors) {
        this.$message.error(this.$t("sceneCreate.2"));
        return;
      }
      if (this.saveChecked) {
        if (!this.saveName || this.saveName === "") {
          this.$message.error(this.$t("sceneCreate.27"));
          return;
        }
      }
      for (let i = 0; i < this.form.length; i++) {
        const o = this.form[i];
        if (!o.fieldName || o.fieldName === "") {
          this.$message.error(this.$t("sceneCreate.28"));
          return;
        }
        if (
          o.formTypeWeb == "date" ||
          o.formTypeWeb == "dateTime" ||
          o.formTypeWeb == "users" ||
          o.formTypeWeb == "usernames" ||
          o.formTypeWeb == "category"
        ) {
          if (!o.value || o.value.length === 0) {
            this.$message.error(this.$t("sceneCreate.29"));
            return;
          }
        } else if (!o.value && o.value !== 0) {
          this.$message.error(this.$t("sceneCreate.29"));
          return;
        }
      }
      const obj = {};
      this.form.forEach((o, i) => {
        if (o.formTypeWeb == "dateTime" || o.formTypeWeb == "date") {
          obj[o.fieldName] = {
            startDate: moment(o.value[0]).format(),
            endDate: moment(o.value[1]).format(),
            formType: o.formType,
            name: o.fieldName,
            value: o.value,
          };
        } else if (o.formTypeWeb == "users" || o.formTypeWeb == "usernames") {
          obj[o.fieldName] = {
            condition: o.condition,
            value: (o.value && o.value.join(",")) || "",
            valueSave: o.setting,
            formType: o.formType,
            name: o.fieldName,
          };
        } else if (o.formTypeWeb == "distributionchannel") {
          obj[o.fieldName] = {
            condition: o.condition,
            value: (o.value && o.value.join(",")) || "",
            valueSave: o.setting,
            formType: o.formType,
            name: o.fieldName,
          };
        } else if (o.formTypeWeb == "select") {
          obj[o.fieldName] = {
            condition: o.condition,
            value: (o.value && o.value.join(",")) || "",
            formType: o.formType,
            name: o.fieldName,
          };
        } else if (
          o.formTypeWeb == "category" ||
          o.formTypeWeb == "IndustryCategory" ||
          o.formTypeWeb == "officeCity" ||
          o.formTypeWeb == "interestProduct" ||
          o.formTypeWeb == "requirement"
        ) {
          let arr = [];
          arr =
            o.value &&
            o.value.map((item) => {
              return item[item.length - 1];
            });
          obj[o.fieldName] = {
            condition: o.condition,
            value: arr.join(","),
            valueSave: o.value,
            formType: o.formType,
            name: o.fieldName,
          };
        } else {
          obj[o.fieldName] = {
            condition: o.condition,
            value: o.value,
            formType: "text",
            name: o.fieldName,
          };
        }
        obj[o.fieldName].form = JSON.parse(JSON.stringify(o));
      });
      // console.log(obj);
      const data = {
        obj: obj,
        form: this.form,
        saveChecked: this.saveChecked,
        saveDefault: this.saveDefault,
        saveName: this.saveName,
      };
      this.$emit("filter", data);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  margin-top: 0 !important;
  top: 15vh;
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}

/deep/ .el-form-item__label {
  width: 100%;
  text-align: left;
}
.el-button {
  padding: 8px 12px;
}
.filter-container {
  max-height: 300px;
  overflow-y: auto;
}
.save {
  margin-top: 10px;
  .name {
    width: 300px;
    margin-left: 10px;
    .el-input__inner {
      height: 32px;
    }
  }
  .save-setting {
    margin-top: 20px;
  }
}
.el-form-item {
  margin-bottom: 0;
}
.el-row {
  margin-bottom: 20px;
  .delete {
    padding-left: 0 !important;
    .delete-btn {
      margin-left: 0;
      color: #bbb;
      cursor: pointer;
    }
  }
  .el-select,
  .el-date-editor {
    width: 100%;
  }
}
.warning-info {
  width: 100%;
  font-size: 14px;
  color: #f56c6c;
  margin-top: 10px;
  .desc {
    padding-left: 8px;
  }
}
</style>