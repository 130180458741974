import Lockr from 'lockr'
import moment from 'moment'
import {
  queryListHead,
  updateListHeadWidth,
  queryDict,
  queryDept,
  getRequireDist
} from '@/api/common'
import { getListMyDraft, getListMyApply } from '@/api/approval'
import { getPackageList, getDeviceList } from '@/api/allEquipment'
import { getProjectList } from '@/api/allProject'
import { getAreaCode } from "@/api/case.js"
export default {
  data () {
    return {
      loading: false, // 加载动画
      tableHeight: document.documentElement.clientHeight - 240, // 表的高度
      showDetail: false, // 打开详情状态
      showCreate: false, // 打开新建编辑
      form: {},
      pageNumber: 1,
      pageSize: 10,
      pageSizes: [10, 15, 30, 60, 100],
      total: 0,
      list: [],
      fieldList: [], // 表格表头
      filterObj: {}, // 高级筛选保存的内容
      fieldFormList: [], // 带formTypeWeb的表格表头数据
      sortData: {}, // 排序
      search: '', // 搜索内容
      showAllDview: false, // 打开CRMAllDetail页面状态
      rowID: '',
      row: {},
      rowType: '',
      selectionList: []
    }
  },
  mounted () {
    /** 控制table的高度 */
    window.onresize = () => {
      this.updateTableHeight()
    }
    if (!this.$store.state.dict.dict['flow_status']) {
      this.getDict(this.crmType)
      this.queryFormOther()
    }
    this.queryHead()
    this.getCountryCode()
  },
  methods: {
    /**
     * 获取字典，统一在这里设置获取
     * flow_status: 审批状态， contact_role_type: "", materialsUnit: "产品单位",  package_status: "批次状态", deposit_status: "押金状态",  equipment_status: "设备状态", PpProjectStage:'项目状态', country:'国家',
     */
    getDict () {
      this.queryFormDict(
        'contact_role_type',
        'materialsUnit',
        'flow_status',
        'package_status',
        'equipment_status',
        'deposit_status',
        'PpProjectStage',
        'country',
        'borrow_dept',
        'commonType'
      )
    },
    /**
     *   customer_business: "客户行业",  requirement: "需求和应用场景"
     */
    queryFormOther () {
      getRequireDist('customer_business').then(res => {
        this.$store.commit('UPDATE_DICT', {
          key: 'customer_business',
          value: res.rows
        })
      })
      getRequireDist('requirement').then(res => {
        this.$store.commit('UPDATE_DICT', {
          key: 'requirement',
          value: res.rows
        })
      })
      getRequireDist('project_scene').then(res => {
        this.$store.commit('UPDATE_DICT', {
          key: 'project_scene',
          value: res.rows
        })
      })
      queryDept({
        opmanagetypeFilter: 'false'
      }).then(res => {
        res.rows.map(ele => {
          ele.status = !ele.status
          ele.list &&
            ele.list.map(olo => {
              olo.status = !olo.status
            })
        })
        this.$store.commit('UPDATE_DICT', {
          key: 'deptOptions',
          value: res.rows
        })
      })
    },
    queryFormDict (...queryList) {
      queryList.map(ele => {
        if (!this.$store.getters.dict[ele]) {
          queryDict(ele).then(res => {
            this.$store.commit('UPDATE_DICT', {
              key: ele,
              value: res.rows
            })
          })
        }
      })
    },
    /**
     * 更新表高
     */
    updateTableHeight () {
      var offsetHei = document.documentElement.clientHeight
      var removeHeight = 0
      // filterObj 高级筛选选中的筛选集合数据
      if (this.crmType == 'package' || this.crmType == 'device') {
        removeHeight = Object.keys(this.filterObj).length > 0 ? 320 : 250
      } else {
        removeHeight = Object.keys(this.filterObj).length > 0 ? 270 : 200
      }
      this.tableHeight = offsetHei - removeHeight
    },
    /** 勾选操作 */
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      this.selectionList = val // 勾选的行
    },
    // 当拖动表头改变了列的宽度的时候会触发该事件
    handleHeaderDragend (newWidth, oldWidth, column, event) {
      if (column.property) {
        updateListHeadWidth({
          objectCode: this.crmType,
          fieldId: this.fieldList.find(e => {
            return e.fieldName == column.property
          }).id,
          width: newWidth
        })
          .then(res => { })
          .catch(() => { })
      }
    },
    /** 自定义字段管理 */
    setSave () {
      this.fieldList = []
      // this.getFieldList()
      this.queryHead()
    },
    /**
     * 字段排序
     */
    sortChange (column, prop, order) {
      this.pageNumber = 1
      this.sortData.sortName =
        column.column.label && column.column.label.split('&')[1]
      if (column.order) {
        this.sortData.sortOrder = column.order == 'ascending' ? 'asc' : 'desc'
      } else {
        this.sortData.sortOrder = ''
        this.sortData.sortName = ''
      }
      this.getList()
    },
    // 更改每页展示数量
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    // 更改当前页数
    handleCurrentChange (val) {
      this.pageNumber = val
      this.getList()
    },
    /** */
    /** */
    /** 搜索操作 */
    crmSearch (value) {
      this.pageNumber = 1
      this.search = value
      if (this.fieldList.length) {
        this.getList()
      }
    },
    filterField (type) {
      if (type == 4) {
        return 'date'
      } else if (type == 9) {
        return 'select'
      } else if (type == 12) {
        return 'category'
      } else if (type == 10 || type == 26) {
        return 'users'
      } else if (type == 13) {
        return 'dateTime'
      } else if (type == 22) {
        return 'IndustryCategory'
      } else if (type == 23 || type == 28) {
        return 'requirement'
      } else if (type == 25) {
        return 'distributionchannel'
      } else if (type == 27) {
        return 'officeCity'
      } else if (type == 29) {
        return 'interestProduct'
      } else if (type == 31) {
        return 'usernames'
      } else {
        return 'text'
      }
    },
    queryHead () {
      if (this.crmType == 'MyDraft' || this.crmType == 'approvalEdit') {
        if (this.crmType == 'MyDraft') {
          this.fieldList = [
            {
              fieldName: 'flowNo',
              fieldCode: 'flowNo',
              name: this.$t('approval.a1')
            },
            {
              fieldName: 'flowName',
              fieldCode: 'flowName',
              name: this.$t('approval.a19')
            },
            {
              fieldName: 'name',
              fieldCode: 'name',
              name: this.$t('approval.a2')
            },
            {
              fieldName: 'status',
              fieldCode: 'status',
              name: this.$t('approval.a3')
            },
            {
              fieldName: 'applyName',
              fieldCode: 'applyName',
              name: this.$t('approval.a4')
            },
            {
              fieldName: 'createdDate',
              fieldCode: 'createdDate',
              name: this.$t('approval.a5')
            }
          ]
        } else if (this.crmType == 'approvalEdit') {
          this.fieldList = [
            {
              fieldName: 'flowNo',
              fieldCode: 'flowNo',
              name: this.$t('approval.a1')
            },
            {
              fieldName: 'flowName',
              fieldCode: 'flowName',
              name: this.$t('approval.a19')
            },
            {
              fieldName: 'name',
              fieldCode: 'name',
              name: this.$t('approval.a2')
            },
            {
              fieldName: 'applyName',
              fieldCode: 'applyName',
              name: this.$t('approval.a4')
            },
            {
              fieldName: 'createdDate',
              fieldCode: 'createdDate',
              name: this.$t('approval.a5')
            },
            {
              fieldName: 'status',
              fieldCode: 'status',
              name: this.$t('approval.a3')
            },
            {
              fieldName: 'currentName',
              fieldCode: 'currentName',
              name: this.$t('approval.a6')
            }
          ]
        }
        this.getList()
      } else {
        queryListHead({
          objectCode: this.crmType
        })
          .then(res => {
            this.fieldFormList = JSON.parse(JSON.stringify(res.rows))
            this.fieldFormList.forEach(e => {
              e.formTypeWeb = this.filterField(e.fieldType)
            })
            this.fieldList = res.rows.filter(item => {
              return !item.isHide
            })
            this.$nextTick(() => {
              this.$refs.crmTable && this.$refs.crmTable.doLayout()
            })
            this.getList()
          })
          .catch(err => { })
      }
    },
    /** 获取列表数据 */
    getList (refresh) {
      this.loading = true

      if (refresh) {
        this.pageNumber = 1
      }
      let params = {}
      if (
        (this.crmType == 'approvalEdit' && !this.form.status) ||
        (this.crmType == 'approvalEdit' &&
          this.form.status &&
          this.form.status.length == 0)
      ) {
        this.form.status = ['1', '2', '3', '4', '5']
      }
      if (this.crmType == 'approvalEdit' || this.crmType == 'MyDraft') {
        params = Object.assign(
          {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          },
          this.form,
          this.sortData
        )
      } else {
        params = Object.assign(
          {},
          {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            _search: {
              data: this.filterObj || {}
            },
            searchName: this.search
          },
          this.sortData
        )
      }
      if (params._search && Object.keys(params._search).length) {
        for (const key in params._search.data) {
          if (Object.hasOwnProperty.call(params._search.data, key)) {
            const element = params._search.data[key]
            if (element.formType == 'datetime' || element.formType == 'date') {
              element.value = JSON.stringify(element.value)
            }
          }
        }
      }
      let crmIndexRequest = this.getIndexRequest()
      crmIndexRequest(params)
        .then(res => {
          this.list = res.rows.rows
          this.pageSize = res.rows.pageSize
          this.total = res.rows.total
          this.loading = false
          this.$nextTick(() => {
            this.updateTableHeight()
            this.$refs.crmTable && this.$refs.crmTable.doLayout()
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    /** 获取列表请求 */
    getIndexRequest () {
      if (this.crmType == 'package') {
        return getPackageList
      } else if (this.crmType == 'ppProject') {
        return getProjectList
      } else if (this.crmType == 'device') {
        return getDeviceList
      } else if (this.crmType == 'MyDraft') {
        // 我的草稿
        return getListMyDraft
      } else if (this.crmType == 'approvalEdit') {
        // 我发起
        return getListMyApply
      }
    },
    fieldFormatter (row, column) {
      let format = {
        status: 'flow_status',
        stage: 'PpProjectStage'
      }
      if (format[column.property]) {
        return this.findDIctName(format[column.property], row[column.property])
      } else if (
        column.property == 'createdDate' ||
        column.property == 'modifiedDate'
      ) {
        return row[column.property]
          ? moment(row[column.property]).format('YYYY-MM-DD HH:mm:ss')
          : '--'
      } else if (
        (this.crmType == 'ppProject' && column.property == 'expectedPoDate') ||
        column.property == 'expectedPoDateEnd'
      ) {
        return row[column.property]
          ? moment(row[column.property]).format('YYYY-MM-DD')
          : '--'
      } else {
        return row[column.property] || '--'
      }
    },
    findDIctName (key, val) {
      if (
        this.$store.state.dict.dict[key] &&
        this.$store.state.dict.dict[key].length > 0
      ) {
        return this.$store.state.dict.dict[key].reduce((str, ele) => {
          if (key === 'PpProjectStage') {
            if (val === '8') {
              str = 'Win'
            } else if (val === '9') {
              str = 'Lost'
            } else if (val === '10') {
              str = 'Overdue'
            } else {
              str = 'Project in progress'
            }
            return str
          }
          if (ele.value == val && key !== 'PpProjectStage') {
            str = ele.name
          }
          return str
        }, '--')
      } else {
        return '-'
      }
    },
    /** 列表操作 */
    // 当某一行被点击时会触发该事件
    handleRowClick (row, column, event) {
      console.log(this.crmType, column)
      if (this.crmType == 'MyDraft' && column && column.property == 'flowNo') {
        this.showAllDview = true
        this.rowID = row.businessId
        this.row = row
        let rowTypeObj = {
          'Equipment Rental': 'borrowEquipment',
          'Equipment Auth': 'equipmentAuthorization',
          'Extend Equipment Auth': 'equipmentAuthorization',
          'Return Equipment': 'equipmentReturn',
          'PP Equipment Rental': 'borrowEquipment',
          'PP Apply License': 'equipmentAuthorization',
          'PP Renew License': 'equipmentAuthorization',
          Refund: 'equipmentRefund',
          'Project Registration': 'ProjectSaveDetil',
          'Renew Project Registration': 'ProjectSaveDetil'
        }
        this.rowType = rowTypeObj[row.flowName]
      } else if (
        this.crmType == 'approvalEdit' &&
        column &&
        column.property == 'flowNo'
      ) {
        let rowTypeObj = {
          'Equipment Rental': 'borrowEquipment',
          'Equipment Auth': 'equipmentAuthorization',
          'Extend Equipment Auth': 'equipmentAuthorization',
          'Return Equipment': 'equipmentReturn',
          'PP Equipment Rental': 'borrowEquipment',
          'PP Apply License': 'equipmentAuthorization',
          'PP Renew License': 'equipmentAuthorization',
          Refund: 'equipmentRefund',
          'Project Registration': 'ProjectSaveDetil',
          'Renew Project Registration': 'ProjectSaveDetil'
        }
        this.rowID = row.businessId
        this.taskId = row.taskId
        this.status = row.status
        this.row = row
        this.showAllDview = true
        this.rowType = rowTypeObj[row.flowName]
      }
    },
    /** 筛选操作 */
    handleFilter (data) {
      window.console.log('data', data)
      this.pageNumber = 1
      this.filterObj = data
      this.updateTableHeight()
      this.getList()
    },
    /** 详情页操作callback */
    handleHandle (data) {
      this.getList()
    },
    // 获取国家区号
    getCountryCode () {
      getAreaCode().then(res => {
        Lockr.set('countryCode', res.rows)
      })
    }
  }
}
