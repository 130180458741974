<template>
  <div class="details" >
      <div class="details_con">      
      <div class="details_title">
          <h2>{{$t('productSelection.ProductDetails')}}</h2>
          <el-button type="text" icon="el-icon-close" @click="closeDetails"></el-button>
      </div>
      <div class="details_content" v-loading='loadingDetail'>
          <div class="details_left">
          <div>
             <img v-if="productDetails.imgUrl" :src="productDetails.imgUrl" alt="">
             <img v-else src="../../../../assets/default.png" alt="" >
              <span class="downloadkit" @click="downloadkit(productDetails.zipList)"><i class="el-icon-download"></i>{{$t('productSelection.Downloadkit')}}</span>
          </div>
          
          <div class="details_msg">
              <h2>{{productDetails.name}}</h2>
              <span class="performanceDesc" v-html="productDetails.performanceDesc"></span>
              <span></span>
          </div>
          <div class="btnList">
              <el-button type="danger"  @click='addTOCart'><i><img src="@/assets/iconCart.png"  alt=""></i>{{$t('productSelection.AddtoCart')}}</el-button>
            <el-button size="medium" type="primary" @click="SaveSelectionResults"><i><img src="@/assets/img/save.png"  alt=""></i>{{$t('productSelection.SaveSelectionResults')}}</el-button>
          </div>
      </div>
      <div class="details_right">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane :label="$t('productSelection.SpecialEffects')" name="SpecialEffects">
            <el-scrollbar>
            <div v-if="productDetails.keyFeatures"  v-html="productDetails.keyFeatures" class="keyFeatures"></div>
            <div v-else  class="none">
                <img src="@/assets/none.png" alt="">
                <span>{{$t('productSelection.None')}}</span>
            </div>
            </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="$t('productSelection.PerformanceParameters')" name="PerformanceParameters">
            <!-- <el-scrollbar> -->
            <div v-html="productDetails.performanceParameter" class="tableDiv"></div>
            
            <!-- </el-scrollbar> -->
        </el-tab-pane>
        <el-tab-pane :label="$t('productSelection.HardwareParameters')" name="HardwareParameters">
            <!-- <el-scrollbar> -->
            <div v-html="productDetails.hardwareParameter" class="tableDiv"></div>
            <!-- </el-scrollbar> -->
        </el-tab-pane>
    </el-tabs>
      </div>
      </div>
      </div>
  </div>
</template>

<script>
import { filedfsDownload } from '@/api/productSelection'
export default {
    props:{
        productDetails:{
            type:Object,
            default:function(){
                return {}
            }
        },
        indexForm:{
            type:Object,
            default:function(){
                return {}
            }
        },
        loadingDetail:{
            type:Boolean,
            default:false
        }
    },
    data () {
        return {
            msg:'',
            tabsmsg:``,
            table:``,
            activeName:'SpecialEffects'
        }
    },
    mounted () {
      
    },
    methods: {
        closeDetails(){
            this.$emit('closeDetails')
        },
        handleClick(){
            
        },
        SaveSelectionResults(){
            let params = {
                'entryName':this.indexForm.entryName,
                'operationType': 1,
                'productId':this.productDetails.productId,
                'sceneList':this.productDetails.sceneList,
                'recommendType':this.indexForm.recommendType,
            }
            this.$emit('SaveSelectionResults',params)
        },
         //加入购物车
        addTOCart(){
            let params = {
                'entryId': localStorage.getItem('entryId')?localStorage.getItem('entryId'):null,
                'entryName':this.indexForm.entryName,
                'productId':this.productDetails.productId,
                'recommendType':this.indexForm.recommendType,
                'sceneList':this.productDetails.sceneList
            }
            this.$emit('addShoppingCart',params)
        },
        downloadkit(zipList){
            if(zipList.length>0){
                filedfsDownload(zipList[0].zipUrl).then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res])
                    link.style.display = 'none'
                    // 设置连接
                    link.href = URL.createObjectURL(blob)
                    link.download = zipList[0].zipName
                    document.body.appendChild(link)
                    // 模拟点击事件
                    link.click()
                })
            } else {
                 this.$message.warning(this.$t('productSelection.noZip'))
            }
        }
    }
}
</script>

<style lang="less" scoped>
.details{
    height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    .details_con{
        height: 100%;
    
    .details_title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        /deep/.el-icon-close{
            color: #ccc;
            font-size: 40px;
        }
    }
    .details_content{
        display: flex;
        height: 100%;
    .details_left{
        width: 30%;
        padding: 0px 26px 0px 54px;
        border-right: 1px solid #CED4DA;
        height: 85.5%;
        overflow: auto;
        div{
            position: relative;
        }
        img{
            width: 100%;
            // height: 268px;
            margin-bottom: 20px;
        }
        .details_msg{
            margin-bottom: 41px;
             
            h2{
                margin-bottom: 20px;
            }
            .performanceDesc{
            }
        }
        .btnList{
                    display: flex;
                   /deep/ .el-button{
                        // flex: 1;
                        font-size: 12px;
                        padding: 8px 11px 8px 11px;
                        // margin-top: 11px;
                        display: flex;
                        align-items: center;
                        span{  
                            display: flex;
                            align-items: center;
                            img{
                                height: 14px;
                                width: 14px;
                                margin-right: 7px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
        }
        .details_right{
            width: 70%;
            height: 80.5%;
            /deep/ .el-tabs{
                width: 100%;
                height: 100%;
                padding-left: 15px;
                padding-right: 54px;
                .el-tabs__content{
                    overflow-y: auto;
                    height: 93%;
                    .el-tab-pane{
                        height: 100%;
                        .keyFeatures{
                            overflow: auto;
                            // min-height: 43.625rem;
                            img{
                                max-width: 100%;
                            }
                          
                        }
                        .tableDiv{
                            // height: 100%;
                            // max-height:680px;
                            width: 100%;
                            line-height: 28px;
                            overflow: auto;
                            table{
                                width: 100%;
                                height: 25rem;
                                td{
                                    width: 30%;
                                }
                            }
                            
                        }
                    }
                }
                .el-tabs__header{
                    border-bottom: none;
                }
                .el-tabs__nav{
                    width: 100%;
                    border: none;
                .el-tabs__item{
                    width: 33%;
                    text-align: center;
                    background-color: rgba(202, 209, 242, 0.13);
                    // opacity: 0.13;
                    border-radius: 4px;
                    margin-right: 10px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 600;
                    // line-height: 20px;
                    color: #74788D;
                    border: none;
                }
                .is-active{
                    background: #556EE6 !important;
                    color: #fff;
                }
                }
            }
        }
    }
}
}
.downloadkit{
    display: inline-block;
    text-align: right;
    width: 100%;
    color: #556EE6;
    font-size: 12px;
    cursor: pointer;
}
.none{
    height: 43.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/deep/ ol{
    padding: revert;
li{
    list-style: auto !important;
    padding: none !important;
}
}
/deep/ ul{
    padding: revert;
li{
    list-style: initial !important;
    padding: none !important;
}
}
</style>