var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":_vm.edit_id ? '编辑场景' : '新建场景',"visible":_vm.visible,"append-to-body":true,"width":"800px"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.handleCancel}},[_c('el-form',{ref:"sceneCreateForm",staticClass:"filter-container",attrs:{"model":_vm.sceneCreateForm,"rules":_vm.rules,"id":"scene-filter-container"}},[_c('div',{staticClass:"scene-name-container"},[_c('el-form-item',{attrs:{"prop":"saveName"}},[_c('div',{staticStyle:{"display":"inline-block"},attrs:{"slot":"label"},slot:"label"},[_c('div',[_vm._v(_vm._s("场景名称"))])]),_c('el-input',{staticClass:"scene-input",attrs:{"maxlength":30,"size":"medium","placeholder":''},model:{value:(_vm.sceneCreateForm.saveName),callback:function ($$v) {_vm.$set(_vm.sceneCreateForm, "saveName", $$v)},expression:"sceneCreateForm.saveName"}})],1)],1),_c('div',{staticClass:"scene-name"},[_vm._v(_vm._s("筛选条件"))]),_c('el-form-item',[_vm._l((_vm.form),function(formItem,index){return [_c('el-row',{key:index,staticStyle:{"width":"100%"},attrs:{"gutter":30}},[_c('el-col',{attrs:{"span":9}},[_c('el-select',{attrs:{"filterable":"","size":"medium","placeholder":'请选择要筛选的字段'},on:{"change":function($event){return _vm.fieldChange(formItem)}},model:{value:(formItem.fieldName),callback:function ($$v) {_vm.$set(formItem, "fieldName", $$v)},expression:"formItem.fieldName"}},_vm._l((_vm.fieldList),function(item){return _c('el-option',{key:item.fieldCode,attrs:{"label":item.name,"value":item.fieldCode}})}),1)],1),(
              formItem.formTypeWeb !== 'date' &&
              formItem.formTypeWeb !== 'dateTime'
            )?_c('el-col',{attrs:{"span":4}},[_c('el-select',{attrs:{"placeholder":'请选择范围',"size":"medium"},model:{value:(formItem.condition),callback:function ($$v) {_vm.$set(formItem, "condition", $$v)},expression:"formItem.condition"}},_vm._l((_vm.calConditionOptions(
                  formItem.formTypeWeb,
                  formItem
                )),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1):_vm._e(),_c('el-col',{attrs:{"span":formItem.formTypeWeb === 'dateTime' ||
              formItem.formTypeWeb === 'date'
                ? 13
                : formItem.formTypeWeb === 'requirement' ||
                  formItem.formTypeWeb === 'IndustryCategory'
                ? 9
                : 8}},[_c('el-input',{attrs:{"size":"medium","placeholder":'请输入筛选条件'},model:{value:(formItem.value),callback:function ($$v) {_vm.$set(formItem, "value", $$v)},expression:"formItem.value"}})],1),_c('el-col',{staticClass:"delete",attrs:{"span":1}},[_c('i',{staticClass:"el-icon-error delete-btn",on:{"click":function($event){return _vm.handleDelete(index)}}})])],1)]})],2)],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showErrors),expression:"showErrors"}],staticClass:"el-icon-warning warning-info"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s("筛选条件中有重复项！"))])]),_c('el-button',{attrs:{"type":"text","size":"medium"},on:{"click":_vm.handleAdd}},[_vm._v("+ "+_vm._s("添加筛选条件"))]),_c('div',{staticClass:"save"},[_c('div',{staticClass:"save-setting"},[_c('el-checkbox',{model:{value:(_vm.saveDefault),callback:function ($$v) {_vm.saveDefault=$$v},expression:"saveDefault"}},[_vm._v(_vm._s("设置为默认"))])],1)]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v(_vm._s("取 消"))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v(_vm._s("确 定"))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }