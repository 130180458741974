<template>
    <div class="crm-container">
        <h1>Sangfor Technology Limited</h1>
        <h1>Actived users: 1</h1>
        <el-table v-loading="loading" id="crm-table" ref="crmTable" :data="list" :height="tableHeight"
            :cell-style="cellStyle" class="n-table--border" stripe border highlight-current-row style="width: 100%"
            @row-click="handleRowClick" @cell-click="handleCellClick" @sort-change="sortChange"
            @header-dragend="handleHeaderDragend">
            <el-table-column v-for="(item, index) in fieldList" :key="index" :prop="item.fieldName"
                :label="item.name+'&'+item.fieldCode" :width="item.width" show-overflow-tooltip>
                <template slot="header" slot-scope="scope">
                    <div class="table-head-name">{{ scope.column.label && scope.column.label.split('&')[0] }}</div>
                </template>
            </el-table-column>
            <el-table-column />
            <el-table-column fixed="right" width="280" label='Action'>
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" icon='el-icon-video-pause' style="background-color: #F46A6A;">
                        Deactivate</el-button>
                    <el-button type="primary" size="mini" icon='el-icon-lock' style="background-color: #556ee6;">Reset
                        password</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="p-contianer">
            <el-pagination :current-page="pageNumber" :page-sizes="pageSizes" :page-size.sync="pageSize" :total="total"
                class="p-bar" layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import table from "@v/mixins/table";
    export default {
        components: {},
        name: "allUser",
        mixins: [table],
        data() {
            return {
                crmType: "ppProject",
                crmName: "ppProject",
            };
        },
        computed: {},
        mounted() {},
        methods: {
            /** 通过回调控制style */
            cellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                if (column.property === "projectName") {
                    return {
                        color: "#3E84E9",
                        cursor: "pointer"
                    };
                } else {
                    return "";
                }
            },
            handleRowClick(row, column, event) {},
            handleCellClick(row, column, event) {}
        },
    };
</script>

<style lang="less" scoped>
    .crm-container {
        padding: 30px 20px;

        &>h1 {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
            color: #343A40;
        }
    }
</style>