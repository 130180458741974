import { render, staticRenderFns } from "./deviceConfiguration.vue?vue&type=template&id=a10e2452&scoped=true&"
import script from "./deviceConfiguration.vue?vue&type=script&lang=js&"
export * from "./deviceConfiguration.vue?vue&type=script&lang=js&"
import style0 from "./deviceConfiguration.vue?vue&type=style&index=0&id=a10e2452&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a10e2452",
  null
  
)

export default component.exports