<template>
  <div>
    <div class="crm-container">
      <!-- <c-r-m-table-header
        ref="crmTableHead"
        class="crm-table-header"
        :crmType="crmType"
        :crmName="crmName"
        :fieldList="fieldFormList"
        searchPlaceholder="Please input Application NO,Customer Name or Project Name"
        @on-search="crmSearch"
        @filter="handleFilter"
      >
        <el-button
          type="primary"
          size="small"
          style="background-color: #556ee6; margin-left: 12px"
          @click="createProject"
          >项目报备</el-button
        >
      </c-r-m-table-header> -->
      <flexbox class="crm-table-header"
               justify="space-between">
        <flexbox-item></flexbox-item>
        <flexbox-item style="margin: 20px 20px; width: 400px">
          <el-input v-model="search"
                    size="small"
                    placeholder="Please input Customer Name or Project Name"
                    clearable
                    @keyup.enter.native="crmSearch(search)">
            <el-button slot="append"
                       icon="el-icon-search"
                       @click.native="crmSearch(search)" />
          </el-input>
        </flexbox-item>
        <flexbox-item>
          <flexbox justify="flex-end">
            <!-- 项目报备 -->
            <el-button type="primary"
                       size="small"
                       style="background-color: #556ee6; margin-left: 12px"
                       @click="createProject">{{$t('allEquipment.projectRegistration')}}</el-button>
          </flexbox>
        </flexbox-item>
      </flexbox>
      <el-table v-loading="loading"
                id="crm-table"
                ref="crmTable"
                :data="list"
                :height="tableHeight"
                :cell-style="cellStyle"
                class="n-table--border"
                stripe
                border
                highlight-current-row
                style="width: 100%"
                @row-click="handleRowClick"
                @cell-click="handleCellClick"
                @sort-change="sortChange"
                @header-dragend="handleHeaderDragend">
        <el-table-column type="index"
                         width="50"
                         label="NO."></el-table-column>
        <el-table-column v-for="(item, index) in fieldList"
                         :key="index"
                         :prop="item.fieldName"
                         :label="item.name + '&' + item.fieldCode"
                         :width="item.width"
                         :formatter="fieldFormatter"
                         show-overflow-tooltip>
          <template slot="header"
                    slot-scope="scope">
            <div class="table-head-name">
              {{ scope.column.label && scope.column.label.split("&")[0] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column />
        <el-table-column fixed="right"
                         width="100">
          <template slot="header"
                    slot-scope="slot">
            <div style="display: flex; align-items: center">
              <span style="margin-right: 10px">Action</span>
              <fields-pover :crmType="crmType"
                            @set-success="setSave"></fields-pover>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="p-contianer">
        <el-pagination :current-page="pageNumber"
                       :page-sizes="pageSizes"
                       :page-size.sync="pageSize"
                       :total="total"
                       class="p-bar"
                       layout="total, sizes, prev, pager, next, jumper"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange" />
      </div>
    </div>
    <projectSaveDetails v-if="openSaveDetails"
                        :openSaveDetails.sync="openSaveDetails"></projectSaveDetails>
    <projectInfoDialog v-if="dialogVisible"
                       @closeDialog="closeDialog"
                       :info="info"></projectInfoDialog>
    <c-r-m-all-detail :crmType="rowType"
                      :visible.sync="showDetail"
                      @indexRegister="indexRegister"
                      @editProject="editProject"
                      @handle="handleHandle"></c-r-m-all-detail>
    <c-r-m-create-view :crmType="crmType"
                       :visible.sync="showCreate"
                       :action="createActionInfo"
                       @updateBackView="updateBackView"></c-r-m-create-view>
  </div>
</template>

<script>
import table from "@v/mixins/table";
import CRMTableHeader from "@c/CRMTableHeader.vue";
import FieldsPover from "@c/fieldsManager/FieldsPover.vue";
import projectDetails from "./projectDetails.vue";
import projectSaveDetails from "./projectSaveDetails.vue";
import projectInfoDialog from "./projectInfoDialog.vue";

export default {
  name: "allProject",
  components: {
    CRMTableHeader,
    FieldsPover,
    projectDetails,
    projectSaveDetails,
    projectInfoDialog,
  },
  mixins: [table],
  data () {
    return {
      crmType: "ppProject",
      crmName: "ppProject",
      openSave: false,
      openSaveDetails: false,
      dialogVisible: false,
      info: {},
      createActionInfo: {
        type: "save",
      }, // 新建、编辑数据
    };
  },
  mounted () { },
  methods: {
    // 关闭弹窗
    closeDialog () {
      this.dialogVisible = false;
    },
    // 开启弹窗并赋值
    indexRegister (info) {
      this.info = info;
      this.dialogVisible = true;
    },
    // 修改设备记录详情
    editProject () {
      this.showDetail = false;
      this.showCreate = true;
    },
    // 创建设备记录
    createProject () {
      this.$store.commit("setBusinessId", "");
      this.showCreate = true;
    },
    // 返回记录详情页
    updateBackView () {
      this.showDetail = true;
      this.showCreate = false;
    },
    // 保存进入详情
    toDetailsView () {
      this.openSaveDetails = true;
    },
    /** 通过回调控制style */
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (column.property === "projectName") {
        return {
          color: "#3E84E9",
          cursor: "pointer",
        };
      } else if (column.property === "expectedPoDateEnd" && row.stage === '10') {
        return {
          color: "#f46a6a",
        };
      } else {
        return "";
      }
    },
    handleRowClick () { },
    handleCellClick (row, column, cell, event) {
      console.log(row);
      if (column.property == "projectName") {
        this.rowType = this.crmType;
        this.$store.commit("setBusinessId", row.businessId);
        this.showDetail = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.crm-table-header {
  font-size: 13px;
  height: 50px;
  padding: 0 20px;
}
</style>