<template>
  <div class="item">
      <div class="title">
          <div class="title_1">
              <el-checkbox v-if="showBtn" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
            <span class="entryName" style="white-space: pre;">{{list.entryName}}</span>
          </div>
            <div class="titleBtn" >
                <span v-if="showBtn" class="time">{{$t('productSelection.Reverse')+':'+ list.currentDate}}</span>
                <span v-if="!showBtn" class="time">{{$t('productSelection.downloadTime')+':'+list.currentDate}}</span>
                <el-button v-if="!showBtn"  @click="showPreview(list)"><i><img src="@/assets/img/preview.png" alt=""></i>{{$t('productSelection.OtherBOMPreview')}}</el-button>
                <!-- <el-button @click="resetForm" v-if="showBtn" class="deleteBtn" icon="el-icon-delete">{{$t('common.btn.delete')}}</el-button> -->
            </div>
      </div>
    <div class="itemList">
       <el-checkbox-group v-model="checkedItem" @change="handleCheckedItemChange">
                <div  v-for="(i,key) in list.detailList"  :key="key" class="group">
                    <div class="groupItem">                   
                    <el-checkbox :label="i" v-if="showBtn">
                    {{''}}
                    </el-checkbox>
                    <img v-if="i.imgUrl" :src="i.imgUrl" alt="">
                    <img v-else src="../../../../assets/default.png" alt="" >
                    <div class="nexttitle">
                       <span @click="productNametableShow($event)">{{i.productName}}</span> 
                    </div>
                    <div class="num" >
                        {{$t('productSelection.EquipmentSets')}}:{{i.productQuantity}}<!-- <el-input-number v-if="showBtn" size="mini" v-model="i.num"></el-input-number> -->
                    </div>
                    <div class="btnClass">
                        <el-button   v-if="showBtn" icon="el-icon-s-operation" @click="configuration(i)">{{$t('productSelection.Edit')}}</el-button>
                        <el-popconfirm
                            :title="$t('sceneCreate.32')"
                            @confirm='deleteProduct(i)'
                            >
                        <el-button slot="reference"   v-if="showBtn" icon="el-icon-delete" >{{$t('common.btn.delete')}}</el-button>
                        </el-popconfirm>
                    </div>
                    <div class="delete">
                       <i class="el-icon-arrow-down down" @click="tableShow($event)"></i>
                    </div>
                    </div>
                    <el-collapse-transition name="el-zoom-in-top">
                    <div class="showTable">
                         <el-table
                            :data="i.serviceList"
                            stripe
                            :border='false'
                            style="width: 100%">
                            <el-table-column
                                type="index"
                                :label="$t('productSelection.number')"
                                :index="indexMethod"
                                width="50">
                            </el-table-column>
                             <el-table-column
                                prop="productName"
                                :label="$t('detail.productName')"
                                width="180">
                            </el-table-column> 
                            <el-table-column
                                prop="productTypeName"
                                :label="$t('productSelection.productType')"
                                width="180">
                            </el-table-column>
                           
                            <el-table-column
                                prop="productDesc"
                                :label="$t('productSelection.productDescription')">
                            </el-table-column>
                            <el-table-column
                                prop="purchaseQuantity"
                                width="200"
                                :label="$t('productSelection.PurchaseQuantity')">
                            </el-table-column>
                            <el-table-column
                                prop="defaultQuantity"
                                width="200"
                                :label="$t('productSelection.TaketheNumber')">
                            </el-table-column>
                            </el-table>
                    </div>
                    </el-collapse-transition>
                </div>    
        </el-checkbox-group>
    </div>
   
    <el-dialog
  :title="$t('productSelection.BOMPreview')"
  :visible.sync="dialogVisible"
  top='7vh'
  class="Preview"
  width="80%"
  @close='dialogVisible = false'>
  <el-scrollbar v-loading='dialogloading'>
  <div class="table-html-wrap" v-html="tableHtml" >
      
  </div>
    </el-scrollbar>
  <span slot="footer" class="dialog-footer">
      <div class="button">
           <el-button @click="dialogDownload(list)" icon="el-icon-download">{{$t('productSelection.download')}}</el-button>
      </div>
  </span>
</el-dialog>
 
  </div>
</template>

<script>
import DeviceConfiguration from './deviceConfiguration.vue'
import { configList,exportDemo,showexcel } from '@/api/selectionOfRecord.js'
import domPreview from '@/assets/domPreview.png'
import XLSX from 'xlsx'
export default {
    name:'cartItem',
    components: {
        DeviceConfiguration
    },
    props: {
        //列表数据
        list:{
            type:Object,
            require:true,
            default:[]
        },
        index:{
            type:Number,
            require:true,
            default:''
        },
        //全选
        allChecked:{
            type:Boolean,
            require:true,
            default:false
        },
        //按钮显示
        showBtn:{
            type:Boolean,
            require:true,
            default:false
        },
        //是否已经删除
        hasDelete:{
            type:Boolean,
            require:false,
            default:false
        },
        //展开全部
        expandAll:{
            type:Boolean,
            require:false,
            default:false
        },
        clearSelect:{
            type:Boolean,
            require:false,
            default:false
        },
        clearItem:{
            type:Boolean,
            require:false,
            default:false
        },
    },
    data () {
        return {
            isIndeterminate: false,
            checkAll:false,
            checkedItem:[],
            Show:false,
            centerDialogVisible:false,
            dialogVisible:false,
            productData:{},
            tableHtml:'',
            pdfUrl:'',
            dialogloading:false
        }
    },
    watch: {
        checkAll(val){
            if(val){
                this.$emit('hasallChecked',val)
            }
        },
        clearSelect(val){
            this.isIndeterminate = false
            this.checkAll = false
            this.checkedItem = []
        },
        allChecked(val){
            if(this.allChecked == true){
                this.checkAll = true
                this.checkedItem = this.list.detailList.map((i)=>{
                    return i
                })
                let value={
                 id : this.list.id,
                 val : this.checkedItem,
                 add:true,
                 singel:false,
                 showChecked:true
                }
                this.isIndeterminate = false;
                setTimeout(() => {
                    this.$emit('ItemChange', value)
                }, 100);   
            } else {
                let value={
                 id : this.list.id,
                 val : this.checkedItem,
                 add : false,
                 singel:false,
                 showChecked:false
                }
                this.checkedItem = []
                this.checkAll = false
               setTimeout(() => {
                    this.$emit('ItemChange', value)
                }, 100);
            }
            this.$emit('hasallChecked',val)
             
        },
        //删除后清除全选
        hasDelete(){
            if(this.hasDelete==true){
                this.checkAll = false
            }
        },
        //全部展开
         expandAll(val){
              var groupItem = document.getElementsByClassName('groupItem')
              var down = document.getElementsByClassName('down')
          if(val== true){
              for(var i=0;i<groupItem.length;i++){
                  this.Show = false
                  groupItem[i].nextElementSibling.style=`display:block`
                  down[i].style=`transform:rotate(180deg)`;
              }
          }else{
            for(var i=0;i<groupItem.length;i++){
                  this.Show = true
                  groupItem[i].nextElementSibling.style=`display:none`
                  down[i].style=`transform:rotate(0deg)`;
              }
            
          }
      },
      'checkedItem':{
          //监听选中
          handler(newVal,oldVal){
                   let value
            if(newVal.length>oldVal.length){
                 value={
                 id : this.list.id,
                 val : this.checkedItem,
                 add:true,
                 singel:false,
                 showChecked:''
                }                
            }else if(newVal.length<oldVal.length){
                 value={
                 id : this.list.id,
                 val : this.checkedItem,
                 add:false,
                 singel:true,
                 showChecked:false
                }
            }else{
                value={
                 id : this.list.id,
                 val : this.checkedItem,
                 add:'',
                 singel:false,
                 showChecked:''
                }
            }
            setTimeout(() => {
            if(this.list.detailList.length == this.checkedItem.length){
                    value.showChecked = true         
            }
            this.$emit('ItemChange',value)
            }, 100);
          }
      },
      clearItem(){
          //清空选中
          this.checkedItem = []
          this.checkAll = false
          this.isIndeterminate = false
      }
    },
    
    methods: {
        //去重
        downloadListUnique(arr){
           if(arr.length>0){
             var arr1 = [arr[0]];
                    for(var i=1,len=arr.length;i<len;i++){
                        if(arr[i].code !== arr[i-1].code){
                            arr1.push(arr[i]);
                        }
                    }
           }else{
                      var arr1 = []
                    }
                    return arr1;
           
         },
        handleCheckAllChange(val){
            //add  是否为新增  singel：是否为单选框
             var list = []
                this.checkedItem.forEach(item=>{
                    list.push(item.id)
                })
            if(val == true){
                this.checkedItem = this.list.detailList.map((i)=>{
                    return i
                })
                 let value={
                 id : this.list.id,
                 val : this.checkedItem,
                 add:true,
                 singel:true
                }
                this.isIndeterminate = false;
            } else {
                 let value={
                 id : this.list.id,
                 val : this.checkedItem,
                 add:false,
                 singel:true
                }
                this.checkedItem = [] 
            }
             this.$emit('hasallChecked',val)
        },
        handleCheckedItemChange(val){
             let checkedCount = val.length;
             this.checkAll = checkedCount === this.list.detailList.length;
             this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.detailList.length;
             this.$emit('hasallChecked',this.checkAll)
        },
        tableShow(e){
            var target= e.target===e.currentTarget? e.target:e.currentTarget
            //判断当前角度
            if(target.style.transform == 'rotate(180deg)'){
                target.style = `transform:rotate(0deg)`;  
                target.parentNode.parentNode.nextElementSibling.style=`display:none`   
            }else{
                target.style = `transform:rotate(180deg)`;  
                target.parentNode.parentNode.nextElementSibling.style=`display:block` 
            }
        },
        productNametableShow(e){
            var target= e.target===e.currentTarget? e.target:e.currentTarget
            if(target.parentNode.nextElementSibling.nextElementSibling.nextElementSibling.firstChild.style.transform == 'rotate(180deg)'){
                target.parentNode.nextElementSibling.nextElementSibling.nextElementSibling.firstChild.style = `transform:rotate(0deg)`;
                target.parentNode.parentNode.nextElementSibling.style=`display:none`
            }else{
                target.parentNode.nextElementSibling.nextElementSibling.nextElementSibling.firstChild.style = `transform:rotate(180deg)`;
                target.parentNode.parentNode.nextElementSibling.style=`display:block`
            }
        },
        //序号 +0
        indexMethod(index) {
            if(index<10){
                return '0'+(index+1)
            }
      },
      //配置
      configuration(val){
          this.$emit('getConfigList',val)
      },
      //单个删除
      deleteProduct(val){
          this.$emit('productRemove',val)
      },
        //预览
      showPreview(value){      
          this.dialogVisible = true  
          this.dialogloading = true 
          showexcel({id:value.id}).then(res=>{
              var data = new Uint8Array(res)
                var workbook = XLSX.read(data, {
                type: 'array'
                })
                this.tableToHtml(workbook)
                this.dialogloading = false
                })
            },
            //execl转成html
            tableToHtml(workbook) {
            var worksheet = workbook.Sheets[workbook.SheetNames[0]];
            var innerHTML = XLSX.utils.sheet_to_html(worksheet);
            var innerHTMLmatch = innerHTML.replace('<td rowspan="5" colspan="3" id="sjs-A2"></td>',`<td style="text-align: center;" rowspan="5" colspan="3" id="sjs-A2"><img style="text-align: center;height:100%;" src='${domPreview}' alt=""/></td>`) 
            var innerHTMLmatch = innerHTMLmatch.replace(/<tr><td colspan="5"/g,'<tr style="background: #ccffcc;"><td colspan="5" style="text-align: right;background: #ccffcc;"')
            this.tableHtml = innerHTMLmatch;
        },
        //下载
        dialogDownload(list){
            exportDemo({id:list.id}).then(res=>{
              const link = document.createElement('a');
              let blob = new Blob([res],{type:"application/zip"});
              link.style.display = 'none';
              //设置连接
              link.href = URL.createObjectURL(blob);
              link.download = `SANGFOR_NGAF_BOM${moment(new Date()).format('YYYY.MM.DD_HH-mm-ss')}.zip`; 
              document.body.appendChild(link);
              //模拟点击事件
              link.click();
              this.$emit('getList')
              this.dialogVisible = false              
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .item{
        border: 1px solid #CED4DA;
        opacity: 1;
        border-radius: 2px;
        margin-bottom: 20px;
        z-index: 999;
        width: 99%;
        
        .title{
            margin-bottom: 18px; 
            height: 54px;
            background: #F4F5F8;
            opacity: 1;
            border-radius: 2px;
             padding: 0 20px 0 20px;
             display: flex;
             justify-content: space-between;
             .title_1{
             display: flex;
             align-items: center;
             width: 30%;
             .entryName{
                 width: 100%;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
             }
           }
             .titleBtn{
                 display: flex;
                 align-items: center;
                 .time{
                     margin-right: 143px;
                     font-size: 14px;
                    font-family: FZLanTingHeiS;
                    font-weight: 400;
                    line-height: 31px;
                    color: #74788D;
                    opacity: 1;
                 }
                 .deleteBtn{
                     background: #FFFFFF !important;
                    border: 1px solid #CED4DA;
                    opacity: 1;
                    border-radius: 2px;
                    color: #74788D !important;
                    opacity: 1;
                 }
                 /deep/ .el-button{
                     background: #556EE6;
                    opacity: 1;
                    border-radius: 2px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    border: none;
                    span{
                        display: flex;
                        align-items: center;
                         img{
                        margin-right: 5px;
                    }
                    }
                   
                 }
             }
            span{
                font-size: 18px;
                font-weight: 500;
                line-height: 54px;
                color: #343A40;
                opacity: 1;
                margin-left: 10px;
                
            }
        }
        .itemList{
            .el-checkbox-group{
                font-size: 14px;            
                .group{
                    .showTable{
                        // height: 300px;
                        display: none;
                        transition: height 2s;
                        padding: 0 20px 0 20px;
                        margin-bottom: 20px;
                        /deep/.el-table{
                            border: 1px solid #CED4DA;
                            td{
                                border-bottom:none;
                            }
                        }
                    }
                    .groupItem{
                    width: 100%;
                    // height: 94px;   
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // margin-left: 10px;
                    // background: #F4F5F8;
                    opacity: 1;
                    border-radius: 8px;
                    padding: 0px 16px 0px 16px;
                    margin-bottom: 10px;
                    .nexttitle{
                        margin-left: 10px;
                        flex: 8;                        
                        span{
                            cursor: pointer;
                        }
                    }
                    .delete{
                        flex: 0.2;
                        text-align: end;
                        .down{
                            cursor: pointer;
                        }
                    }
                    .btnClass{
                        // flex: 2;
                        text-align: end;   
                        /deep/ .el-button{
                            border-radius: 0;
                            .el-icon-s-operation{
                                color: #74788d;
                            }
                            .el-icon-delete{
                                color: #74788d;
                            }
                            span{
                                color: #74788d;
                                margin-left: 10px;
                            }
                        }
                        span{
                            // .el-popover{
                                margin-left: 10px;
                            // }
                        }
                    }
                    .num{
                        flex: 2;
                        display: flex;
                        // flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        /deep/ .el-input-number{
                            height: 16px;
                            display: flex;
                            // align-items: center;
                            width: 60px;
                            line-height: 23px;
                        .el-input-number__decrease{
                            width: 16px;
                            height: 16px;
                            border-radius: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #A6B0CF;
                            opacity: 1;
                            color: #f4f5f8;
                            border:none;
                        }
                        .el-input-number__increase{
                            width: 16px;
                            height: 16px;
                            border-radius: 16px;
                             display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #A6B0CF;
                            opacity: 1; 
                            color: #f4f5f8;
                            border:none;
                        }
                       .el-input{
                            .el-input__inner{
                            border: none;
                            height: 16px;
                            // background: #F4F5F8;
                            padding: 0;
                            font-size: 16px;
                            padding: 0 16px 0 16px;
                            }
                        }
                        }    
                    }
                    img{
                        width: 4rem;
                        // height: 4rem;
                    }
                }
                }
            }
        }
    }
    /deep/.el-dialog__header{
    background-color: #FFFFFF;
    
}
/deep/ .el-dialog__body{
    min-height: 45rem;
    height: 45.9375rem;
    overflow: auto;
    // img{
    //     width: 100%;
    //     height: 70%;
    // }
    .table-html-wrap{
        width: 100%;
        height: 100%;
        overflow: auto;
    }
}
.Preview{
/deep/ .el-dialog__footer{
    border-top: none;
    text-align: center;
    display: flex;
    .dialog-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
        .button{
            flex: 1.5;
        .el-button{
        background: #556EE6;
        color: #FFFFFF;
        }
        }
        .prompt{
        flex: 1;
    .el-checkbox{
            margin-left: 12px;
        }
    }
    }
    }
}
.DeviceConfiguration{
    // z-index: 9;
}
.table-html-wrap /deep/ table {
	border-right: 1px solid  #e8eaec;
	border-bottom:1px solid  #e8eaec;
	border-collapse: collapse;
	margin: auto;
    #sjs-A1{
       text-align: center !important;
       background: #c0c0c0 !important;
   }
   #sjs-A6{
       text-align: left !important;
       background: none !important;
   }
   }
 
   .table-html-wrap /deep/ table td {
	border-left: 1px solid  #e8eaec;
	border-top: 1px solid  #e8eaec;
	// text-align: center;
	padding: .5rem;
   }
 
   .table-html-wrap /deep/ tbody tr:nth-of-type(1) {	
	font-size: 20px;
    td{
        font-weight: bold;
    }
   }
      .table-html-wrap /deep/ tbody tr:nth-of-type(2) {
    td{
        text-align: left;
    }
   }
    .table-html-wrap /deep/ tbody tr:nth-of-type(3) {
    td{
        text-align: left;
    }
   }
  .table-html-wrap /deep/ tbody tr:nth-of-type(4) {
    td{
        text-align: left;
    }
   }
    .table-html-wrap /deep/ tbody tr:nth-of-type(5) {
    td{
        text-align: left;
    }
   }
   .table-html-wrap /deep/ tbody tr:nth-of-type(6) {
    td{
        text-align: left;
    }
   }
   .table-html-wrap /deep/ tbody tr:nth-of-type(7) {
    td{
        text-align: left;
        font-family: Arial;
        font-style: italic;
    }
   }
    .table-html-wrap /deep/ tbody tr:nth-of-type(8) {
        text-align: center;
        td{
            font-weight: bold;
        }
        td:nth-of-type(1){
            background: #c0c0c0;
            min-width: 100px;
        }
        td:nth-of-type(2){
            background: #c0c0c0;
            min-width: 150px;
        }
        td:nth-of-type(3){
            background: #c0c0c0;
            min-width: 140px;
        }
        td:nth-of-type(4){
            background: #c0c0c0;
        }
        td:nth-of-type(5){
            background: #c0c0c0;
            min-width: 100px;
        }
        td:nth-of-type(6){
            background: #c0c0c0;
            min-width: 100px;
        }
        td:nth-of-type(7){
            background: #c0c0c0;
            min-width: 100px;
        }
   }
   .table-html-wrap /deep/ tbody tr:last-of-type {
       td:nth-of-type(1){
           background: #99ccff !important;
       }
       td:nth-of-type(2){
           background: #99ccff !important;
       }
       td:nth-of-type(3){
           background: #99ccff !important;
       }
       
    
   }
    .table-html-wrap /deep/ tbody tr  {
        td{
            word-break: break-word;
            text-align: center;
        }
        td:last-of-type{
            display: none;
        }
        
   }
   
</style>