<template>
  <el-dialog title="Sangfor Firewall Procutment project information"
             :visible.sync="dialogFormVisible"
             v-dialogDrag
             @closed="closeDialog">
    <el-form :model="ruleForm"
             :rules="rules"
             ref="ruleForm"
             :label-position="labelPosition"
             :label-width="formLabelWidth"
             style="padding: 0 30px;">
      <el-form-item label="Registration Expiry Date："
                    prop="registrationDate：">
        <span>{{moment(info.expectedPoDateEnd).format('YYYY-MM-DD')}}</span>
      </el-form-item>
      <!-- <el-form-item label="Status：" prop="stage">
        <el-input v-model="ruleForm.status" size="small" autocomplete="off"></el-input>
      </el-form-item> -->
      <el-form-item label="Expected PO Date："
                    required>
        <el-form-item prop="expectedPoDate">
          <el-date-picker type="date"
                          size="small"
                          v-model="ruleForm.expectedPoDate"
                          style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-form-item>
      <!-- <el-form-item label="Customer Budget：" prop="customerBudget">
        <el-input v-model="ruleForm.customerBudget" size="small" disabled autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" placeholder="Please enter the number"></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="Stage：" prop="stage">
        <el-select v-model="ruleForm.stage">
          <el-option label="20%:BOM Submitted" value="0" :disabled="this.isBusiness"></el-option>
          <el-option label="30%:Customer Visited" value="1" :disabled="this.isBusiness"></el-option>
          <el-option label="40%:POC Arranged" value="3"></el-option>
          <el-option label="50%:POC Conducted" value="4"></el-option>
          <el-option label="60%:POC Passed" value="5"></el-option>
          <el-option label="70%:Quotation Requested" value="6"></el-option>
          <el-option label="90%:PO Received" value="7"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="Reason："
                    prop="reason">
        <el-input type="textarea"
                  v-model="ruleForm.reason"
                  rows='5'
                  resize='none'></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button size="small"
                 @click="dialogFormVisible=false">Cancel</el-button>
      <el-button type="primary"
                 :loading="confirmLoading"
                 @click.native="submitForm('ruleForm')"
                 size="small"
                 style="background-color: #556ee6;">
        Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  extendApplicationProject
} from "@/api/allProject"
import moment from "moment";
export default {
  name: "projectInfoDialog",
  components: {},
  props: {
    info: Object
  },
  data () {
    return {
      dialogFormVisible: true,
      infoDialog: {},
      isBusiness: false,
      ruleForm: {
        stage: '',
        expectedPoDate: '',
        // customerBudget: '',
        stage: "",
        reason: ''
      },
      rules: {
        stage: [{
          required: true,
          message: 'Please input the stage',
          trigger: 'blur'
        }],
        expectedPoDate: [{
          required: true,
          message: 'Please input the Expected PO Date',
          trigger: 'blur'
        }],
        // customerBudget: [{
        //   required: true,
        //   message: 'Please input the Customer Budget',
        //   trigger: 'blur'
        // }],
        stage: [{
          required: true,
          message: 'Please input the stage',
          trigger: 'blur'
        }]
      },
      labelPosition: 'left',
      formLabelWidth: '190px',
      confirmLoading: false
    };
  },
  created () {
    this.infoDialog = this.info
    this.ruleForm.stage = this.infoDialog.stage
    this.ruleForm.expectedPoDate = this.infoDialog.expectedPoDate
    // this.ruleForm.customerBudget = this.infoDialog.customerBudget
    // this.ruleForm.reason = this.infoDialog.reason
    this.isBusiness = this.info.stage >= 2 ? true : false;
    console.log(this.info);
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.confirmLoading = true
          let params = {
            businessId: this.infoDialog.businessId,
            stage: this.ruleForm.stage,
            expectedPoDate: this.ruleForm.expectedPoDate,
            // customerBudget: this.ruleForm.customerBudget,
            stage: this.ruleForm.stage,
            reason: this.ruleForm.reason
          }
          console.log(params);
          console.log(this.infoDialog);
          // if (params.businessId == this.infoDialog.businessId && params.stage == this.infoDialog.stage && params.expectedPoDate == this.infoDialog.expectedPoDate) {
          //   this.$message({
          //     type: "warning",
          //     message: this.$t('allEquipment.message'),
          //   });
          //   this.dialogFormVisible = false
          // } else {
            extendApplicationProject(params).then(res => {
              console.log(res)
              let {
                msg
              } = res
              this.confirmLoading = false
              if(res.code === 500){
                this.$message({
                type: "warning",
                message: msg,
              });
              }else{
                this.$message({
                  type: "success",
                  message: msg,
                });
              }
              this.dialogFormVisible = false
            }).catch(err => {
              this.dialogFormVisible = false
            })
          // }
        } else {
          return false;
        }
      });
    },
    closeDialog () {
      this.$emit('closeDialog')
    }
  },
  computed: {
    moment () {
      return moment
    }
  }
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>