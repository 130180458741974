<template>
  <div v-loading="loading"
       class="person-container">
    <div class="header-handle">
      <el-dropdown @command="handleCommand"
                   style="margin-right: 10px; margin-left: 10px">
        <span class="el-dropdown-link">
          {{ LanTitle }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="CN">中文</el-dropdown-item>
          <el-dropdown-item command="EN">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button plain
                 @click="editClick('back')">{{
        $t("PersonCenter.1")
      }}</el-button>
    </div>
    <flexbox class="person-info">
      <div class="person-body">
        <div class="person-name">
          {{ userInfo.surname || "" }} {{ userInfo.name }}
        </div>
        <flexbox class="person-detail">
          <div style="margin-right: 50px">
            {{ $t("allEquipment.channel") + "：" }}
            {{ userInfo.channelName || "--" }}
          </div>
          <div style="margin-right: 50px">
            {{ $t("PersonCenter.3") + ":"}} {{ userInfo.title || "--" }}
          </div>
          <!-- <div style="margin-right: 50px">
            {{ "对接办事处：" }}
            {{
              (dictData.deptOptions &&
                findFullName(
                  userInfo.deptId,
                  dictData.deptOptions,
                  "deptId",
                  "list",
                  true
                )) ||
              userInfo.deptName ||
              "--"
            }}
          </div> -->
        </flexbox>
      </div>
    </flexbox>
    <div class="segmentation" />
    <div class="section">
      <div class="section-header">
        <div class="section-mark" />
        <div class="section-title">{{ $t("PersonCenter.4") }}</div>
      </div>
      <flexbox :gutter="0"
               wrap="wrap"
               class="content">
        <flexbox-item v-for="(item, index) in list"
                      :key="index"
                      :span="0.5"
                      class="info-cell">
          <flexbox align="stretch"
                   class="info-cell-box">
            <div class="info-cell-box-name">{{ item.name }}</div>
            <div class="info-cell-box-value">
              {{
                item.props !== "sex"
                  ? item.props == "name"
                    ? (userInfo.surname || "") + " " + userInfo[item.props]
                    : userInfo[item.props] || "--"
                  : userInfo[item.props]
              }}
            </div>
          </flexbox>
        </flexbox-item>
      </flexbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { findFullName } from "@/utils";
import { queryDept } from "@/api/common";

export default {
  name: "Person",
  components: {},
  data () {
    return {
      loading: false,
      LanTitle: "",
      editImage: null,
      editFile: null,
      list: [
        { name: this.$t("PersonCenter.7"), props: "name" },
        { name: this.$t("PersonCenter.8"), props: "username" },
        { name: this.$t("PersonCenter.9"), props: "email" },
        { name: this.$t("PersonCenter.10"), props: "department" },
        { name: this.$t("PersonCenter.11"), props: "title" },
        { name: this.$t("PersonCenter.12"), props: "telephone" },
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    findFullName () {
      return findFullName;
    },
    dictData () {
      return this.$store.state.dict.dict;
    },
  },
  mounted () {
    this.getDetail();
    if (localStorage.getItem("lang") && localStorage.getItem("lang") == "CN") {
      this.LanTitle = "中文";
    } else {
      this.LanTitle = "English";
    }
    if (!this.dictData.deptOptions) {
      queryDept({
        opmanagetypeFilter: "false",
      }).then((res) => {
        res.rows.map((ele) => {
          ele.status = !ele.status;
          ele.list &&
            ele.list.map((olo) => {
              olo.status = !olo.status;
            });
        });
        this.$store.commit("UPDATE_DICT", {
          key: "deptOptions",
          value: res.rows,
        });
      });
    }
  },
  methods: {
    handleCommand (val) {
      if (val == "CN") {
        this.LanTitle = "中文";
        this.$i18n.locale = "CN";
      } else {
        this.LanTitle = "English";
        this.$i18n.locale = "EN";
      }
      localStorage.setItem("lang", this.$i18n.locale);
      window.location.reload();
    },
    getDetail () {
      this.loading = true;
      this.$store
        .dispatch("GetUserInfo")
        .then((res) => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editClick (type) {
      if (type == "back") {
        this.$router.go(-1);
      }
    },
    submiteImage (data) {
      this.loading = true;
      var param = new FormData();
      param.append("userId", this.userInfo.userId);
      param.append("file", data.blob, data.file.name);
      adminUsersUpdateImg(param)
        .then((res) => {
          this.loading = false;
          this.getDetail();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="less" scoped>
.person {
  &-container {
    width: 900px;
    min-width: 900px;
    margin: 0 auto;
    height: 100%;
    border: 1px solid #e6e6e6;
    background-color: white;
    border-radius: 2px;
    overflow-y: auto;
  }
}

.header-handle {
  float: right;
  padding: 10px 30px;
}

.person-info {
  padding: 0 80px 30px;
  position: relative;
  .person-head {
    .person-head-img {
      display: block;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      margin-right: 35px;
      cursor: pointer;
    }
    .select-picture {
      position: absolute;
      display: none;
      z-index: 3;
      top: 0;
      left: 80px;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      background-color: rgba(0, 0, 0, 0.2);
      text-align: center;
      cursor: pointer;
      div {
        cursor: pointer;
        margin-top: 55px;
        color: white;
        font-size: 12px;
      }
    }
  }
  .person-head:hover {
    .select-picture {
      display: block;
    }
  }
  .person-body {
    .person-name {
      font-size: 25px;
      margin-bottom: 15px;
    }
    .person-detail {
      font-size: 12px;
      color: #777;
    }
  }
}

// 分割线
.segmentation {
  height: 4px;
  background-color: #f5f6f9;
}

.section {
  padding: 30px 80px;
  position: relative;
  background-color: white;
  margin-top: 8px;
  .section-mark {
    border-left: 2px solid #46cdcf;
    height: 10px;
  }

  .section-header {
    display: flex;
    align-items: center;
    padding: 5px 15px;
  }
  .section-title {
    font-size: 13px;
    color: #333;
    margin-left: 8px;
    flex-shrink: 0;
  }
}

.content {
  position: relative;
  padding: 10px 20px;
}

.info-cell {
  padding: 0 10px;
  &-box {
    width: auto;
    padding: 8px;
    &-name {
      width: 100px;
      margin-right: 10px;
      font-size: 12px;
      flex-shrink: 0;
      color: #777;
    }
    &-value {
      font-size: 12px;
      color: #333;
    }
  }
}
</style>
