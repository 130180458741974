<template>
<div class="competitor">
   <el-form ref="form" :model="form" label-position="left" :rules="rules" @submit.native.prevent>
        <div class="throughputRequired">
            <el-form-item :label="$t('productSelection.CompetingGoodsCompany')+':'" prop="companyId">
            <el-select :disabled='!(entryName.length>0)' style="width: 72%;" placeholder="" size="mini" v-model="form.companyId" @change="selectCompany">
                <el-option
                v-for="item in companyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"/>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('productSelection.CompetingGoodsModel')+':'" prop="competitorModel">
            <el-select :disabled='!(entryName.length>0)' style="width: 72%;" placeholder="" size="mini" v-model="form.competitorModel" >
                <el-option
                v-for="item in GoodsOptions"
                :key="item.id"
                :label="item.competitorName"
                :value="item.id"/>
            </el-select>
        </el-form-item>
        </div>
        <div class="footer">
            <el-form-item>  
            <!-- <el-button @click="resetForm" class="reset">Reset</el-button> -->
            <el-button type="primary" @click="submitForm" class="submit" :disabled='!(entryName.length>0)'>{{$t('productSelection.submit')}}</el-button>
            </el-form-item>
        </div>
   </el-form>
   <div class="form-header">
        <!-- <h2 class="form-h2">Here are the Recommended Models</h2>        -->
        <CreateSections :title="$t('productSelection.Models')" :noShowLine='true'/> 
        <div class="ProductCard" v-if='ProductCardList.length>0'>
            <div v-for="(item,key) in ProductCardList" :key="key" style="margin-right: 70px;">
                <!--  -->
            <el-tooltip :key="key"  class="item" effect="light" :disabled='!item.productDesc'  :content="item.productDesc"  placement="right">
                <ProductCard @addShoppingCart='addShoppingCart' @SaveSelectionResults='SaveSelectionResults' :indexForm='indexForm' :item="item" :length='ProductCardList.length'  :index='key' @showDetails='showDetails' @getDetails='getDetails'/>
            </el-tooltip>
            </div>
        </div>
         <div class='nodata' v-else-if='!showNoneTips' v-loading='loading'>
              {{$t('productSelection.nodata')}}
          </div>
            <div class='nodata' v-else v-loading='loading'>
              {{$t('productSelection.showNoneTips')}}
          </div>
        </div>
        <Details class="details" v-if="showDetail||Detail" :indexForm='indexForm' @addShoppingCart='addShoppingCart' :productDetails='productDetails' @closeDetails='closeDetails' @SaveSelectionResults='SaveSelectionResults'/>
</div>
</template>

<script>
import ProductCard from './productCard.vue'
import CreateSections from "@c/CreateSections"
import Details from './details'
import { getCompetitorProduct,getProductList,getDetail } from '@/api/productSelection'
export default {
    name:'Competitor',
    components: {
        ProductCard,CreateSections,Details
    },
    props: {
        entryName:{
            type:String,
            default:''
        },
        Detail:{
            type:Boolean,
            default:false
        },
        indexForm:{
            type:Object,
            default:{},
            require:true
        },
        showComponent:{
            type:Boolean,
            default:true,
            required:true
        },
        details:{
            type:Object,
            default:{},
            require:true
        },
        cartList:{
            type:Array,
            default:()=>{
                return []
            }
        },
        clearForm:{
            type:Boolean,
            default:false,
            required:true
        },
    },
    data () {
      return {
        form:{},
        loading:false,
        showDetail:false,
        ProductCardList:[],
        showNoneTips:false,
        rules:{
            companyId:[
                { required: true, message: this.$t('productSelection.notAllowed'), trigger: 'change' }
            ],
            competitorModel:[
                { required: true, message:  this.$t('productSelection.notAllowed'), trigger: 'change' }
            ],
        },
        companyOptions:[
            {
                    value: '1',
                label: 'Fortinet'
            },{
                    value: '2',
                label: 'Sophos'
            }
        ],
        GoodsOptions:[]
      }
    },
     watch:{
        showComponent:{
            handler(val){
             if(val==false){
            this.form = {}
            this.$nextTick(()=>[
               this.$refs.form.clearValidate(),//清除校验
               this.ProductCardList = []
            ])
             }
         },
            deep:true
        },
        details(){
            this.showDetails(this.details)
        },
        entryName(val){
            if(val.length==0){
                // this.form = {}
                // this.$nextTick(()=>[
                //     this.$refs.form.clearValidate(),//清除校验
                    this.ProductCardList = []
                // ])
            }
                
        },
        clearForm(){
            this.form = {}
            this.$nextTick(()=>[
                this.$refs.form.clearValidate(),//清除校验
                this.ProductCardList = []
            ])
        },
        'form.competitorModel'(){
            this.ProductCardList=[]
        },
        'form.competitorModel'(){
            this.ProductCardList=[]
        },
    },
    methods: {
        //提交
        submitForm(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true
                let params = {
                    'entryName': this.indexForm.entryName,
                    'recommendType': this.indexForm.recommendType,
                    'competitorModel': this.form.competitorModel,
                    'competitorCompany' : this.form.companyId
                }
                getProductList(params).then(res=>{
                    if(res.code==0){
                            this.ProductCardList = res.rows
                            this.loading = false
                            this.showNoneTips = false
                        }
                })
                }
            })
        },
        getDetails(){
            this.$emit('getDetails')
        },
        showDetails(val){
            getDetail(val.productId).then(res=>{
                if(res.code == 0){
                this.showDetail=true
                this.productDetails = res.rows
                this.productDetails.sceneList = val.sceneList
                }
            })
            this.$emit('pageScroll',this.showDetail)
        },
        closeDetails(){
            this.showDetail=false
            this.Detail = false
            this.$emit('pageScroll',this.showDetail)
        },
        //选择公司
        selectCompany(val){
            // this.form.competitorModel = ''
            this.$set(this.form,'competitorModel','')
            this.$nextTick(()=>[
               this.$refs.form.clearValidate(),//清除校验
            ])
            getCompetitorProduct(val).then(res=>{
                if(res.code==0){
                    this.GoodsOptions = res.rows
                }
            })
        },
         //加入购物车
        addShoppingCart(params){
            this.$emit('addShoppingCart', params)
        },
        //保存选型结果
        SaveSelectionResults(params){
            this.$emit('SaveSelectionResults',params)
        }
    }
}
</script>
<style lang="less" scoped>
  .competitor{
    height: 100%;
    /deep/ .el-form{
           border-bottom: 1px solid #CED4DA;
           margin-bottom: 40px;
           margin-top: 36px;
           .el-form-item{
                display: flex;
                flex-direction: column;
                padding-left: 18px;
                 .el-form-item__content{
                    margin-left: 0 !important;
                    // width: 35%;
                    .notice{
                        font-size: 12px;
                        font-family: FZLanTingHeiS;
                        font-weight: 400;
                        line-height: 26px;
                        color: #F46A6A;
                        opacity: 1;
                        margin-left: 7px;
                     }
                 }
           }
           .throughputRequired{
             display: flex;
                     .el-form-item{
                        //  flex: 1;
                        width: 50%;
                     }
           }
           .footer{
                text-align: center;
                .reset{
                    border: 1px solid #CED4DA;
                    opacity: 1;
                    border-radius: 2px;
                    color: #A1A4B4;
                }
                .submit{
                    border: 1px solid #556EE6;
                    opacity: 1;
                    border-radius: 2px;
                    background-color: #556EE6;
                    margin-left: 90px;
                } 
            }
    }
    .details{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
    .ProductCard{
                    display: flex;
                }
  }
  .nodata{
    width: 100%;
    display: flex;
    // text-align: center;
    justify-content: center;
    height: 80px;
    align-items: center;
}
</style>