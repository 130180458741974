<template>
  <div class="upgradeDialog">
    <el-dialog
      :visible="upgradeDialogAction"
      width="40%"
      :modal="false"
      @close="closeUpgradeDialog"
      :close-on-click-modal="false"
      center
    >
      <div class="formModule">
        <el-form :model="upgradeForm" :rules="upgradeRules" ref="upgradeForm">
          <el-form-item :label="$t('createcase.escalationReasons')" prop="upgradeReason">
            <el-radio-group v-model="upgradeForm.upgradeReason">
              <el-radio v-for="val in upgradeReasonData" :key="val.label" :label="val.label">{{val.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('createcase.detailReason')" prop="detailReason">
            <el-input
              type="textarea"
              v-model="upgradeForm.detailReason"
              resize="none"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeUpgradeDialog" size="small">{{$t('case.cancel')}}</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="UpgradeLoading"
          @click="handleUpgrade('upgradeForm')"
          >{{$t('case.submit')}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { upgradeWorkOrder } from "../../../api/case.js";
export default {
  name: "upgradeDialog",
  props: {
    upgradeDialogAction: {
      type: Boolean,
      require: true,
      default: false,
    },
    upgradeCaseNo: {
      type: String,
      require: true,
      default: "",
    },
  },
  data() {
    var checkdetailReason = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('case.detailReason')))
      }
      setTimeout(() => {
        if (value.length<1000) {
          callback()
        } else {
          callback(new Error(this.$t('case.detailReason2')))
        }
      }, 200)
    }
    return {
      upgradeForm: {
        caseId: "",
        upgradeReason: "",
        detailReason: "",
      },
      upgradeReasonData:[
        {label:1,name:'Business effected.',},
        {label:2,name:'No solution.',},
        {label:3,name:'Bad attitude.',},
        {label:4,name:'Difficulty/unable to coordinate with the third-party vendor.',},
        {label:5,name:'Unsatisfied with the provided resolution/suggestion/action.',},
        {label:6,name:'Technical ability needs to be improved.',},
        {label:7,name:'New important feature request for resolution.',},
        {label:8,name:'Others.',},
      ],
      // upgradeDialog: false,
      UpgradeLoading:false,
      upgradeRules: {
        upgradeReason: [
          { required: true, message: this.$t('case.upgradeReason'), trigger: "change" },
        ],
        detailReason: [
          { required:true, validator: checkdetailReason, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //提交升级工单
    handleUpgrade(upgradeForm) {
      this.$refs[upgradeForm].validate((valid) => {
        if (valid) {
          this.UpgradeLoading=true;
          upgradeWorkOrder(this.upgradeForm).then((res) => {
            if(res.code==0){
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.UpgradeLoading=false;
              this.closeUpgradeDialog()
              this.$emit("upgraded", true);
            }else if(res.code==500){
              this.$message({
                message: this.$t('case.upgradeErro'),
                type: "error",
              });
              this.UpgradeLoading=false;
            }
          }).catch(()=>{
            this.UpgradeLoading=false;
          });
        }
      });
    },
    //关闭
    closeUpgradeDialog() {
      this.$emit("showUpgradeDialog", false);
    },
  },
  created() {
    this.upgradeForm.caseId = this.upgradeCaseNo;
  },
};
</script>

<style scoped lang="less">
/deep/ .el-form-item__label {
  width: 100%;
  text-align: left;
  font-weight: 600;
}
/deep/ .el-radio{
  width: 100%;
  padding: 5px;
}
/deep/ .el-dialog--center .el-dialog__body {
  padding: 25px 25px 10px;
}
/deep/ .el-textarea__inner {
  height: 100px;
  font-family: 'MicrosoftYaHei', 'Avenir', Helvetica, Arial, sans-serif;
}
</style>