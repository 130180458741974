<template>
  <span class="title">{{title}}</span>
</template>

<script>
export default {
    name:'Card',
    props: {
        title:{
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="less" scoped>
    .title{
        // width: 93px;
        height: 34px;
        display: flex;
        padding: 10px 16px 11px 11px;
        align-items: center;
        justify-content: center;
        background: #556EE6;
        opacity: 1;
        border-radius: 0px 18px 18px 0px;
        color: #FFFFFF;
    }
</style>