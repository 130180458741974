<template>
  <div>
    <div class="crm-container">
      <flexbox class="th-container"
               style="padding: 0">
        <el-date-picker value-format="yyyy-MM-dd"
                        v-model="approvalForm.time"
                        unlink-panels
                        type="daterange"
                        size="small"
                        style="width: 300px"
                        :start-placeholder="$t('approval.a9')"
                        :end-placeholder="$t('approval.a10')"
                        @change="handleChange"></el-date-picker>
        <el-input size="small"
                  v-model="approvalForm.flowNo"
                  style="width: 200px; margin-left: 10px"
                  clearable
                  :placeholder="$t('approval.a8')"
                  class="filter-item"
                  @change="handleChange" />
        <el-button v-if="selectionList.length"
                   type="danger"
                   size="mini"
                   @click="deleteDraft"
                   style="margin-left: 10px">{{ $t("components.c7") }}</el-button>
      </flexbox>
      <el-table v-loading="loading"
                id="crm-table"
                ref="crmTable"
                :data="list"
                :height="tableHeight"
                :cell-style="cellStyle"
                class="n-table--border"
                stripe
                border
                highlight-current-row
                style="width: 100%"
                @row-click="handleRowClick"
                @sort-change="sortChange"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="50"
                         fixed
                         style="text-align: center"></el-table-column>
        <el-table-column v-for="(item, index) in fieldList"
                         :key="index"
                         :prop="item.fieldName"
                         :label="item.name + '&' + item.fieldCode"
                         :width="item.width"
                         :formatter="fieldFormatter"
                         show-overflow-tooltip>
          <template slot="header"
                    slot-scope="scope">
            <div class="table-head-name">
              {{ scope.column.label && scope.column.label.split("&")[0] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column />
      </el-table>
      <div class="p-contianer">
        <el-pagination :current-page="pageNumber"
                       :page-sizes="pageSizes"
                       :page-size.sync="pageSize"
                       :total="total"
                       class="p-bar"
                       layout="total, sizes, prev, pager, next, jumper"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange" />
      </div>
    </div>
    <!-- 相关详情页面 -->
    <c-r-m-all-detail :visible.sync="showAllDview"
                      :crm-type="rowType"
                      :id="rowID"
                      :row="row"
                      @handle="handleHandle" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import table from '@v/mixins/table'
import CRMTableHeader from '@c/CRMTableHeader.vue'
import FieldsPover from '@c/fieldsManager/FieldsPover.vue'
import { deleteMyDraft } from '@/api/common'
export default {
  name: 'forMyDraftlIndex',
  components: {
    CRMTableHeader,
    FieldsPover,
  },
  mixins: [table],
  data() {
    return {
      crmType: 'MyDraft',
      crmName: 'MyDraft',
      approvalForm: {
        time: [moment().subtract(3, 'Q').format(), moment().format()],
        flowNo: '',
      },
      form: {},
      flow_status: [
        { name: this.$t('filter.f79'), value: '2' },
        { name: this.$t('filter.f80'), value: '3' },
        { name: this.$t('filter.f81'), value: '5' },
      ],
    }
  },
  computed: {
    ...mapGetters(['dict']),
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.approvalForm))
  },
  methods: {
    /** 通过回调控制style */
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'flowNo') {
        return { color: '#3E84E9', cursor: 'pointer' }
      } else {
        return ''
      }
    },
    handleChange() {
      this.form = _.cloneDeep(this.approvalForm)
      if (this.approvalForm.time) {
        this.form.applyDateStart = moment(this.approvalForm.time[0]).format()
        this.form.applyDateEnd = moment(this.approvalForm.time[1]).format()
      }
      this.getList('refresh')
    },
    deleteDraft() {
      this.$confirm(this.$t('other.13'), this.$t('task.57'), {
        confirmButtonText: this.$t('common.btn.sure'),
        cancelButtonText: this.$t('common.btn.cancel'),
        type: 'warning',
      })
        .then(() => {
          const deleteList = this.selectionList.map((item) => {
            return { id: item.id, type: item.flowName, applicationType: 'PP' }
          })
          deleteMyDraft(deleteList)
            .then((res) => {
              this.$message({
                type: 'success',
                message: this.$t('sceneSet.9'),
              })
              // this.$emit("handle", { type: type });
              this.getList()
            })
            .catch((err) => {
              this.$message({
                type: 'success',
                message: this.$t('sceneSet.11'),
              })
            })
          // let borrowArr = [],
          //   authorization = [];
          // this.selectionList.map((item) => {
          //   if (
          //     item.flowName == "Equipment Rental" ||
          //     item.flowName == "PP Equipment Rental"
          //   ) {
          //     borrowArr.push(item);
          //   } else if (
          //     item.flowName == "PP Apply License" ||
          //     item.flowName == "PP Renew License"
          //   ) {
          //     authorization.push(item);
          //   }
          // });
          // let borrowPromise, authorizationPromise;
          // if (borrowArr.length) {
          //   borrowPromise = new Promise((resolve, reject) => {
          //     resolve();
          //   })
          //     .then(() => {})
          //     .catch(() => {});
          // }
          // if (authorization.length) {
          // }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
.th-container {
  font-size: 13px;
  height: 50px;
  padding: 0 20px;
}
</style>