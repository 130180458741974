<template>
  <div class="record">
      <h2 class="title">{{$t('productSelection.Customization')}}</h2>
      <div class="recordTitle">
          <div class="first">
            <el-checkbox v-model="allChecked">{{$t('productSelection.selectAll')}}</el-checkbox>
            <div class="sort">
                <span v-if="sortShow" @click="sort(1)" style="line-height:18px;display:flex;">{{$t('productSelection.Forward')}}<span class="sorticon"><i class="el-icon-caret-top "></i><i class='el-icon-caret-bottom light'></i></span></span>
                <span v-if="!sortShow" @click="sort(2)" style="line-height:18px;display:flex;">{{$t('productSelection.Reverse')}}<span class="sorticon"><i class="el-icon-caret-top light"></i><i class='el-icon-caret-bottom'></i></span></span>
            </div>
            <el-checkbox  v-model="expandAll" class="expandAll">{{$t('productSelection.ExpandAll')}}</el-checkbox>
          </div>
          <div class="input">
              <el-input
            :placeholder="$t('productSelection.inputText')"
            size="medium"
            v-model.trim="entryName"
            maxlength="500"
            @keyup.enter.native="seachEntry">
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
            <el-button slot="append" icon="el-icon-search" @click="seachEntry"></el-button>
        </el-input>
          </div>
          
        <div class="button">
             <el-button :disabled='!downloadList.length>0'  type="primary" icon='el-icon-download' size="medium" @click="downloadBOM">{{$t('productSelection.downloadBOM')}}</el-button>
             <el-popconfirm
                :title="$t('sceneCreate.32')"
                @confirm='productBatchRemove'
                >
            <el-button v-show='ids.length>0' :title="$t('productSelection.deleteProjectTips')" slot="reference" type="danger" size="medium" icon='el-icon-delete' >{{$t('common.btn.delete')}}</el-button>
            </el-popconfirm>
        </div>
        
      </div>
      <div v-loading='loading' style="height:500px;width: 100%;display:flex;align-items: center;justify-content: center;" v-if="!productList.length>0">
              {{$t('productSelection.nodata')}}
        </div>
      <div  v-loading='loading'  v-else  class="tableClass">
          <el-scrollbar>
              <ProductTable :clearSelect='clearSelect' :clearItem='clearItem' @hasallChecked='hasallChecked'  :expandAll="expandAll"  @getConfigList='getConfigList' @productRemove='productRemove' @ItemChange='ItemChange' :hasDelete='hasDelete' :list='item' :allChecked='allChecked'  v-for="(item,index) in productList" :key="index" :index="index" :showBtn='showBtn'/>
          </el-scrollbar>       
      </div>
      <div class="footer">
         <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pageMsg.pageNo"
            :page-size="10"
            layout="total,prev, pager, next, jumper"
            :total="pageMsg.total">
    </el-pagination>
         
      </div>
      
<el-dialog
  :title="$t('productSelection.PreviewFormat')"
  :visible.sync="dialogVisible"
  top='7vh'
  width="50%"
  class="Preview"
  @close='dialogVisible = false'>
  <span class="tips">{{$t('productSelection.PreviewNotice')}}</span>
  <div>
      <img src="../../../../assets/img/previewBOM.png" alt="">
      <span class="watermark">Sangfor</span>
  </div>
  
  <span slot="footer" class="dialog-footer">
      <div class="prompt">
          <el-checkbox v-model="checked">{{$t('productSelection.prompt')}}</el-checkbox>
      </div>
      
      <div class="button">
           <el-button @click="download" icon="el-icon-download">{{$t('productSelection.download')}}</el-button>
      </div>
   
  </span>
</el-dialog>
<DeviceConfiguration   class="DeviceConfiguration"  @clearDownload='clearDownload' :product='product' :queryList='queryList'  :centerDialogVisible='centerDialogVisible'  @handle='handle' :productData='productData'/>
  </div>
  
</template>

<script>
import ProductTable from './productTable.vue'
import DeviceConfiguration from './deviceConfiguration.vue'
import { configList,getDetailList,noTips } from '@/api/selectionOfRecord.js'
export default {
    name:'Record',
    props: {
        productList:{
            type:Array,
            default:[]
        },
        loading:{
            type:Boolean,
            default:true
        },
        //选择的项目数组
        ids:{
            type:Array,
            default:[]
        },
        pageMsg:{
            type:Object,
            default:{}
        },
        downloadList:{
            type:Array,
            default:[]
        }
    },
    components: {
        ProductTable,DeviceConfiguration
    },
    data () {
        return {
            showBtn:true,
            sortShow:true,
            dialogVisible:false,
            expandAll:false,
            checked:false,
            hasDelete:false,
            allChecked:false,
            cartItem:[],
            entryName:'',
            centerDialogVisible:false,
            productData:{},
            product:{},
            queryList:{},
            productId:'',
            tips:'',
            clearSelect:false,
            clearItem:false
        }
    },
    watch: {
      expandAll(val){
      },
      allChecked(val){
              this.$emit('clearIdList')
              this.$emit('showChecked',val)
      },
      loading(){
          
      }
    },
    created () {
        this.noTips()
        this.checked=false
    },
    methods: {
        sort(val){
            this.clearItem = !this.clearItem
            this.sortShow=!this.sortShow
            this.$emit('sort',val)
        },
        downloadBOM(){
            this.noTips()
          setTimeout(() => {
            if(this.tips==1){
                this.$emit('exportBom',this.checked)
                this.tips=2
            }else{
                this.dialogVisible=true
            }
          }, 500);
            
        },
        download(){
            this.$emit('exportBom',this.checked)
            this.dialogVisible = false
        },
        productRemove(val){
            this.clearSelect = !this.clearSelect
            this.$emit('productRemove', val)
        },
        ItemChange(val){
            this.$emit('ItemChange', val)
        },
        clearDownload(val){
            this.$emit('clearDownload')
        },
        productBatchRemove(){
            this.hasDelete = true
            this.$emit('productBatchRemove')
        },
        //分页
        handleCurrentChange(val){
            this.allChecked = false
            this.expandAll = false
            this.clearSelect = !this.clearSelect
            this.$emit('handleRecordCurrentChange', val)
        },
        //项目搜索
        seachEntry(){
            this.allChecked = false
            this.expandAll = false
            this.clearSelect = !this.clearSelect
            this.$emit('seachEntry', this.entryName)
        },
        // showChecked(val){
        //     this.$emit('showChecked',val)
        // },
         handle(val,type){
          switch(val){
              case 'cancel':
              this.centerDialogVisible =type
            //   this.allChecked = false
            //   this.clearSelect = !this.clearSelect
            //   this.$emit('getList')
              break;
              case 'submit':
              this.allChecked = false
              this.clearSelect = !this.clearSelect
              this.clearItem = !this.clearItem
              this.centerDialogVisible =type
              this.$emit('getList')
              break;
          }
      },
        getConfigList(val){
            //productName
            this.product = val
            this.productId =val.id
            
            //配置
            let params = {
                code: val.code
            }
            let query = {
              id:val.id
          }
          
         getDetailList(query).then(res=>{
             if(res.code == 0){
                 this.queryList = res.rows
                    configList(params).then(res=>{
                    if(res.code == 0){
                        this.productData = res.rows
                        this.centerDialogVisible = true
                        
                    }
                })
             }
         })
          
        },
        //提示
        noTips(){
            noTips().then(res=>{
                if(res.code == 0){
                this.tips = res.rows.msg
                }
            })
        },
        hasallChecked(val){
            this.$emit('showChecked',val)
        }
        
    }
}
</script>

<style lang="less" scoped>
    .record{
        width: 100%;
        // overflow: auto;
        .title{
                font-size: 18px;
                font-family: FZLanTingHeiS-DB-GB;
                // font-weight: 400;
                line-height: 21px;
                color: #343A40;
                opacity: 1;
                }
        .recordTitle{
            display: flex;
            margin-bottom: 12px;
            margin-top: 20px;
            .first{
                flex: 1;
                display: flex;
                align-items: center;
                
                .sort{
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 600;
                    line-height: 20px;
                    color: #74788D;
                    opacity: 1;
                    margin-left: 49px;
                    cursor: pointer;
                    display: flex;
                    .sorticon{
                        display: flex;
                        flex-direction: column;
                        i{
                            height: 7px;
                            width: 8px;
                        }
                    }
                }
                /deep/.expandAll{
                    margin-left: 46px;
                }
            }
            .input{
                flex: 1;
                text-align: center;
                /deep/.el-input{
                    width: 70%;
                    .el-input__inner{
                    // background: #F3F3F9;
                    opacity: 1;
                    // border-radius: 19px;
                    }
                   
                }
            }
            .button{
                flex:1;
                text-align: end;
                /deep/.el-button{
                    // border: 1px solid #F46A6A;
                    opacity: 1;
                    border-radius: 2px;
                }
                span{
                    // .el-popover__reference-wrapper{
                    margin-left: 10px;
                // }
                }
                
            }
        }
        .footer{
            display: flex;
            justify-content: space-between;
            border-top:  1px solid #CED4DA;
            opacity: 1;
            padding-top: 24px;
            justify-content: flex-end;
        }
    }
// .light{
//     background: #556EE6;
// }
.el-icon-caret-top{
    color: #CED4DA;
}
.el-icon-caret-bottom{
    color: #CED4DA;
}
.light{
    color: #556EE6;
}
/deep/.el-dialog__header{
    background-color: #FFFFFF;
    
}
/deep/ .el-dialog__body{
    img{
        width: 100%;
        height: 70%;
    }
}
.Preview{
    /deep/ .el-dialog__footer{
    border-top: none;
    text-align: left;
    display: flex;
    .dialog-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .button{
            flex: 1.5;
        .el-button{
        background: #556EE6;
        color: #FFFFFF;
        }
        }
        .prompt{
        flex: 1;
    .el-checkbox{
            margin-left: 12px;
        }
    }
    }
    }
}
.expandAll{
    font-weight: 600;
line-height: 20px;
color: #74788D;
font-size: 14px;
}
.watermark{
    position: absolute;
    left: 50%;
    font-size: 5.625rem;
    color: rgba(203, 200, 200, 0.5);
    top: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
}
.tableClass{
    height: 36.875rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 99;
}
.tableClass::-webkit-scrollbar{
    display: block !important;
}
.tableClass::-webkit-scrollbar-track{
    display: block !important;
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>