<template>
  <div class="wrapper">
    <div class="left">
      <div class="left-info">
        <p class="title">Sangfor Partner Portal</p>
        <p class="content">
          Sangfor Technologies Inc. ("Sangfor") welcomes you. The Sangfor
          Partner Portal, developed by Sangfor, is provided to enable users to
          manage own Sangfor assets, view assets status, activate and update
          assets.
        </p>
      </div>
    </div>
    <div class="right">
      <div class="header">
        <div>
          <img src="@/assets/img/logo-en.png" alt style="width: 100px" />
        </div>
        <div class="internationalization">
          <span :class="{ activaLang: internationalLang == 'CN' }" @click="handleCommand('CN')">中文</span>
          <div class="interval"></div>
          <span :class="{ activaLang: internationalLang == 'EN' }" @click="handleCommand('EN')">English</span>
        </div>
      </div>
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" label-position="left">
        <div class="form-logo">
          <img src="@/assets/img/logo.png" alt />
          <p>Partner Portal</p>
        </div>
        <el-menu default-active="userId" mode="horizontal" class="login-form-menu" text-color="#495060"
          active-text-color="#0088F4">
          <el-menu-item index="userId">{{ $t("login.userId") }}</el-menu-item>
        </el-menu>
        <div>
          <el-form-item prop="username" style="margintop: 20px">
            <div slot="label" style="display: inline-block; color: #7a7b79">
              {{ $t("login.username") }}
            </div>
            <el-input ref="name" v-model="loginForm.username" auto-complete="on" name="username" type="text"
              :placeholder="$t('login.usernamePlaceholder')" @keyup.enter.native="handleLogin">
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <div slot="label" style="display: inline-block; color: #7a7b79">
              {{ $t("login.password") }}
            </div>
            <el-input v-model="loginForm.password" type="password" name="password" auto-complete="new-password"
              :placeholder="$t('login.passwordPlaceholder')" @keyup.enter.native="handleLogin">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="Verification Code"
                        prop="VerificationCode"
                        class="validCode">
            <el-input class="input" v-model="loginForm.VerificationCode"
                      @keyup.enter.native="submitForm('loginForm')">
              <template slot="append">
                <img :src="validCodeUrl"
                     alt="Code"
                     @click="getValidCode">
              </template>
            </el-input>
            <!-- <span class="message-label">Please click to change another captcha.</span> -->
          </el-form-item>
        </div>
        <el-form-item>
          <el-button style="
              width: 100%;
              font-size: 16px;
              line-height: 2;
              border-radius: 4px;
            " :loading="loading" size="mini" type="primary" @click.native.prevent="handleLogin">
            {{ $t("login.signIn") }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters
  } from "vuex";

  export default {
    name: "Login",
    data() {
      const validateUsername = (rule, value, callback) => {
        if (value.length == 0) {
          callback(new Error("请输入账号"));
        } else {
          callback();
        }
      };
      const validatePass = (rule, value, callback) => {
        if (value.length < 5) {
          callback(new Error("密码不能小于5位"));
        } else {
          callback();
        }
      };
      return {
        loading: false,
        loginForm: {
          username: "",
          password: "",
          VerificationCode: "",
          deviceId: ''
        },
        loginRules: {
          username: [{
            required: true,
            trigger: "change"
          }],
          password: [{
            required: true,
            trigger: "change"
          }],
        },
        remember: false,
        internationalLang: "EN",
        validCodeUrl: '',
      };
    },
    computed: {},
    watch: {},
    mounted() {
      this.getValidCode()
      if (localStorage.getItem("lang")) {
        this.internationalLang = localStorage.getItem("lang");
      } else {
        this.internationalLang = "EN";
        localStorage.setItem("lang", "EN");
      }
    },
    methods: {
          //获取图形验证码
    getValidCode () {
      let num = "";
      for (let i = 0; i < 4; i++) {
        num += parseInt(Math.random() * 10);
      }
      this.loginForm.deviceId = num;
      this.validCodeUrl =
        process.env.VUE_APP_BASE_URL + "api-auth/validate/createCode/" + num;
    },
      handleCommand(val) {
        if (this.internationalLang == val) return;
        this.internationalLang = val;
        if (val == "CN") {
          this.$i18n.locale = "CN";
        } else {
          this.$i18n.locale = "EN";
        }
        localStorage.setItem("lang", this.$i18n.locale);
      },
      handleLogin() {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            const AES_KEY = "eaA8eBa7EfeMfcfZ";
            const IV = "TRYTOCN394402133";

            function encrypt(params) {
              var key = CryptoJS.enc.Utf8.parse(AES_KEY);
              var iv = CryptoJS.enc.Utf8.parse(IV);
              var jsonData = JSON.stringify(params);
              //下面的data参数要求是一个字符串，第一次用的时候我直接传递的是一个对象，出现了错误，要转换成字符串
              var data = CryptoJS.enc.Utf8.parse(jsonData);
              var encrypted = CryptoJS.AES.encrypt(data, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
              }).toString();
              return encrypted;
            }
            let field = {};
            field.loginCode = encrypt({
              username: this.loginForm.username,
              password: this.loginForm.password,
            });
            field.codes = '1111'
            field.deviceId = this.loginForm.deviceId
            this.$store
              .dispatch("Login", field)
              .then((res) => {
                this.loading = false;
                var url = "http://" + location.host + "/";
                location.href = url;
              })
              .catch((res) => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  @dark_gray: #ccc;
  @light_gray: #333;
  @login_theme: #00aaee;

  .el-input,
  .el-select {
    font-size: 14px;
  }

  /deep/ .checkbox .el-checkbox__label {
    color: #333;
  }

  /deep/ input {
    border: 0 none;
    background-color: white;
    -webkit-appearance: none;

    &:-webkit-autofill {
      background-image: none;
      box-shadow: 0 0 0 1000px white inset !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      -webkit-text-fill-color: @light_gray  !important;
    }
  }

  /deep/ .el-input__inner {
    height: 40px;
    // padding: 0 12px;
    background-color: white;
    border: 0 none;
    border-bottom: 1px solid #e6e6e6 !important;
  }

  /deep/ .el-form-item__error {
    left: 12px;
  }

  /deep/ .el-dialog__body {
    padding: 0px 20px;
  }

  .wrapper {
    position: relative;
    width: 100%;
    min-width: 1024px;
    display: flex;
    width: 100%;
    height: 100%;
    background: url("./login.jpg") no-repeat center;
    background-size: cover;

    .left {
      width: 37%;

      .left-info {
        margin-top: 25%;
        margin-left: 150px;

        .title {
          padding: 0 0 10px;
          border-radius: 4px;
          color: hsla(0, 0%, 100%, 0.993);
          position: relative;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 700;
          font-size: 34px;
        }

        .content {
          opacity: 0.6;
          padding: 0 0 10px 150;
          border-radius: 4px;
          color: hsla(0, 0%, 100%, 0.993);
          position: relative;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 16px;
          text-align: justify;
          line-height: 30px;
        }
      }
    }

    .right {
      position: relative;
      width: 600px;
      height: 98%;
      background-color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 4px;
      overflow: auto;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 20px;

        .internationalization {
          display: flex;

          .activaLang {
            color: #0088f4;
          }

          span {
            height: 16px;
            font-size: 12px;
            color: #cccccc;
            line-height: 16px;
            font-weight: 400;
            cursor: pointer;
          }

          .interval {
            width: 1px;
            height: 16px;
            margin: 0 16px;
            background: #cccccc;
          }
        }
      }

      .el-form {
        width: 400px;
        margin-top: 160px;

        .form-logo {
          position: absolute;
          top: 120px;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;

          img {
            // width: 44px;
            height: 44px;
          }

          p {
            font-size: 22px;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 700;
            color: #807d7d;
            margin-top: 5px;
            margin-bottom: 40px;
          }
        }

        .el-form-item {
          margin-bottom: 25px;
        }

        .el-menu {
          border-bottom: none;
          height: 40px;
          display: flex;
          justify-content: center;

          .el-menu-item {
            width: 50%;
            height: 100%;
            line-height: 40px;
            border-bottom: none;
            text-align: center;
            font-size: 14px;

            // &:hover {
            //   background-color: #f7f9fc !important;
            //   color: #0088f4;
            // }
            &.is-active {
              &::after {
                content: "";
                width: 32px;
                height: 1px;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                border-bottom: 3px solid #0088f4;
                transition: width 0.5s;
              }

              &:hover::after {
                width: 50px;
              }
            }
          }
        }

        .title {
          font-size: 40px;
          color: #0088f4;
          margin: 0 auto 25px;
          font-weight: bolder;
          text-align: center;
        }

        .small_title {
          font-size: 28px;
          color: #0088f4;
          margin-bottom: 20px;
        }

        .submit-btn {
          width: 100%;
          line-height: 2;
          font-size: 16px;
          color: white;
          border-radius: 3px;
          background-color: @login_theme;
          display: block;
        }

        .el-button {
          border: 0 none;
        }

        .action-control {
          color: #999;

          /deep/ .el-checkbox {
            .el-checkbox__label {
              color: #999;
            }

            .el-checkbox__input.is-checked .el-checkbox__inner {
              background-color: @login_theme;
              border-color: @login_theme;
            }
          }

          .forget {
            cursor: pointer;
            float: right;
          }
        }
      }

      .register {
        width: 70%;
        padding-top: 30px;
        color: @light_gray;
        border-top: 1px solid #e6e6e6;
        text-align: center;
        margin-top: 28px;

        .register-btn {
          color: @login_theme;
          cursor: pointer;
        }
      }

      .copyright {
        width: 92%;
        position: absolute;
        bottom: 2%;
        color: #d0d0d0;
        font-size: 12px;
        text-align: center;
        line-height: 1.5;
      }
    }

    .logo {
      position: absolute;
      left: 60px;
      top: 50px;

      z-index: 200;
    }
  }
</style>