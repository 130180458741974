<template>
    <el-dialog
  :title="$t('productSelection.configuration')"
  :visible="centerDialogVisible"
  width="70%"
  class="quote"
  @close="close('cancel')"
  v-dialogDrag>
  
    <el-form ref="form" :model="queryList" label-width="120px" :rules="rules">
<div class="dialogTitle">
  <el-form-item :label="$t('productSelection.ProductModel')+':'">
    <el-input size="medium" v-model="queryList.productName" disabled></el-input>
  </el-form-item>
     <el-form-item :label="$t('productSelection.EquipmentSets')+':'" prop="productQuantity">
    <el-input-number  :min="1" :max="999999999999"  step-strictly size="medium" controls-position="right" v-model="queryList.productQuantity" :step='1'   :maxlength="12"></el-input-number>
  </el-form-item>
  </div>
  <el-tabs
        v-model="activeName"
        tab-position="left"
        type="card"
        v-if="productData"
      >
        <el-tab-pane
          v-for="(item, index) in productData.children"
          :key="item.itemNameQuote"
          :name="index.toString()"
        >
          <div slot="label">
            <span>{{ item.itemNameQuote }}</span>
          </div>
           <div class="quote-tabs-content" v-if="activeName == index.toString()">
               <!-- :row-style="rowStyle" -->
            <el-table
              :data="item.children"
              height="450px"
              :tree-props="{children:'children',hasChildren:'booleanSale'}"
              row-key='uniqueId'
              v-loading="loading"
              border
              ref="eltable"
              @row-click="handleRowClick"
              @expand-change='cellClick'
            >
            <el-table-column
                :label="$t('detail.productName')"
                header-align="center"
                width="180"
                prop="invoiceNameInt"
              >
              <!-- <template slot-scope="scope">
              </template> -->
            </el-table-column>
            <el-table-column
                :label="$t('productSelection.productType')"
                header-align="center"
                width="140"
                prop="itemTypeName"
              >
            </el-table-column>
            <el-table-column
                :label="$t('productSelection.productDescription')"
                header-align="center"
                prop="productDesc"
              >
            </el-table-column>
            <el-table-column
                :label="$t('productSelection.PurchaseQuantity')"
                header-align="center"
                width="120"
                prop="purchaseQuantity"
                :key="tablekey"
              >
              <template slot-scope="scope">
                 <el-input-number :min="0"  step-strictly :precision="0" :max="scope.row.invoiceNameInt=='FSSLU-1'?10000:1" controls-position="right" :disabled='scope.row.itemType=="SOC" || scope.row.invoiceName=="STS"' v-model="scope.row.purchaseQuantity" @change="changeVal($event,scope.row.purchaseQuantity)"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
                :label="$t('productSelection.TaketheNumber')"
                header-align="center"
                width="120"
                prop="defaultQty"
              >
            </el-table-column>
            </el-table>
           </div>
        </el-tab-pane>
  </el-tabs>
</el-form>
  
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="handle('submit')">{{$t('productSelection.save')}}</el-button>
    <!-- <el-button type="primary" @click="handle('submit')">{{$t('productSelection.saveAdd')}}</el-button> -->
  </span>
</el-dialog>
</template>

<script>
import { saveOrUpdate } from '@/api/selectionOfRecord.js'
export default {
    props: {
        centerDialogVisible:{
            type:Boolean,
            require:true
        },
        productData:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        product:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        queryList:{
          type:Object,
            default:()=>{
                return {}
            }
        },
        obj:{
          type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data () {
        return {
            dialogForm:{},
            loading:false,
            activeName:'',
            options:[],
            value:'',
            rules:{
              productQuantity:[
                { required: true, message:  this.$t('productSelection.notAllowed'), trigger: ['change','bulr'] },
                { pattern:/^\d{1,12}?$/, message: this.$t('productSelection.configureAllowedMsg'), trigger: ['change','blur'] },
              ]
            },
            tablekey:false
        }
    },
    created () {
    },
    watch: {
      'productData'(val){
        if(val.children.length>0){
          val.children.forEach((item,index)=>{
             item.children.forEach((i,v)=>{
               if(this.queryList.serviceList.length>0){
                 this.queryList.serviceList.forEach((el,j)=>{
                     if(i.uniqueId == el.uniqueId){
                          i['purchaseQuantity'] = Number(el.purchaseQuantity)
                     }
                 })
               }
             })
          })
        }
      }
    },
    methods: {
      //close
      close(){
        this.$emit('handle','cancel',false)
      },
        handle(val){
           this.$refs['form'].validate((valid) => {
                if (valid) {
                  this.$emit('clearDownload','  ')
            let params = []
            this.productData.children.forEach(item=>{
              item.children.forEach(i=>{
                let obj
                  if(i.purchaseQuantity !== 0&&i.purchaseQuantity&&(i.purchaseQuantity >= 1)){
                    obj={
                      'productType':i.itemType,
                      'productTypeName':i.itemTypeName,
                      'productName':i.invoiceNameInt,
                      'uniqueId':i.uniqueId,
                      'productDesc':i.productDesc,
                      'purchaseQuantity':i.purchaseQuantity,
                      'defaultQuantity':i.defaultQty,
                      // 'bomProductName':i.invoiceNameInt
                    }
                    params.push(obj)
                  }
              })
            })
            let query={
              id:this.queryList.id,
              productQuantity:Number(this.queryList.productQuantity),
              serviceList:params
            }
            saveOrUpdate(query).then(res=>{
              this.$emit('handle',val,false)
            })
                }
           })
           
        },
        handleChange(){
            
        },
        handleRowClick(){
          
        },
        cellClick(row){
        },
        changeVal(val,purchaseQuantity){
          this[purchaseQuantity] = val
          this.tablekey = !this.tablekey
        }
    }

}
</script>

<style lang="less" scoped>
 /deep/.el-table .el-table__expand-icon {
     .el-icon-arrow-right:before {
       content: '\e7a0';
     }
   }
/deep/ .el-table__expand-icon{
    transform: none;
    
}
//箭头点击后会多出一个 .el-table__expand-icon--expanded  可以在这设置点击后的图标
/deep/ .el-table__expand-icon--expanded{
    .el-icon-arrow-right:before {
       content: '\e7a2' !important;
     }
}   
    /deep/.el-dialog{
        .el-dialog__body{
             
         .el-form{
             .dialogTitle{
             display: flex;
             justify-content: space-between;
             .el-form-item{
                 flex: 1;
                 .el-cascader{
                     width: 100%;
                 }
                 
                   .el-input-number{
                     width: 100%;
                 
                 }
                 
             }
         }
         .el-dialog__footer{
                display: flex;
                justify-content: center;
            //  .dialog-footer{
            //      display: inline-block;
            //      width: 100%;
             
            //  }
            
         }
        }   
    }
    
    }
    .dialog-footer{
        display: flex;
                justify-content: center;
    }
    .v-modal{
        display: none !important;
    }
  /deep/ .el-table__row{
    .cell{
      .el-input-number{
        width: 100%;
      }
    }
    }
    /deep/.el-tabs{
      padding-top: 2px;
    }
@media screen and (min-width: 1200px){
  .quote /deep/.el-dialog {
    max-height: 650px !important;
}
  .quote .el-tab-pane {
    height: 470px !important;
}
}
</style>