<template>
  <div>
    <div v-show="showcontent">
      <common-head :productOprions="productOption"
                   :statusOptions="statusOptions"
                   @handler-change="handlerChange"
                   :resetNum="resetNum"></common-head>
      <div class="table-head">
        <el-button size="medium"
                   @click="handleRefresh">
          <span class="btn-content">
            <img class="img"
                 src="@/assets/svg/refresh.svg"><span>Refresh</span>
          </span>
        </el-button>
        <el-button plain
                   size="medium"
                   @click="handleUnshare">
          <span class="btn-content">
            <img class="img"
                 src="@/assets/svg/unshare.svg"><span>Stop Sharing</span>
          </span>
        </el-button>
      </div>
      <common-table :tableHeadList="tableHeadList"
                    :tableData="tableData"
                    :isSelected="true"
                    :pageNumber="pageNumber"
                    :pageSize="pageSize"
                    :total="total"
                    :hasPagination="true"
                    @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange"
                    @getSelectDate="getSelectDate"
                    @rowClick="rowClick">
        <template v-slot:status="slot">
          <span :style="statusStyle[slot.scope.row.licStatus]">
            {{statusOptions[slot.scope.row.licStatus].name}}
          </span>
        </template>
        <template v-slot:operation="slot">
          <el-button plain
                     size="medium"
                     @click="handelUpdate(slot.scope.row)">{{
                   slot.scope.row.licStatus===0?'Activate':'Update'
                 }}</el-button>
          <el-dropdown trigger="click">
            <el-button :disabled="slot.scope.row.licStatus===0||slot.scope.row.licStatus===4"
                       size="medium"
                       plain>More
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleDownload(slot.scope.row.id)">Download License</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </common-table>
    </div>
    <device-details v-if="showDetail"
                    :detailsMsg="detailsMsg"
                    @hide-view="hideView"></device-details>
  </div>
</template>
<script>
import commonTable from '../components/commonTable.vue'
import CommonHead from '../components/commonHead.vue'
import DeviceDetails from '../components/deviceDetails.vue'
import {
  customerAssetsList,
  getProductTypeList,
  unshareDevice,
  downloadLicense,
} from '@/api/assets'
export default {
  name: 'customerAssetsIndex',
  components: {
    commonTable,
    CommonHead,
    DeviceDetails,
  },
  data() {
    return {
      tableHeadList: [
        {
          data: {
            label: 'Customer',
            prop: 'corpsName',
            toolTip: true,
          },
        },
        {
          data: {
            label: 'Serial Number',
            prop: 'sn',
          },
        },
        {
          data: {
            label: 'Gateway ID',
            prop: 'deviceId',
          },
        },
        {
          data: {
            label: 'Product',
            prop: 'productType',
          },
        },
        {
          data: {
            label: 'Model',
            prop: 'model',
          },
        },
        {
          slot: 'status',
          data: {
            label: 'Status',
            prop: 'licStatus',
            width: 140,
          },
        },
        {
          data: {
            label: 'Order No.',
            prop: 'orderId',
          },
        },
        {
          data: {
            label: 'Shared Time',
            prop: 'sharedTime',
          },
        },
        {
          data: {
            label: 'Added Time',
            prop: 'createTime',
          },
        },
        {
          data: {
            label: 'Updated Time',
            prop: 'updateTime',
          },
        },
        {
          data: {
            label: 'Expired Date',
            prop: 'softwareUpdateTime',
          },
        },
        {
          slot: 'operation',
          data: {
            label: 'Operation',
            prop: '',
            width: 250,
          },
        },
      ],
      tableData: [],
      productOption: [],
      statusOptions: [
        { id: 0, name: 'Inactivated' },
        { id: 1, name: 'Activated' },
        { id: 2, name: 'To be Updated' },
        { id: 999, name: '' },
        { id: 4, name: 'Expired' },
      ],
      statusStyle: {
        0: {
          background: '#F4A12D',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: 'inline-block',
        },
        1: {
          background: '#32C093',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: 'inline-block',
        },
        2: {
          background: '#007AFF',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: 'inline-block',
        },
        4: {
          background: '#79828C',
          color: '#fff',
          padding: '1px 10px',
          borderRadius: '25px',
          display: 'inline-block',
        },
      },
      shareMsg: {},
      detailsMsg: {
        show: false,
        row: {},
        toLicense: false,
      },
      showcontent: true,
      showDetail: false,
      searchData: {},
      resetNum: 0,
      pageNumber: 1,
      pageSize: 10,
      total: 0,
    }
  },
  created() {
    this.getCustomerAssetsList()
    this.getproductList()
  },

  methods: {
    //获取勾选表格数据
    getSelectDate(data) {
      this.shareMsg = data
    },
    //分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getCustomerAssetsList()
    },

    //
    handleCurrentChange(val) {
      this.pageNumber = val
      this.getCustomerAssetsList()
    },

    //获取列表
    getCustomerAssetsList(data) {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
      if (data) {
        params.createBeginTime =
          data.addedTime !== null && data.addedTime && data.addedTime.length > 0
            ? data.addedTime[0]
            : undefined
        params.createEndTime =
          data.addedTime !== null && data.addedTime && data.addedTime.length > 0
            ? data.addedTime[1]
            : undefined
        params.expiredBeginTime =
          data.expiredTime !== null &&
          data.expiredTime &&
          data.expiredTime.length > 0
            ? data.expiredTime[0]
            : undefined
        params.expiredEndTime =
          data.expiredTime !== null &&
          data.expiredTime &&
          data.expiredTime.length > 0
            ? data.expiredTime[1]
            : undefined
        params.licStatus = data.status !== '' ? data.status : undefined
        params.productType = data.productType
        params.searchName = data.searchName.replace(/\s*/g, '')
      }
      customerAssetsList(params).then((res) => {
        this.tableData = res.rows.rows
        this.total = res.rows.total
      })
    },
    //获取产品线
    getproductList() {
      getProductTypeList().then((res) => {
        this.productOption = res.rows
      })
    },
    //顶部筛选
    handlerChange(data) {
      this.searchData = JSON.parse(JSON.stringify(data))
      this.getCustomerAssetsList(data)
    },
    //重置刷新
    handleRefresh() {
      this.resetNum++
      this.getCustomerAssetsList()
    },

    //取消设备分享
    handleUnshare() {
      if (!this.shareMsg.row || !this.shareMsg.row.length) {
        this.$message({
          type: 'warning',
          message: 'Please make a selection before stopping sharing.',
        })
        return
      }
      this.$confirm('Confirm to unshare?', 'Hint', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          let params = this.shareMsg.row.map((item) => {
            return item.sharedId
          })
          unshareDevice(params).then((res) => {
            this.getCustomerAssetsList()
            this.$message({
              type: 'success',
              message: 'Unshare successfully',
            })
          })
        })
        .catch(() => {
          console.log(1)
        })
    },
    //更新
    handelUpdate(data) {
      this.detailsMsg.row = data
      this.detailsMsg.activeIndex = 2

      this.showDetail = true
      this.showcontent = false
    },

    //点击表格
    rowClick(data, column) {
      if (column.property === 'sn') {
        this.detailsMsg.row = data
        this.detailsMsg.activeIndex = 1

        this.showcontent = false
        this.showDetail = true
      }
    },
    //关闭弹窗回调
    hideView() {
      this.showDetail = false
      this.showcontent = true
    },
    //文件下载
    handleDownload(id) {
      let params = id
      downloadLicense(params).then((res) => {
        let fileName = res.headers['content-disposition']
          .split(';')[1]
          .split('=')[1]
        const a = document.createElement('a')
        const blob = new Blob([res.data])
        const objectUrl = URL.createObjectURL(blob)
        a.setAttribute('href', objectUrl)
        a.setAttribute('download', fileName)
        a.click()
        URL.revokeObjectURL(objectUrl)
      })
    },
  },
}
</script>
<style lang="less" scoped>
.table-head {
  margin: 12px 0;
  .btn-content {
    display: flex;
    align-items: center;
    .img {
      margin-right: 5px;
    }
  }
}
</style>