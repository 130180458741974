<template>
  <el-dialog
    :title="edit_id ? '编辑场景' : '新建场景'"
    :visible.sync="visible"
    :append-to-body="true"
    width="800px"
    @close="handleCancel"
    v-dialogDrag
  >
    <el-form
      :model="sceneCreateForm"
      :rules="rules"
      ref="sceneCreateForm"
      id="scene-filter-container"
      class="filter-container"
    >
      <div class="scene-name-container">
        <el-form-item prop="saveName">
          <div slot="label" style="display: inline-block">
            <div>{{ "场景名称" }}</div>
          </div>
          <el-input
            v-model="sceneCreateForm.saveName"
            :maxlength="30"
            size="medium"
            class="scene-input"
            :placeholder="''"
          />
        </el-form-item>
      </div>
      <div class="scene-name">{{ "筛选条件" }}</div>
      <el-form-item>
        <template v-for="(formItem, index) in form">
          <el-row :key="index" :gutter="30" style="width: 100%">
            <el-col :span="9">
              <el-select
                v-model="formItem.fieldName"
                filterable
                size="medium"
                :placeholder="'请选择要筛选的字段'"
                @change="fieldChange(formItem)"
              >
                <el-option
                  v-for="item in fieldList"
                  :key="item.fieldCode"
                  :label="item.name"
                  :value="item.fieldCode"
                />
              </el-select>
            </el-col>
            <el-col
              v-if="
                formItem.formTypeWeb !== 'date' &&
                formItem.formTypeWeb !== 'dateTime'
              "
              :span="4"
            >
              <el-select
                v-model="formItem.condition"
                :placeholder="'请选择范围'"
                size="medium"
              >
                <el-option
                  v-for="item in calConditionOptions(
                    formItem.formTypeWeb,
                    formItem
                  )"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
            <el-col
              :span="
                formItem.formTypeWeb === 'dateTime' ||
                formItem.formTypeWeb === 'date'
                  ? 13
                  : formItem.formTypeWeb === 'requirement' ||
                    formItem.formTypeWeb === 'IndustryCategory'
                  ? 9
                  : 8
              "
            >
              <el-input
                v-model="formItem.value"
                size="medium"
                :placeholder="'请输入筛选条件'"
              />
            </el-col>
            <el-col :span="1" class="delete">
              <i
                class="el-icon-error delete-btn"
                @click="handleDelete(index)"
              />
            </el-col>
          </el-row>
        </template>
      </el-form-item>
    </el-form>
    <p v-show="showErrors" class="el-icon-warning warning-info">
      <span class="desc">{{ "筛选条件中有重复项！" }}</span>
    </p>
    <el-button type="text" size="medium" @click="handleAdd"
      >+ {{ "添加筛选条件" }}</el-button
    >
    <div class="save">
      <div class="save-setting">
        <el-checkbox v-model="saveDefault">{{ "设置为默认" }}</el-checkbox>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">{{ "取 消" }}</el-button>
      <el-button type="primary" @click="handleConfirm">{{ "确 定" }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { objDeepCopy, getFathersById } from "@/utils";
import { saveScene, editScene } from "@/api/common";
export default {
  name: "SceneCreate", // 新建场景
  props: {
    fieldList: {
      type: Array,
    },
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    fieldList: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    obj: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
    /** 没有值就是全部类型 有值就是当个类型 */
    crmType: {
      type: String,
      default: "",
    },
    crmName: {
      type: String,
      default: "",
    },
    /** 名字和 默认 id 编辑的时候需要 */
    name: {
      type: String,
      default: "",
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    edit_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false, // 控制展示
      form: [],
      showErrors: false,
      saveDefault: false, // 设置为默认场景
      saveName: null, // 场景名称
      sceneCreateForm: {
        saveName: "", // 场景名称
      },
      rules: {
        saveName: [{ required: true, message: this.$t('sceneCreate.33'), trigger: "change" }],
      },
    };
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          // 处理编辑数据
          if (this.edit_id) {
            this.form = [];
            for (const field in this.obj.obj) {
              const element = this.obj.obj[field];
              const item = this.getItem();

              let obj = this.fieldList.reduce((obj, ele) => {
                if (ele.fieldCode == element.name) {
                  obj = ele;
                }
                return obj;
              }, {});
              item.fieldName = element.name;
              item.condition = element.condition;
              item.formType = obj.formType;
              item.formTypeWeb = obj.formTypeWeb;
              if (item.formTypeWeb == "date") {
                item.value = [element.startDate, element.endDate];
              } else if (item.formTypeWeb == "dateTime") {
                item.value = [element.startDate, element.startDate];
              } else if (item.formTypeWeb == "business_type") {
                item.typeId = element.typeId;
                item.statusId = element.statusId;
                item.typeOption = element.setting;
                if (element.typeId) {
                  const obj = element.setting.find((typeItem) => {
                    return typeItem.typeId === element.typeId;
                  });
                  if (obj) {
                    item.statusOption = obj.statusList;
                  } else {
                    item.statusOption = [];
                  }
                }
              } else if (
                item.formTypeWeb == "category" ||
                item.formTypeWeb == "IndustryCategory" ||
                item.formTypeWeb == "requirement" ||
                item.formTypeWeb == "officeCity" ||
                item.formTypeWeb == "interestProduct"
              ) {
                item.value = element.valueSave;
              } else if (item.formTypeWeb == "select") {
                item.value = element.value && element.value.split(",");
                item.setting = obj.options;
              } else if (item.formTypeWeb == "IndustryCategory") {
                item.value = getFathersById(
                  element.value,
                  this.$store.state.dict.dict.customer_business,
                  "value",
                  "list"
                );
              } else if (item.formTypeWeb == "users") {
                item.value = (element.value && element.value.split(",")) || [];
                this.manager_list = element.valueSave || [];
                item.setting = element.valueSave || [];
              } else if (item.formTypeWeb == "distributionchannel") {
                item.value = (element.value && element.value.split(",")) || [];
                this.distributionchannel_list = element.valueSave || [];
                item.setting = element.valueSave || [];
              } else {
                item.setting = obj.options;
                item.value = element.value;
              }
              this.form.push(item);
            }
          } else {
            this.form = objDeepCopy(this.obj.form);
            if (this.form.length == 0) {
              this.form.push(this.getItem());
            }
          }
          /** 只有编辑会牵扯到这两个字段赋值 */
          if (this.name) {
            this.sceneCreateForm.saveName = this.name;
          } else {
            this.sceneCreateForm.saveName = "";
          }
          if (this.isDefault) {
            this.saveDefault = this.isDefault;
          } else {
            this.saveDefault = false;
          }
        }
        this.visible = this.dialogVisible;
      },
      deep: true,
      immediate: true,
    },
    form() {
      this.$nextTick(() => {
        var container = document.getElementById("scene-filter-container");
        container.scrollTop = container.scrollHeight;
      });
    },
  },
  methods: {
    /**
     * 取消选择
     */
    handleCancel() {
      this.visible = false;
      this.$emit("update:dialogVisible", false);
    },
    getItem() {
      return {
        fieldName: "",
        name: "",
        formType: "",
        formTypeWeb: "",
        condition: "in",
        setting: [],
        value: "",
        typeOption: [],
        statusOption: [],
        typeId: "",
        statusId: "",
      };
    },
    /** 条件数据源 */
    calConditionOptions(formTypeWeb, item) {
      if (formTypeWeb == "text") {
        return [
          { value: "is", label: "等于", disabled: false },
          { value: "isNot", label: "不等于", disabled: false },
          {
            value: "contains",
            label: "包含",
            disabled: false,
          },
          {
            value: "notContains",
            label: "不包含",
            disabled: false,
          },
        ];
      } else {
        return [
          { value: "in", label: "等于", disabled: false },
          { value: "isNot", label: "不等于", disabled: false },
        ];
      }
    },
    /**
     * 当前选择的字段名改变，判断是否有重复
     * @param formItem
     */
    fieldChange(formItem) {
      const obj = this.fieldList.find((item) => {
        return item.fieldCode === formItem.fieldName;
      });

      if (obj) {
        formItem.formType = obj.formType;
        formItem.formTypeWeb = obj.formTypeWeb;
        formItem.name = obj.name;
        if (formItem.formTypeWeb == "select") {
          formItem.setting = obj.options || [];
          formItem.condition = "in";
          formItem.value = "";
        } else if (
          formItem.formTypeWeb === "date" ||
          formItem.formTypeWeb === "IndustryCategory" ||
          formItem.formTypeWeb === "distributionchannel" ||
          formItem.formTypeWeb === "category" ||
          formItem.formTypeWeb === "officeCity" ||
          formItem.formTypeWeb === "interestProduct" ||
          formItem.formTypeWeb === "dateTime" ||
          formItem.formTypeWeb === "users" ||
          formItem.formTypeWeb === "requirement"
        ) {
          formItem.condition = "in";
          formItem.value = [];
        } else {
          formItem.value = "";
          formItem.condition = "contains";
        }
      }
      const arr = this.form.filter((item) => {
        return item.fieldName === formItem.fieldName;
      });
      if (arr.length > 1) this.showErrors = true;
      else this.showErrors = false;
    },
    /**
     * 删除筛选条件
     * @param index
     */
    handleDelete(index) {
      this.$confirm(this.$t('sceneCreate.32'), this.$t('filter.f13'), {
        confirmButtonText: this.$t('common.btn.sure'),
        cancelButtonText: this.$t('common.btn.cancel'),
        type: "warning",
      })
        .then(() => {
          this.form.splice(index, 1);
        })
        .catch(() => {});
    },
    /**
     * 添加筛选条件
     */
    handleAdd() {
      this.form.push(this.getItem());
    },
    /**
     * 确定选择
     */
    handleConfirm() {
      this.$refs.sceneCreateForm.validate((valid) => {
        if (valid) {
          if (this.showErrors) {
            this.$message.error(this.$t("sceneCreate.2"));
            return;
          }
          for (let i = 0; i < this.form.length; i++) {
            const o = this.form[i];
            if (!o.fieldName || o.fieldName === "") {
              this.$message.error(this.$t("sceneCreate.28"));
              return;
            }
            if (
              o.formTypeWeb == "date" ||
              o.formTypeWeb == "dateTime" ||
              o.formTypeWeb == "users"
            ) {
              if (!o.value || o.value.length === 0) {
                this.$message.error(this.$t("sceneCreate.29"));
                return;
              }
            } else if (!o.value) {
              this.$message.error(this.$t("sceneCreate.29"));
              return;
            }
          }
          const obj = {};
          this.form.forEach((o) => {
            if (o.formTypeWeb == "dateTime" || o.formTypeWeb == "date") {
              obj[o.fieldName] = {
                startDate: moment(o.value[0]).format(),
                endDate: moment(o.value[1]).format(),
                formType: o.formType,
                name: o.fieldName,
                value: o.fieldName,
              };
            } else if (
              o.formTypeWeb == "category" ||
              o.formTypeWeb == "IndustryCategory" ||
              o.formTypeWeb == "interestProduct" ||
              o.formTypeWeb == "officeCity" ||
              o.formTypeWeb == "requirement"
            ) {
              let arr = [];
              arr =
                o.value &&
                o.value.map((item) => {
                  return item[item.length - 1];
                });
              obj[o.fieldName] = {
                condition: o.condition,
                value: arr.join(","),
                valueSave: o.value,
                formType: o.formType,
                name: o.fieldName,
              };
            } else if (o.formTypeWeb == "select") {
              obj[o.fieldName] = {
                condition: o.condition,
                value: (o.value && o.value.join(",")) || "",
                formType: o.formType,
                name: o.fieldName,
              };
            } else if (o.formTypeWeb == "users") {
              obj[o.fieldName] = {
                condition: o.condition,
                value: (o.value && o.value.join(",")) || "",
                formType: o.formType,
                valueSave: o.setting,
                setting: o.value[0],
                name: o.fieldName,
              };
            } else if (o.formTypeWeb == "distributionchannel") {
              obj[o.fieldName] = {
                condition: o.condition,
                value: (o.value && o.value.join(",")) || "",
                valueSave: o.setting,
                formType: o.formType,
                name: o.fieldName,
              };
            } else {
              obj[o.fieldName] = {
                condition: o.condition,
                value: o.value,
                formType: "text",
                name: o.fieldName,
              };
            }
          });
          const data = {
            obj: obj,
            form: this.form,
            saveChecked: this.saveChecked,
            saveDefault: this.saveDefault,
            saveName: this.sceneCreateForm.saveName,
          };
          // console.log(data);
          this.requestCreateScene(data);
        }
      });
    },
    // 创建场景
    requestCreateScene(data) {
      /** 编辑操作 */
      if (this.edit_id) {
        editScene({
          isDefault: data.saveDefault ? 1 : 0,
          name: data.saveName,
          id: this.edit_id,
          data: JSON.stringify(data.obj),
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: this.$t("sceneCreate.30"),
            });
            // 编辑成功
            this.$emit("saveSuccess");
            this.handleCancel();
          })
          .catch(() => {});
      } else {
        var objectCode = "";
        objectCode = this.crmType;
        saveScene({
          objectCode,
          objectName: this.crmName,
          isDefault: data.saveDefault ? 1 : 0,
          name: data.saveName,
          data: JSON.stringify(data.obj),
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: this.$t("sceneCreate.31"),
            });
            // 新建成功
            this.$emit("saveSuccess");
            this.handleCancel();
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  margin-top: 0 !important;
  top: 15vh;
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}

/deep/ .el-form-item__label {
  width: 100%;
  text-align: left;
}
.el-button {
  padding: 8px 12px;
}
.filter-container {
  max-height: 300px;
  overflow-y: auto;
}

.save {
  margin-top: 10px;
  .save-setting {
    margin-top: 20px;
  }
}

.el-form-item {
  margin-bottom: 0;
}

.el-row {
  margin-bottom: 20px;
  .delete {
    padding-left: 0 !important;
    .delete-btn {
      margin-left: 0;
      color: #bbb;
      cursor: pointer;
    }
  }
  .el-select,
  .el-date-editor {
    width: 100%;
  }
}

.warning-info {
  width: 100%;
  font-size: 14px;
  color: #f56c6c;
  margin-top: 10px;
  .desc {
    padding-left: 8px;
  }
}

.scene-name-container {
  padding-bottom: 15px;
  .scene-input {
    width: 300px;
  }
}
.scene-name {
  margin-bottom: 10px;
}
</style>