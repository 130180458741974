<template>
  <div class="content">
    <div class="close-content">
      <h2>Assets Details</h2>
      <img class="close"
           src="@/assets/img/task_close.png"
           @click="hidenView" />
    </div>

    <div class="sp_content">
      <el-row>
        <el-col class="sp_titleName">
          <p v-for="val in tabsList"
             :key="val.id"
             @click="handleTabs(val.id)"
             :class="{is_active:activeIndex == val.id}">{{val.name}}</p>
        </el-col>
      </el-row>

      <div class="sp_information"
           v-if="activeIndex == 1">
        <el-row>
          <div class="sp_infoTitle"><span></span>
            <p>Device Basic Information</p>
          </div>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Serial Number：</label><span>{{deviceInfo.sn}}</span></p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Gateway ID：</label><span>{{deviceInfo.deviceId}}</span></p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Order No：</label><span>{{deviceInfo.orderId}}</span></p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Model：</label><span>{{deviceInfo.model}}</span></p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Product：</label><span>{{deviceInfo.productType}}</span></p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Order Date：</label>
              <span>{{moment(deviceInfo.orderTime).format("YYYY-MM-DD")}}</span>
            </p>
          </el-col>
        </el-row>

        <el-row>
          <div class="sp_infoTitle"><span></span>
            <p>Device Status Information</p>
          </div>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Status：</label>
              <span>{{deviceStatus[deviceInfo.licStatus]}}</span>
            </p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Added Date：</label>
              <span>{{moment(deviceInfo.createTime).format("YYYY-MM-DD")}}</span>
            </p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>Update Date：</label>
              <span>{{moment(deviceInfo.updateTime).format("YYYY-MM-DD")}}</span>
            </p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>License Createtime：</label>
              <span>{{moment(deviceInfo.licenseCreateTime).format("YYYY-MM-DD")}}</span>
            </p>
          </el-col>
          <el-col :span="8">
            <p class="sp_infoCount"><label>License Updatetime：</label>
              <span>{{moment(deviceInfo.licenseUpdateTime).format("YYYY-MM-DD")}}</span>
            </p>
          </el-col>
        </el-row>

        <el-row>
          <div class="sp_infoTitle"><span></span>
            <p>Software Authorized</p>
          </div>
          <el-col :span="24">
            <common-table :tableHeadList="tableHeadList"
                          :tableprop="{expand_all:true,show_header:false,row_key:'id'}"
                          :tableData="tableData"></common-table>
          </el-col>
        </el-row>
      </div>

      <div class="sp_information"
           v-if="activeIndex == 2">
        <el-row>
          <div class="sp_infoTitle"><span></span>
            <p>Upload the Device Files</p>
          </div>
          <el-col :span="12">
            <p style="color:#2B2B2B; margin-bottom:25px;">
              <label>Last Date of Authrization：</label>
              <span>{{moment(licenseVO.lastActivationTime).format('YYYY-MM-DD')}}</span>
            </p>
          </el-col>
          <el-col :span="12">
            <p style="color:#2B2B2B; margin-bottom:25px;"><label>Status：</label><span :style="statusStyle[licenseVO.licStatus]">{{deviceStatus[licenseVO.licStatus]}}</span></p>
          </el-col>
          <el-col :span="24">
            <el-upload class="upload-demo"
                       ref="upload"
                       :http-request="uploadSectionFile"
                       :show-file-list="false"
                       :multiple="false"
                       :disabled="disabledUpload"
                       :limit="1"
                       action=""
                       drag>
              <i class="el-icon-upload"
                 v-if="!showProgress&&!uploadSuccess&&!uploadFail"></i>
              <div class="el-upload__text"
                   v-if="!showProgress&&!uploadSuccess&&!uploadFail">Drop files here or click to upload.</div>
              <div class="uploadStatus"
                   v-if="showProgress">
                <p style="color:#2b2b2b;"><label>文件大小：</label>{{fileTotal+'KB'}}</p>
                <el-progress :text-inside="false"
                             :stroke-width="8"
                             :percentage="progressPercent"
                             stroke-linecap="square"></el-progress>
              </div>
              <div class="uploadStatus"
                   v-if="uploadSuccess">
                <!-- <svg-icon icon-class="succeed"></svg-icon> -->
                <p style="font-size:20px; font-weight: 600; color: #2B2B2B;">Upload the Device Files success！</p>
                <p style="font-size: 16px; color:#2b2b2b;">{{sfileName}}</p>
                <p style="color:#007AFF; border:1px solid #007AFF; border-radius: 4px; padding:8px 14px; width:104px; font-size:14px; margin:0 auto;">Re-Upload</p>
              </div>
              <div class="uploadStatus"
                   v-if="uploadFail">
                <!-- <svg-icon icon-class="fail"></svg-icon> -->
                <p style="font-size:20px; font-weight: 600; color: #2B2B2B;">Upload Failed</p>
                <p style="font-size: 16px; color:#2b2b2b;">Yuo can Re-Upoad or try to copy and paste to frame below.</p>
                <p style="color:#fff; background:#007AFF; border-radius: 4px; padding:8px 14px; width:104px; font-size:14px; margin:0 auto;">Re-Upload</p>
              </div>
            </el-upload>
          </el-col>
          <el-col :span="24"
                  v-if="uploadFail">
            <el-input type="texta"
                      textarea
                      :autosize="{ minRows: 2, maxRows: 4}"
                      v-model="fileInfo"></el-input>
          </el-col>
          <el-col :span="24"
                  style=" margin-top:40px; display:flex; justify-content: center;">
            <el-button type="primary"
                       size="medium"
                       @click="applyLicense"
                       :disabled="fileInfo==''"
                       :loading="activeLoading">{{licenseStatus}}</el-button>
            <el-button plain
                       size="medium"
                       :disabled="licenseVO.licStatus==0||licenseVO.licStatus==4"
                       @click="handleDownload">
              <!-- <svg-icon icon-class="download"></svg-icon> -->
              &nbsp;&nbsp;Download License
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>

  </div>
</template>
<script>
import { deviceDetail, uploadDevice, applyLicense, downloadLicense } from '@/api/assets'
import commonTable from './commonTable.vue'
import moment from 'moment'
export default {
  name: 'deviceDetails',
  components: {
    commonTable
  },
  data () {
    return {
      tabsList: [
        { id: 1, name: "Devices Information" },
        { id: 2, name: "License" },
      ],
      activeIndex: 1,
      licenseVO: {},
      deviceInfo: {},
      deviceStatus: {
        0: 'Inactivated',
        1: 'Activated',
        2: 'To be Updated',
        4: 'Expired',
      },
      statusStyle: {
        0: {
          color: '#F4A12D',
        },
        1: {
          color: '#32C093',
        },
        2: {
          color: '#007AFF',
        },
        4: {
          color: '#79828C',
        },
      },
      disabledUpload: false,
      showProgress: false,
      progressPercent: 10,
      fileTotal: 0,
      uploadSuccess: false,
      uploadFail: false,
      fileInfo: '',
      licenseStatus: 'Activate',
      activeLoading: false,
      tableHeadList: [
        {
          data: {
            label: 'item',
            prop: 'name',
            name: '',
          }
        },
        {
          data: {
            label: 'Expired Date',
            prop: 'value',
            name: '',
          }
        },
      ],
      tableData: [],
    }
  },

  props: {
    detailsMsg: {
      type: Object,
      default: () => {
        return {

        }
      }
    },
  },
  watch: {
    detailsMsg: {
      handler (val) {
        this.activeIndex = val.activeIndex
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    moment () {
      return moment
    }
  },
  created () {
    this.getDeviceDetail()
  },
  methods: {

    // 关闭
    hidenView () {
      this.$emit('hide-view')
    },

    handleTabs (data) {
      this.activeIndex = data;
    },

    //获取设备详情
    getDeviceDetail () {
      let params = this.detailsMsg.row.id
      deviceDetail(params).then(res => {
        this.deviceInfo = res.rows.deviceInfo;
        this.licenseVO = res.rows.licenseVO;
        this.tableData = res.rows.serviceList
        this.tableData.forEach((item, index) => {
          item.id = index + 1
        })
      })
    },
    //激活设备
    applyLicense () {
      this.activeLoading = true;
      let params = {
        id: this.detailsMsg.row.id,
        devInfo: this.fileInfo,
      }
      applyLicense(params).then(res => {
        this.activeLoading = false;
        if (res.code == 0) {
          this.getDeviceDetail()
          this.$message({
            type: 'success',
            message: this.detailsMsg.licStatus == 0 ? "Activated Successfully" : 'Updated Successfully'
          })
        } else if (res.code == 500) {
          this.$message({
            type: 'success',
            message: this.detailsMsg.licStatus == 0 ? "Activated Failed" : 'Updated failed'
          })
        }
      }).catch(() => {
        this.activeLoading = false;
      })
    },

    //文件下载
    handleDownload () {
      let params = this.detailsMsg.row.id
      downloadLicense(params).then(res => {
        let fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
        const a = document.createElement('a')
        const blob = new Blob([res.data])
        const objectUrl = URL.createObjectURL(blob)
        a.setAttribute('href', objectUrl)
        a.setAttribute('download', fileName)
        a.click()
        URL.revokeObjectURL(objectUrl)
      })
    },
    //文件上传
    uploadSectionFile (params) {
      let filetype = params.file.name.split('.')[params.file.name.split('.').length - 1]
      if (filetype.toLowerCase() == 'info') {
        this.disabledUpload = true;//上传时不允许其他操作
        this.progressPercent = 0;
        this.fileTotal = 0;
        const config = {
          onUploadProgress: (progressEvent) => {
            // progressEvent.loaded:已上传文件大小
            // progressEvent.total:被上传文件的总大小
            this.fileTotal = (progressEvent.total / 1024).toFixed(2)
            this.progressPercent = Number(
              ((progressEvent.loaded / progressEvent.total) * 90).toFixed(2)
            );
          },
        };

        this.showProgress = true;
        this.uploadSuccess = false;
        this.uploadFail = false;
        let form = new FormData();
        form.append("file", params.file);
        form.append("id", this.detailsMsg.row.id);
        uploadDevice(form, config).then(res => {
          this.progressPercent = 100
          this.sfileName = params.file.name
          this.$refs.upload.clearFiles();
          setTimeout(() => {
            this.showProgress = false;
            this.uploadSuccess = true;
            this.disabledUpload = false;
            this.fileInfo = res.rows;
          }, 1000);
        }).catch(() => {
          this.$refs.upload.clearFiles();
          this.showProgress = false;
          this.disabledUpload = false;
          this.uploadFail = true;
          this.fileInfo = '';
        })
      } else {
        this.$refs.upload.clearFiles();
        this.$message({
          type: "warning",
          message: 'Only info files can be uploaded'
        })
      }
    },
  }
}
</script>
<style lang="less" scoped>
.close-content {
  display: flex;
  justify-content: space-between;
  .close {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: -10px;
    padding: 10px;
    cursor: pointer;
  }
}
.sp_content {
  width: 100%;
  background: #fff;
  padding: 16px;
  min-height: calc(100% - 53px);
  .sp_titleName {
    display: flex;
    border-bottom: 1px solid #e1e6ec;
    cursor: pointer;
    p {
      padding: 12px 24px;
      font-weight: 600;
      font-size: 18px;
      color: #666;
      transition: all 0.3s;
    }
    .is_active {
      background: #e4f1ff;
      color: #007aff;
    }
  }
  .sp_information {
    width: 100%;
    padding: 20px 16px;
    /deep/.el-col-8 {
      margin-bottom: 24px;
    }
    .sp_infoTitle {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 13px 0;
      margin-bottom: 16px;
      span {
        width: 4px;
        height: 16px;
        background: #007aff;
        margin-right: 12px;
      }
      p {
        font-weight: 600;
        font-size: 16px;
      }
    }
    .sp_infoCount {
      margin-left: 12px;
      label {
        color: #999999;
        font-size: 14px;
        font-weight: 600;
      }
      span {
        width: 100%;
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        color: #2b2b2b;
        margin-top: 8px;
      }
    }
    .upload-demo {
      width: 100%;
      /deep/.el-upload {
        width: 100%;
        height: 220px;
        .el-upload-dragger {
          width: 100%;
          height: 210px;
        }
      }
      .uploadStatus {
        width: 42%;
        margin: 0px auto;
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        .svg-icon {
          font-size: 48px;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>