<template>
  <div>
    <el-table class="table"
              :cell-style="cellStyle"
              :data="tableData"
              :default-expand-all="tableprop.expand_all"
              :show-header="tableprop.show_header"
              :row-key="tableprop.row_key"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
              @selection-change="handleSelectionChange"
              @row-click="handleRowClick">
      <el-table-column type="selection"
                       width="55"
                       v-if="isSelected">
      </el-table-column>
      <template v-for="(item,index) in tableHeadList">
        <el-table-column v-if="item.slot && !item.slotheader"
                         :key="index"
                         v-bind="item.data"
                         align="left"
                         fixed="right">
          <template slot-scope="scope">
            <slot :scope="scope"
                  :name="item.slot"></slot>
          </template>
        </el-table-column>
        <el-table-column v-else-if="item.slot && item.slotheader"
                         :key="index"
                         v-bind="item.data"
                         align="left"
                         fixed="right">
          <template slot="header">
            <slot name="tooltip"></slot>
          </template>
          <template slot-scope="scope">
            <slot :scope="scope"
                  :name="item.slot"></slot>
          </template>
        </el-table-column>
        <el-table-column v-else
                         :key="index"
                         :width="item.data.width"
                         v-bind="item.data"
                         :formatter="fieldFormatter"
                         :show-overflow-tooltip="true"
                         :render-header="renderHeader"></el-table-column>
      </template>
    </el-table>
    <div class="p-contianer"
         v-if="hasPagination">
      <el-pagination :current-page="pageNumber"
                     :page-sizes="pageSizes"
                     :page-size="pageSize"
                     :total="total"
                     class="p-bar"
                     layout="total, sizes, prev, pager, next, jumper"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange" />
    </div>
  </div>
</template>
<script>
import Lockr from 'lockr'
export default {
  name: 'commonTable',
  components: {},
  data() {
    return {
      selectData: {
        title: 'Share',
        show: false,
        row: [],
      },
      pageSizes: [10, 15, 30, 60, 100],
    }
  },
  props: {
    tableHeadList: {
      type: Array,
      default: () => {
        return []
      },
    },

    customClumn: {
      type: Array,
      default: () => {
        return []
      },
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      },
    },

    tableprop: {
      type: Object,
      default: () => {
        return {
          expand_all: false,
          show_header: true,
        }
      },
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },

    total: Number,
    hasPagination: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    //表头样式
    renderHeader(h, data) {
      return h('span', [
        h(
          'el-tooltip',
          {
            attrs: {
              class: 'item',
              effect: 'dark',
              content: data.column.label,
              placement: 'top',
            },
          },
          [h('span', data.column.label)]
        ),
      ])
    },
    // 更改每页展示数量
    handleSizeChange(val) {
      this.$emit('handleSizeChange', val)
    },
    // 更改当前页数
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
    },

    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property == 'sn') {
        return { color: '#007aff', cursor: 'pointer' }
      }
      if (
        row.children &&
        row.children.length &&
        (column.label === 'item' || column.label === 'Expired Date')
      ) {
        return {
          background: '#e5e5e5',
          color: '#222222',
          'font-weight': '600',
        }
      }
    },

    handleRowClick(row, column, event) {
      this.$emit('rowClick', row, column)
    },

    fieldFormatter(row, column) {
      switch (column.property) {
        case 'createTime':
          return moment(row.createTime).format('YYYY-MM-DD')
          break
        case 'updateTime':
          return moment(row.updateTime).format('YYYY-MM-DD')
          break
        case 'sharedTime':
          return moment(row.sharedTime).format('YYYY-MM-DD')
          break
        case 'softwareUpdateTime':
          return row.softwareUpdateTime
            ? moment(row.softwareUpdateTime).format('YYYY-MM-DD')
            : '/'
          break
        case 'bind':
          return row.bindingFlag ? 'Bound' : 'Not Bound'
          break
        case 'autoActivate':
          return row.autoActivate === 1 ? 'Enabled' : 'Disabled'
        default:
          return row[column.property]
      }
    },

    handleSelectionChange(data) {
      this.selectData.row = data
      this.$emit('getSelectDate', this.selectData)
    },
  },
}
</script>
<style lang="less" scoped>
.table {
  margin: 13px 0;
}
</style>