var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('ul',{staticClass:"list"},_vm._l((_vm.showObj.form),function(item,index){return _c('li',{key:index,staticClass:"list-item"},[(item.formTypeWeb == 'date')?_c('span',[_vm._v(_vm._s(item.name + " “" + item.value[0] + "-" + item.value[1] + "”"))]):(item.formTypeWeb === 'dateTime')?_c('span',[_vm._v(_vm._s(item.name + " “" + item.value[0] + "-" + item.value[1] + "”"))]):(item.formTypeWeb === 'users')?_c('span',[_vm._v(_vm._s(item.name + " " + _vm.optionsNames[item.condition] + "“" + _vm.getUserName(item) + "”"))]):(item.formTypeWeb === 'usernames')?_c('span',[_vm._v(_vm._s(item.name + " " + _vm.optionsNames[item.condition] + "“" + _vm.getUserName(item) + "”"))]):(item.formTypeWeb === 'distributionchannel')?_c('span',[_vm._v(_vm._s(item.name + " " + _vm.optionsNames[item.condition] + "“" + _vm.getdistributionchannelName(item) + "”"))]):(
          (item.formTypeWeb === 'category' && item.value.length > 0) ||
          item.formTypeWeb === 'IndustryCategory' ||
          item.formTypeWeb === 'interestProduct' ||
          (item.formTypeWeb === 'requirement' &&
            item.value.length > 0 &&
            item.value.length > 0)
        )?_c('span',[_vm._v(_vm._s(item.name + " " + _vm.optionsNames[item.condition] + " “" + item.valueContent + "”"))]):(item.formTypeWeb === 'select')?_c('span',[_vm._v(_vm._s(item.name + " " + _vm.optionsNames[item.condition] + "“" + _vm.getSelectName(item) + "”"))]):_c('span',[_vm._v(_vm._s(item.name + " " + _vm.optionsNames[item.condition] + "“" + item.value + "”"))]),_c('i',{staticClass:"el-icon-close icon",on:{"click":function($event){return _vm.handleDelete(item, index)}}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }