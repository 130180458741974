<template>
  <div style="height:100%;">
       <el-menu
      :default-active="SelectionMenu"
      mode="horizontal"
      text-color="#A1A4B4"
      active-text-color="#556EE6"
      class="productSelectionMenu"
      @select="handleSelect"
    >
      <el-menu-item index="SelectionOfRecord">{{$t('productSelection.SelectionOfRecord')}}</el-menu-item>
      <el-menu-item index="HasDownLoad">{{$t('productSelection.beenDownloaded')}}</el-menu-item>
    </el-menu>
      <div class="productComponent">
      <!-- <keep-alive> -->
          <!-- 组件 -->
        <component ref="componentList" @entryNameChange='entryNameChange' @dateChange='dateChange' @getDownloadList='getDownloadList' @getList='getList' @clearDownload='clearDownload' @exportBom='exportBom' @showChecked='showChecked' @clearIdList='clearIdList' :pageMsg='pageMsg' :downloadList='downloadList' @sort='sort'  @seachEntry='seachEntry' @handleCurrentChange='handleCurrentChange' @handleRecordCurrentChange='handleRecordCurrentChange' :ids='ids' @productBatchRemove='productBatchRemove' @ItemChange='ItemChange' @productRemove='productRemove' :is="getComponentName" :productList='productList' :loading='loading'></component>
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
import HasDownLoad from './components/hasDownload.vue'
import record from './components/Record.vue'
import { getProductList,productRemove,productBatchRemove,exportBom,noTipsSave,getDownList,isExist } from '@/api/selectionOfRecord.js'
import moment from 'moment'
export default {
    components: {
      HasDownLoad,record 
    },
    data () {
        return {
            SelectionMenu:'SelectionOfRecord',
            productList:[],
            params:{
                orderId: 2,
                page:'1',
                pageSize:'10'
                },
            query:{
                orderId: 2,
                page:'1',
                pageSize:'10'
            },
            loading:false,
            ids:[],
            pageMsg:{},
            downloadList:[],
            showCheck:false,
            hasProductList:[],
            entryName:''
        }
    },
    computed: {
        getComponentName(){
            if(this.SelectionMenu == 'SelectionOfRecord'){   
                this.query={
                orderId: 2,
                page:'1',
                pageSize:'10'
            },           
                this.getProductList()
                return 'record'
            }else if(this.SelectionMenu == 'HasDownLoad'){
                this.params.entryName = ''
                this.getDownList()
                return 'HasDownLoad'
            }
        }
    },
    created () {
      // this.getProductList()
      
    },
    methods: {
        handleSelect(val){
            this.SelectionMenu = val
        },
        getProductList(){
          this.loading = true
            getProductList(this.params).then(res=>{
                if(res.code == 0){                 
                  this.pageMsg={
                    total : res.rows.total,
                    pageNo : res.rows.pageNo
                  }
                  this.ids = []
                  this.downloadList = []
                  this.productList = res.rows.rows 
                  this.loading = false
                }
            })
        },
        productRemove(val){
          productRemove({id:val.id}).then(res=>{
            if(res.code == 0){
              this.$message.success(this.$t('productSelection.delete'))
              this.getProductList()
            }
          })
        },
        //批量删除参数拼接
        ItemChange(val){
          if(val.add == true){
            if(val.singel == false && (this.showCheck == true || val.showChecked == true)){
             this.ids.push(val.id)
            }           
            this.downloadList.push(val)
            this.downloadList.length>0?this.downloadList.forEach((item,index) => {
              if(val.id == item.id && val.val.length!==item.val.length){
                this.downloadList.splice(index,1)
              }
            }):''
          }else{  
            //删除id
            this.ids.length>0?this.ids.forEach((item,index) => {
              if(val.id == item){
                this.ids.splice(index,1)
              }
            }):''
            //下载Bom
            this.downloadList.length>0?this.downloadList.forEach((item,index) => {
              if(val.singel==false){
                if(val.id == item.id && val.val.length!==item.val.length){
                this.downloadList.splice(index,1)
                }
              }else if(val.singel){
                if(val.id == item.id && val.val.length!==item.val.length){
                  // this.downloadList.splice(index,1)
                  if(val.val.length!==0){
                    this.downloadList[index].val  = val.val
                  }else{
                    this.downloadList.splice(index,1)
                  }
                }
              } 
            }):''
          }
          this.ids =  this.unique(this.ids)
          this.downloadList = this.downloadListUnique(this.downloadList)
        },
        //去重
        unique(arr) {
            var arry = Array.from(new Set(arr))
            return arry
         },
         downloadListUnique(arr){
           if(arr.length>0){
             var arr1 = [arr[0]];
                    for(var i=1,len=arr.length;i<len;i++){
                        if(arr[i].id !== arr[i-1].id){
                            arr1.push(arr[i]);
                        }
                    }
           }else{
                      var arr1 = []
                    }
                    return arr1;
           
         },
        //取消全选
        clearIdList(){
          this.ids = []
        },
        //批量删除
        productBatchRemove(){
          let ids = this.ids.join(',')
          productBatchRemove({ids:ids}).then(res=>{
            if(res.code == 0){
              this.$message.success(this.$t('productSelection.delete'))
              this.getProductList()
            }
          })
        },
        //分页
        handleCurrentChange(val){
          this.ids = []
          this.downloadList = []
          this.query.page = val.toString()
          this.getDownList()
        },
        handleRecordCurrentChange(val){
          this.ids = []
          this.downloadList = []
          this.params.page = val.toString()
          this.params.entryName = this.entryName
          this.getProductList()
        },
        //搜索
        seachEntry(val){
          this.ids = []
          this.downloadList = []
          this.entryName = val
          this.params={
                orderId: 2,
                page:'1',
                pageSize:'10',
                entryName:this.entryName
          }
          this.getProductList()
        },
        //排序
        sort(val){
          this.ids = []
          this.downloadList = []
          this.params={
                orderId: val,
                page:'1',
                pageSize:'10',
                entryName:this.entryName
          }
          this.getProductList()
        },
        //下载Bom
        exportBom(val){
          let params = []
          this.downloadList.forEach((item,index)=>{
            var list = []
            item.val.forEach((i,v)=>{
              list.push({'id':i.id})
              params[index] ={
                'detailList':list,
                'id':item.id,
                } 
            })
          })
          isExist(params).then(res=>{
            if(res.code==0){
              this.downloadExportBom(params,val)
            }
          })          
        },
        downloadExportBom(params,val){
          exportBom(params).then(res=>{
            if(val){
              this.noTipsSave(val)
            }
             const link = document.createElement('a');
              let blob = new Blob([res],{type:"application/zip"});
              link.style.display = 'none';
              //设置连接
              link.href = URL.createObjectURL(blob);
              link.download = `SANGFOR_NGAF_BOM${moment(new Date()).format('YYYY.MM.DD_HH-mm-ss')}.zip`; 
              document.body.appendChild(link);
              //模拟点击事件
              link.click();
          })
        },
        showChecked(val){
          this.showCheck = val
        },
        //不再提示
        noTipsSave(val){
          if(val){
            var params = {
            state:1
          }
          }else{
            var params = {
            state:2
          }
          }
          noTipsSave(params).then(res=>{
          })
        },
        getDownList(){
          this.ids = []
          this.downloadList = []
          this.loading = true
          getDownList(this.query).then(res=>{
                if(res.code == 0){                                   
                  this.pageMsg={
                    total : res.rows.total,
                    pageNo : res.rows.pageNo
                  }
                  this.productList = res.rows.rows 
                  this.loading = false                  
                }
          })
        },
        getList(){
          this.getProductList()
        },
        clearDownload(){
          // this.ids = []
          // this.downloadList = []
        },
        getDownloadList(){
          this.getDownList()
        },
        dateChange(val){
          this.query.page = '1'
          this.query.startTime = val.startTime
          this.query.endTime = val.endTime
          this.getDownList()
        },
        entryNameChange(val){
          this.query.page = '1'
          this.query.entryName = val.entryName
          this.getDownList()
        }
    }

}
</script>

<style lang="less" scoped>
    .productComponent{
            overflow-x: auto;
            padding: 30px 20px 10px 20px;
            background-color: #fff;
            min-height: 92%;
    }
    /deep/ .el-menu-item{
        font-weight: bold;
    }
</style>